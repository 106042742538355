import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getAccessToken } from '../../utils/local-data-service'
import { Container } from '../../components/login'
import { PATH } from '../../constants/path'

const LoginContainer = () => {
  const history = useHistory()
  useEffect(() => {
    if (getAccessToken()) {
      history.push(PATH.chat)
    }
  })

  return (
    <Container />
  )
}

export default LoginContainer
