import { ApolloProvider, useQuery } from '@apollo/client'
import { Div, FullPageLoading, H3, Icon } from '@konsys-ui-custom'
import _, { find } from 'lodash'
import React, { useContext, useEffect } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import client from './apollo-client'
import { PATH } from './constants/path'
import history from './history'
import Chat from './pages/chat'
import Login from './pages/login'
import quotationContainer from './pages/user-quotation'
import { theme } from './styles/variables'
import './App.css'
import { ForgotPassword, ResetPassword } from './components/login/forgot-reset-password'
import NavigateContainer from './components/navigate'
import { GET_PERMISSIONS } from './constants/graph/query'
import Broadcast from './pages/broadcast'

import QuatationContainer from './pages/quotation'
import { QuatationForm } from './pages/quotation/form'
import QuatationView from './pages/quotation/view'
import { Role } from './pages/role'
import { GlobalContext } from './store/StoreProvider'
import { getAccessToken } from './utils/local-data-service'

const NotFound = () => (
  <Div display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='100%' bgColor={theme.color.gray10}>
    <Icon fontSize='100px' color={theme.color.gray40} icon='fal fa-sad-tear' />
    <H3 bold color={theme.color.gray40} margin='8px 0 0'>ไม่พบข้อมูล</H3>
  </Div>
)

const Landing = (props) => {
  const { updateAuthorization } = useContext(GlobalContext)
  const getPermissions = useQuery(GET_PERMISSIONS, { skip: props.history?.location?.pathname?.split('/')[1] === PATH.userQuotation.split('/')[1] || !getAccessToken() })

  const getData = getPermissions?.data?.myRole?.data?.[0]?.roleList?.map((roleData) => (
    {
      role: roleData?.role,
      moduleList: getPermissions?.data?.myRole?.data?.map((moduleDetail) => {
        const findRole = find(moduleDetail?.roleList, (obj) => obj?.role?.id === roleData?.role?.id)
        return (
          {
            moduleName: moduleDetail?.moduleName,
            permissionList: findRole?.permissionList,
          }
        )
      }),
    }
  ))

  useEffect(() => {
    if (getAccessToken() && !getPermissions.loading) {
      const newReduce = _.reduce(
        getData?.[0]?.moduleList,
        (moduleList, v) => ({
          ...moduleList,
          [v.moduleName]: _.reduce(v.permissionList, (permList, perm) => ({ ...permList, [perm.name]: perm.status }), {}),
        }),
        {},
      )
      updateAuthorization(newReduce)
    }
  }, [getPermissions.loading])
  if (!getAccessToken()) return props.children
  if (getPermissions.loading) return <FullPageLoading height='calc(100vh - 54px)' center fontSize={theme?.fonts?.size?.subTitle} />
  return props.children
}

const App = () => {
  const {
    navigateDetail,
  } = useContext(GlobalContext)

  useEffect(() => {
    const getRootNode = document.querySelector('#root')
    getRootNode.style = `height: ${window.innerHeight}px; maxHeight: ${window.innerHeight}px;`
  }, [])

  return (
    <ApolloProvider client={client}>
      <Landing history={history}>
        <Router history={history}>
          <Switch>
            <Route path={PATH.login} exact component={Login} />
            <Route path={PATH.forgotPassword} exact component={ForgotPassword} />
            <Route path={`${PATH.resetPassword}/:token`} exact component={ResetPassword} />
            <Route path={PATH.userQuotation} exact component={quotationContainer} />
            {
              getAccessToken() && <NavigateContainer navigateProps={navigateDetail}>
                <Switch>
                  <Route path={PATH.chatLine} component={Chat} />
                  <Route path={PATH.chatMessenger} component={Chat} />
                  <Route path={PATH.chatComment} component={Chat} />
                  <Route path={PATH.chat} component={Chat} />
                  <Route path={PATH.quotation} exact component={QuatationContainer} />
                  <Route path={PATH.quotationCreate} exact component={QuatationForm} />
                  <Route path={`${PATH.quotation}/:id/edit`} exact render={() => <QuatationForm isEdit />} />
                  <Route path={`${PATH.quotation}/:id`} exact component={QuatationView} />
                  <Route path={PATH.role} component={Role} />
                  <Route path={PATH.broadcast} component={Broadcast} />
                  <Redirect from='/' to={PATH.chat} />
                  <Route component={NotFound} />
                </Switch>
              </NavigateContainer>
            }
            <Redirect from='*' to={PATH.login} />
          </Switch>
        </Router>
      </Landing>
    </ApolloProvider>
  )
}

export default App
