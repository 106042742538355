import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Collapse,
  CustomLink,
  Div,
  Divider,
  FullPageLoading,
  H3,
  H4, Input,
  Label,
  P,
  SmallText,
  SummaryPrice,
  Uploader,
} from '@konsys-ui-custom'
import {
  Col,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select as SelectAnt,
  message,
  notification,
} from 'antd'
import dayjs from 'dayjs'
import _, {
  find,
  first,
  flatten,
  isEmpty,
  isUndefined, keys,
  reduce,
} from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import {
  BankLogo,
} from '../../components/common/styled'
import {
  DELIVERY_TYPE,
  DELIVERY_TYPE_TEXT, PAYMENT_TYPE,
  PAYMENT_TYPE_TEXT,
} from '../../constants/db-const'
import { PAYMENT_CHANNEL } from '../../constants/enum'
import URL from '../../constants/environment'
import {
  ACTIVE_QUOTATION,
  APPROVE_PAYMENT,
  CONFIRM_PAYMENT,
  DUPLICATE_QUOTATION,
  GENERATE_TOKEN, SEND_QUOTATION,
  VERIFY_SLIP,
} from '../../constants/graph/mutation'
import {
  BANK_LIST,
  POSSIBLE_PROVIDER,
  QUOTATION_DETAIL,
} from '../../constants/graph/query'
import { PATH } from '../../constants/path'
import {
  QUOTATION_STATUS,
  colorSelector,
} from '../../constants/status/quotation'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import {
  getAccessToken,
  getURL,
} from '../../utils/local-data-service'
import { mapImageBank } from '../../utils/util-services'
import { ProductDisplay, Status, UpsellDropdown } from './components'
import { DownloadWrapper, Line } from './styled'

const VerifyModal = (props) => {
  const [refNo, setRefNo] = useState('')
  const [slipImage, setSlipImage] = useState()
  const [bankCode, setBankCode] = useState()
  const [disabledPopconfirm, setDisabledPopconfirm] = useState(true)
  const [doVerifySlip, { loading, data: verifyData }] = useMutation(VERIFY_SLIP)
  const [doConfirmVerifySlip, { loading: confirmLoading }] = useMutation(CONFIRM_PAYMENT)
  const { data } = useQuery(BANK_LIST)

  useEffect(() => {
    if (!disabledPopconfirm) {
      document.getElementById('modal-button-confirm').click()
    }
  }, [disabledPopconfirm])

  return (
    <Modal
      title="ตรวจสอบสลิป"
      visible={props.open && !_.isEmpty(data?.bankList?.data?.list)}
      onCancel={() => {
        setDisabledPopconfirm(true)
        setSlipImage()
        setRefNo()
        setBankCode('')
        props.onCancel()
      }}
      footer={
        [
          <Button key="back" onClick={() => props.onCancel()} text='ยกเลิก' />,
          <Popconfirm
            key="submit"
            title={
              verifyData?.verifySlip?.data?.totalPayment < verifyData?.verifySlip?.data?.depositAmount
                ? <P>ยอดชำระรวม ยังไม่ครบยอดที่กำหนดชำระ<br/>ต้องบันทึกการชำระเงินเพิ่มเติม จนถึงยอดที่กำหนดของ Quotation จึงจะดำเนินการต่อได้<br/>ต้องการบันทึกชำระเงินหรือไม่ ?</P>
                : verifyData?.verifySlip?.data?.totalPayment > verifyData?.verifySlip?.data?.totalAmount
                  ? <P>หากต้องการบันทึกการชำระเงินของรอบนี้<br/>ยอดชำระจริง ฿{numeral(verifyData?.verifySlip?.data?.totalAmount).format('0,0.00')} แต่ยอดชำระเกินเป็น ฿{numeral(verifyData?.verifySlip?.data?.totalPayment).format('0,0.00')}<br/>ต้องการยืนยันเพื่อทำรายการต่อหรือไม่?</P>
                  : <P>Loading...</P>
            }
            onConfirm={() => {
              doConfirmVerifySlip({
                variables: {
                  quotationId: props?.data?.quotationInfo?.data?.id,
                },
              }).then((resp) => {
                if (resp?.data?.confirmPayment?.success) {
                  notification.success({
                    message: resp?.data?.confirmPayment?.message,
                    placement: 'topRight',
                    style: {
                      boxShadow: `0 0 10px ${theme.color.success}44`,
                    },
                  })
                } else {
                  notification.error({
                    message: resp?.data?.confirmPayment?.message,
                    placement: 'topRight',
                    style: {
                      boxShadow: `0 0 10px ${theme.color.error}44`,
                    },
                  })
                }
                props.onCancel()
              })
            }}
            onCancel={() => setDisabledPopconfirm(true)}
            onVisibleChange={(visible) => {
              if (!visible) setDisabledPopconfirm(true)
            }}
            destroyTooltipOnHide
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{
              disabled: _.isEmpty(verifyData),
            }}
            disabled={disabledPopconfirm}
          >
            <Button id='modal-button-confirm' disabled={confirmLoading || loading || (_.isEmpty(refNo) && _.isEmpty(slipImage)) || _.isEmpty(bankCode)} loading={loading || confirmLoading} inverse text='ตรวจสอบ' onClick={() => {
              doVerifySlip({
                variables: {
                  transactionId: refNo,
                  quotationId: props.quotationId,
                  bankCode,
                  attachment: _.isEmpty(_.compact(slipImage)) ? undefined : slipImage[0],
                },
              }).then((resp) => {
                if (!_.isEmpty(resp?.data?.verifySlip)) {
                  if (resp?.data?.verifySlip?.success) {
                    notification.success({
                      message: resp?.data?.verifySlip?.message,
                      placement: 'topRight',
                      style: {
                        boxShadow: `0 0 10px ${theme.color.success}44`,
                      },
                    })
                    props.onCancel()
                  } else if (
                    !resp?.data?.verifySlip?.success
                    && resp?.data?.verifySlip?.message === 'NEED_CONFIRMATION'
                    && ((resp?.data?.verifySlip?.data?.totalPayment < resp?.data?.verifySlip?.data?.depositAmount) || (resp?.data?.verifySlip?.data?.totalPayment > resp?.data?.verifySlip?.data?.totalAmount))
                  ) {
                    setDisabledPopconfirm(false)
                  } else {
                    notification.error({
                      message: resp?.data?.verifySlip?.message,
                      placement: 'topRight',
                      style: {
                        boxShadow: `0 0 10px ${theme.color.error}44`,
                      },
                    })
                  }
                }
              })
            }} />
          </Popconfirm>,
        ]}
    >
      <Div>
        <P display='block' margin='0 0 8px'>เลือกธนาคาร</P>
        <SelectAnt
          showSearch
          style={
            {
              width: '100%',
            }
          }
          placeholder='เลือกธนาคาร'
          value={bankCode}
          onSelect={(value) => {
            setBankCode(value)
          }}
        >
          {
            data?.bankList?.data?.list.map((o) => (
              <SelectAnt.Option key={o.bankCode} value={o.bankCode}>{o.name}</SelectAnt.Option>
            ))
          }
        </SelectAnt>
        <P display='block' margin='16px 0 8px'>รายละเอียด</P>
        <Div className='slip-section' padding={!_.isEmpty(slipImage) ? '0 0 24px' : '0'} display='flex' alignItems='center'>
          <Uploader
            onChange={(e) => setSlipImage(e)}
            data={slipImage}
            accept='image/*'
            isMultiple={false}
            buttonText='อัพโหลดไฟล์'
            disabled={!_.isEmpty(refNo)}
          />
          <P margin='0 16px'>หรือ</P>
          <Input
            width='61.9%'
            state='default'
            onChange={(e) => setRefNo(e.target.value)}
            placeholder='กรอก Reference number จากสลิป'
            disabled={!_.isEmpty(slipImage)}
          />
        </Div>
      </Div>
    </Modal>
  )
}

const ItemRowRenderer = (item, index) => (
  <Div margin='8px 0'>
    <Col span={14}>
      <P margin='0' style={{ overFlow: 'visible', width: '0', display: 'inline-flex' }}>{index + 1}.</P>
      <P padding='0 0 0 39px' margin='0 0 2px'>{item?.pack?.inventoryOption?.inventory?.productName || item?.set?.name}</P>
      <Div padding='0 0 0 39px' display='flex' flexDirection='column' >
        <Label>sku: {item?.pack?.inventoryOption?.inventory?.sku || item?.set?.sku}</Label>
        {
          item?.pack
          && <Label margin='2px 0 0'>brand: {item?.pack?.inventoryOption?.inventory?.brand?.name}</Label>
        }
      </Div>
    </Col>
    <Col span={2} style={{ textAlign: 'end' }}>
      <P>{item?.quantity}</P>
    </Col>
    <Col span={4} style={{ textAlign: 'end' }}>
      <P>{item?.price}</P>
    </Col>
    <Col span={4} style={{ textAlign: 'end' }}>
      <P>{item?.discount}</P>
    </Col>
  </Div>
)

export const QuotationView = ({
  id, modal, setModal, refetchQuotationListInChat, successDuplication, currentProviderId,
}) => {
  const history = useHistory()
  const {
    data, loading,
  } = useQuery(QUOTATION_DETAIL, { variables: { id } })
  const { data: providerData } = useQuery(POSSIBLE_PROVIDER, { variables: { id } })
  const [doConfirm, { loading: confirmLoading }] = useMutation(APPROVE_PAYMENT)
  const [tokenData] = useMutation(GENERATE_TOKEN)
  const [sendQuotationToChat, { loading: sendQuotationLoading }] = useMutation(SEND_QUOTATION)
  const [activeQuotation, { loading: activeQuotationLoading }] = useMutation(ACTIVE_QUOTATION)
  const [doDuplicateQuotation, { loading: duplicateQuotationLoaing }] = useMutation(DUPLICATE_QUOTATION)
  const [openModal, setOpenModal] = useState(false)

  const renderAddress = (address) => `${address?.addressNo} ${address?.subDistrict} ${address?.district} ${address?.province} ${address?.postcode} ${address?.contactPhoneNumber || ''}`

  if (loading || (!loading && _.isEmpty(data))) return <FullPageLoading />
  if (_.isNull(data?.quotationInfo?.data?.id)) return <Div display='flex' flexDirection='column' padding='0 32px' style={{ background: '#FFFFFF', height: 'auto', minHeight: '100%' }} />

  const isExpired = data?.quotationInfo?.data?.isExpired
  const isOnMultipleChannel = true

  const sendQuotation = (providerId) => {
    sendQuotationToChat({
      variables: {
        channelId: data?.quotationInfo?.data?.channelId,
        providerId: parseInt(providerId),
        quotationId: data?.quotationInfo?.data?.id,
      },
    }).then((resp) => {
      if (resp.data.replyFlexMessage.success) {
        message.success(resp.data.replyFlexMessage.message)
        const obj = _.find(providerData?.possibleProvider?.data, (v) => v.providerId === parseInt(providerId))
        if (modal) {
          setModal(false)
        }
        if (currentProviderId !== parseInt(providerId)) {
          history.push({
            pathname: obj?.name === 'MESSENGER' ? PATH.chatMessenger : PATH.chatLine,
          })
        }
      } else message.error(resp.data.replyFlexMessage.message)
    })
  }
  const menu = isOnMultipleChannel ? (
    <Menu disabled={_.isEmpty(providerData)} onClick={(e) => {
      sendQuotation(e.key)
    }}>
      {
        providerData?.possibleProvider?.data?.map((v) => (
          <Menu.Item key={v.providerId}>{v.name}</Menu.Item>
        ))
      }
    </Menu>
  ) : (
    <Menu></Menu>
  )

  const urlPath = process.env.REACT_APP_BUILD_MODE === 'production' ? URL.production : (process.env.REACT_APP_BUILD_MODE === 'staging') ? URL.staging : URL.development
  const isLessThanDeposit = _.sum(data?.quotationInfo?.data?.quotationPayments?.map((v) => v.amount)) < data?.quotationInfo?.data?.transferAmount

  const PaymentHistoryDetail = ({
    paymentData, margin, index, type,
  }) => !_.isEmpty(paymentData?.finnicBankName) && (
    <Div display='flex'>
      <SmallText margin='0 8px 0 0' width='30px'>{index}.</SmallText>
      <Div margin={margin}>
        <Div display='flex'>
          {
            !_.isEmpty(paymentData?.bankName) && !_.isEmpty(paymentData?.bankFullName)
            && <Div>
              <SmallText color={theme.color.gray90}>บัญชีต้นทาง</SmallText>
              <Div margin='0 0 16px' display='flex'>
                {
                  !_.isEmpty(paymentData?.bankName)
                  && <BankLogo src={mapImageBank(paymentData?.bankName)} />
                }
                <Div margin={!_.isEmpty(paymentData?.bankName) ? '0 0 0 8px' : '0'}>
                  <P display='block' color={theme.color.gray90} bold>{paymentData?.bankFullName}</P>
                </Div>
              </Div>
            </Div>
          }
          {
            !_.isEmpty(paymentData?.finnicBankName) && !_.isEmpty(paymentData?.finnicBankFullName)
            && <Div margin={!_.isEmpty(paymentData?.bankName) && !_.isEmpty(paymentData?.bankFullName) ? '0 0 0 24px' : '0'}>
              <SmallText color={theme.color.gray90}>บัญชีปลายทาง</SmallText>
              <Div margin='0 0 16px' display='flex' alignItems='center'>
                {
                  !_.isEmpty(paymentData?.finnicBankName)
                  && <BankLogo src={mapImageBank(paymentData?.finnicBankName)} />
                }
                <Div margin={!_.isEmpty(paymentData?.finnicBankName) ? '0 0 0 8px' : '0'}>
                  <P display='block' color={theme.color.gray90} bold>{paymentData?.finnicBankFullName}</P>
                  <P display='block' color={theme.color.gray90} bold>{paymentData?.bankAccount}</P>
                </Div>
              </Div>
            </Div>
          }
          <Div margin='0 0 0 24px'>
            <SmallText>วันเวลาที่ชำระเงิน</SmallText>
            <P display='block'>{_.isEmpty(paymentData?.date) ? '-' : moment.tz(paymentData?.date, 'Asia/Bangkok').format('DD/MM/YYYY')} {paymentData?.time}</P>
          </Div>
          {
            !_.isNil(paymentData?.amount)
            && <Div margin='0 0 0 24px'>
              <SmallText>จำนวนที่ชำระเงิน</SmallText>
              <P display='block'>{numeral(paymentData?.amount).format('0,0.00')}</P>
            </Div>
          }
        </Div>
        <Div display='flex'>
          {
            type === 'transaction'
            && <Div margin='0 24px 0 0'>
              <SmallText>Reference</SmallText>
              <P display='block'>{paymentData?.transactionId}</P>
            </Div>
          }
          {
            paymentData?.url
            && <Div display='flex' flexDirection='column'>
              <SmallText color={theme.color.gray90} margin='0 0 4px'>รูปภาพการชำระเงิน</SmallText>
              <a href={paymentData?.url} style={{ width: 'fit-content' }} download>
                <SmallText color={theme.color.tertiaryColor} margin='0 0 4px' style={{ textDecoration: 'underline' }}>ดาวน์โหลดรูปภาพการชำระเงิน</SmallText>
                <br />
                <img src={paymentData?.url} style={{ maxWidth: '160px' }} />
              </a>
            </Div>
          }
        </Div>
      </Div>
    </Div>
  )

  return (
    <Div display='flex' flexDirection='column' padding='0 32px' style={{
      background: '#FFFFFF', height: 'auto', minHeight: '100%', minWidth: '1024px',
    }}>
      <VerifyModal data={data} quotationId={id} open={openModal} onCancel={() => setOpenModal(false)} />
      <Div width='100%' display='flex' flexDirection='row' justifyContent='space-between' >
        <Div margin='26px 0 40px' display='flex' flexDirection='row' alignItems='center'>
          <H3 margin='0 32px 0 0' color={theme.color.primaryColor} bold>#{data?.quotationInfo?.data?.refId}</H3>
          {
            !isExpired
            && <Button loading={activeQuotationLoading} disabled={activeQuotationLoading} text={data?.quotationInfo?.data?.isActive ? 'ยกเลิกใบ Quotation นี้' : 'ใช้ใบ Quotation นี้'}
              color={data?.quotationInfo?.data?.isActive ? theme.color.error : theme.color.success} onClick={() => {
                activeQuotation({
                  variables: {
                    id,
                    isActive: !data?.quotationInfo?.data?.isActive,
                  },
                  refetchQueries: ['quotationInfo', 'quotationList'],
                }).then((resp) => {
                  if (resp.data.activeQuotation.success) {
                    message.success(resp.data.activeQuotation.message)
                  } else message.error(resp.data.activeQuotation.message)
                })
              }}
            />
          }
        </Div>
        <H3 margin='26px 0 40px' color={data?.quotationInfo?.data?.isActive && !isExpired ? colorSelector(data?.quotationInfo?.data?.status) : theme.color.error} bold>{isExpired ? 'หมดอายุ' : !data?.quotationInfo?.data?.isActive ? 'ยกเลิก' : (_.find(QUOTATION_STATUS, { value: data?.quotationInfo?.data?.status })?.text || '')}</H3>
      </Div>
      <Div width='100%' display='flex' justifyContent='space-between' margin='0 0 24px'>
        <Div margin='0 0 24px' moreStyle={{ flexBasis: '50%', flexWrap: 'wrap' }}>
          <Div display='flex'>
            <Div margin='0 24px 0 0'>
              <P display='block' margin='0 0 4px' color={theme.color.gray90}>ลูกค้า</P>
              <P color={theme.color.gray90} bold>{data?.quotationInfo?.data?.customer?.name}</P>
            </Div>
            <Div margin='0 24px 0 0'>
              <P display='block' margin='0 0 4px' color={theme.color.gray90}>ประเภทการชำระเงิน</P>
              <P color={theme.color.gray90} bold>{PAYMENT_TYPE_TEXT[data?.quotationInfo?.data?.paymentType]}</P>
            </Div>
            <Div>
              <P display='block' margin='0 0 4px' color={theme.color.gray90}>ชื่อผู้ติดต่อ</P>
              <P color={theme.color.gray90} bold>{data?.quotationInfo?.data?.contactAddress?.contactName || '-'}</P>
            </Div>
          </Div>
          <Div display='flex' margin='16px 0 0'>
            {
              !_.isEmpty(data?.quotationInfo?.data?.channelType)
              && <Div margin='0 24px 0 0'>
                <P display='block' margin='0 0 4px' color={theme.color.gray90}>Channel</P>
                <P color={theme.color.gray90} bold>{data?.quotationInfo?.data?.channelType || '-'}</P>
              </Div>
            }
            {/* {
              data?.quotationInfo?.data?.status === QUOTATION_STATUS[1].value && !_.isEmpty(data?.quotationInfo?.data?.qrCodeUrl)
              && <DownloadWrapper>
                <P display='block' margin='0 0 2px'>ดาวน์โหลด Qr code</P>
                <CustomLink href={data?.quotationInfo?.data?.qrCodeUrl} target='_blank'>คลิกเพื่อดาวน์โหลด</CustomLink>
                <img src={data?.quotationInfo?.data?.qrCodeUrl} />
              </DownloadWrapper>
            } */}
          </Div>
        </Div>
        <Div moreStyle={{ flexBasis: '50%' }}>
          {
            _.findIndex(QUOTATION_STATUS, { value: data?.quotationInfo?.data?.status }) < 3 && !isExpired
            && <Div display='flex' justifyContent='flex-end'>
              <Button margin='0 0 8px' text='ยืนยันการชำระเงิน' inverse icon='far fa-check' color={theme.color.primaryColor}
                disabled={confirmLoading}
                onClick={() => {
                  if (data?.quotationInfo?.data?.status === QUOTATION_STATUS[2].value) {
                    doConfirm({
                      variables: {
                        id,
                        isApprove: true,
                      },
                      refetchQueries: ['quotationInfo', 'quotationList'],
                    }).then((res) => {
                      if (res.data.approvePayment.success) {
                        message.success(res.data.approvePayment.message)
                      } else message.error(res.data.approvePayment.message)
                    })
                  } else {
                    tokenData({
                      variables: {
                        type: 'SALES',
                      },
                    }).then((resp) => {
                      if (resp.data.generateOneTimeToken.success) {
                        window.open(`${getURL().WEB_SERVER}/quotation-detail/${resp.data.generateOneTimeToken.data.token}/${id}`, '_blank')
                      } else message.error(resp.data.generateOneTimeToken.message)
                    })
                  }
                }} />
            </Div>
          }
          {
            data?.quotationInfo?.data?.status === QUOTATION_STATUS[3].value
            && <Div display='flex' justifyContent='flex-end'>
              <Button margin='0 0 8px' text='สร้างใบ SO' inverse icon='fal fa-sign-out' color={theme.color.leafyGreen}
                onClick={() => {
                  window.open(`${process.env.REACT_APP_ERP_SERVER}/sale-orders/create/${id}`, '_blank')
                }}
              />
            </Div>
          }
          {
            data?.quotationInfo?.data?.channelId
            && <Div display='flex' justifyContent='flex-end' margin='0 0 8px'>
              {
                providerData?.possibleProvider?.data.length > 1
                  ? <Dropdown overlay={menu} disabled={sendQuotationLoading}>
                    <Button disabled={sendQuotationLoading} loading={sendQuotationLoading} text='ส่งลิงค์เข้าแชตลูกค้า' icon='fal fa-paper-plane' color={theme.color.primaryColor} />
                  </Dropdown>
                  : <Button disabled={sendQuotationLoading} loading={sendQuotationLoading} text='ส่งลิงค์เข้าแชตลูกค้า' icon='fal fa-paper-plane' color={theme.color.primaryColor} onClick={() => {
                    sendQuotation(providerData?.possibleProvider?.data[0]?.providerId)
                  }} />
              }
            </Div>
          }
          <Div display='flex' justifyContent='flex-end' width='100%' moreStyle={{ flexWrap: 'wrap' }}>
            <a href={`${urlPath.FILE_SERVER}/print/quotation/${data?.quotationInfo?.data?.id}?t=${getAccessToken()}`} target='_blank' rel="noopener noreferrer">
              <Button text='พิมพ์ใบ Quotation' icon='fal fa-print' color={theme.color.secondaryColor} />
            </a>
            {
              data?.quotationInfo?.data?.status === QUOTATION_STATUS[1].value && !isExpired && <Button margin='0 0 0 8px' text='ตรวจสอบสลิป' icon='fal fa-clipboard-check' color={theme.color.secondaryColor} onClick={() => setOpenModal(!openModal)} />
            }
            {
              isExpired && <Button disabled={duplicateQuotationLoaing} loading={duplicateQuotationLoaing} margin='0 0 0 8px' text='สร้าง Quotation ใหม่' inverse icon='fal fa-copy' color={theme.color.secondaryColor} onClick={() => {
                doDuplicateQuotation({
                  variables: {
                    id: data?.quotationInfo?.data?.id,
                  },
                }).then((resp) => {
                  if (resp.data.duplicateQuotation.success) {
                    message.success(resp.data.duplicateQuotation.message)
                    if (successDuplication) {
                      successDuplication(resp.data.duplicateQuotation.data?.id)
                    } else if (refetchQuotationListInChat) {
                      refetchQuotationListInChat()
                    }
                  } else {
                    message.error(resp.data.duplicateQuotation.message)
                  }
                })
              }} />
            }
          </Div>
        </Div>
      </Div>
      {
        _.findIndex(QUOTATION_STATUS, { value: data?.quotationInfo?.data?.status }) > 0 && data?.quotationInfo?.data?.paymentType !== PAYMENT_TYPE.CREDIT && !_.isEmpty(data?.quotationInfo?.data?.quotationPayments)
        && <Div display='flex' padding='16px' flexDirection='column' margin='0 0 40px' style={{ background: 'rgba(243, 245, 250, 0.65)' }}>
          <Collapse color={theme.color.gray90} title={<P color={theme.color.primaryColor} bold>รายละเอียดชำระเงิน</P>}>
            <Div margin='16px 0 0' >
              {
                isLessThanDeposit
                && <Div margin='8px 0 24px' padding='16px' bgColor={`${theme.color.error}22`}>
                  <P bold>ยอดชำระรวม ยังไม่ครบยอดที่กำหนดชำระ</P>
                  <P bold color={theme.color.error} margin='0 0 0 6px;'>({data?.quotationInfo?.data?.transferAmount} บาท) </P>
                </Div>
              }
              {
                data?.quotationInfo?.data?.quotationPayments?.map((v, i) => (
                  <Div key={i}>
                    <PaymentHistoryDetail paymentData={v} index={i + 1} type={!_.isNil(v.transactionId) && 'transaction'} />
                    {
                      i !== data?.quotationInfo?.data?.quotationPayments.length - 1
                      && <Line margin='16px 0' />
                    }
                  </Div>
                ))
              }
            </Div>
          </Collapse>
        </Div>
      }
      {
        _.map(data?.quotationInfo?.data?.quotationLots, (v, index) => (
          <Div display='flex' flexDirection='column' key={v.id} style={{ padding: 20, border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5 }} margin='10px 0'>
            <Div display='flex' alignItems='flex-start' margin='0 0 24px'>
              <H4 margin='0 16px 0 0' bold color={theme.color.secondaryColor}>ล็อตที่ {index + 1}</H4>
            </Div>
            <Div display='flex' flexDirection='row' margin='0 0 24px'>
              <Div display='flex' flexDirection='column' width='15%' margin='0 24px 0 0' style={{ minWidth: '150px' }}>
                <P margin='0 0 4px' color={theme.color.gray90}>ช่องทางการจัดส่ง</P>
                <P color={theme.color.gray90} bold>{DELIVERY_TYPE_TEXT[v.receiveType]}</P>
              </Div>
              {
                v.isTax
                && <Div margin='0 24px 0 0' style={{ minWidth: '200px' }}>
                  <P display='block' margin='0 0 4px' color={theme.color.gray90}>ใบกำกับภาษี</P>
                  <P color={theme.color.gray90} bold>ต้องการรับใบกำกับภาษี</P>
                </Div>
              }
              {
                !_.isNull(v.deliveryDate)
                && <Div display='flex' flexDirection='column' width='20%' margin='0 24px 0 0' style={{ minWidth: '200px' }}>
                  <P margin='0 0 4px' color={theme.color.gray90}>วันที่ส่งสินค้า</P>
                  <P color={theme.color.gray90} bold>{moment(v.deliveryDate).format('DD/MM/YYYY')}</P>
                </Div>
              }
            </Div>
            {
              !_.isEmpty(v.deliveryChannels)
              && <Div margin='0 0 24px'>
                <SmallText bold margin='0 0 8px'>Tracking Number ทั้งหมด</SmallText>
                <Div>
                  {
                    v.deliveryChannels?.map((o, i) => (
                      <Div display='flex' key={i} margin={i !== 0 ? '8px 0 0' : '0'}>
                        <Div width='20px' margin='0 6px 0 0'><SmallText bold>{i + 1}.</SmallText></Div>
                        <Div display='flex' flexDirection='column' width='20%' margin='0 24px 0 0' style={{ minWidth: '200px' }}>
                          <P margin='0 0 4px' color={theme.color.gray90}>บริษัทขนส่ง</P>
                          <P color={theme.color.gray90} bold>{o?.name}</P>
                        </Div>
                        <Div display='flex' flexDirection='column' width='20%' style={{ minWidth: '200px' }}>
                          <P margin='0 0 4px' color={theme.color.gray90}>Tracking Number</P>
                          <P color={theme.color.gray90} bold>{o.trackingNumber}</P>
                        </Div>
                      </Div>
                    ))
                  }
                </Div>
              </Div>
            }
            {
              !(v.receiveType === DELIVERY_TYPE.MANUAL) && !_.isNull(v.lotDeliveryAddress)
                ? <Div display='flex' flexDirection='row' margin={!_.isEmpty(v.lotBillingAddress) ? '0 0 24px' : '0 0 40px'}>
                  <Div display='flex' flexDirection='column' margin='0 24px 0 0'>
                    <P margin='0 0 4px' color={theme.color.gray90}>ที่อยู่การจัดส่ง</P>
                    <P color={theme.color.gray90} bold>{renderAddress(v.lotDeliveryAddress)}</P>
                  </Div>
                </Div>
                : <Div display='flex' flexDirection='row' margin='0 0 16px' />
            }
            {
              !_.isEmpty(v.lotBillingAddress)
              && <Div margin='0 0 40px'>
                <P margin='0 0 4px' color={theme.color.gray90}>ที่อยู่ใบกำกับภาษี</P>
                <P display='block' color={theme.color.gray90} bold>{renderAddress(v.lotBillingAddress)}</P>
              </Div>
            }
            <Row type='flex' align='middle' gutter={16}>
              <Col span={14}>
                <SmallText bold padding='0 0 0 39px'>สินค้า</SmallText>
              </Col>
              <Col span={2} style={{ textAlign: 'end' }}>
                <SmallText bold>จำนวน</SmallText>
              </Col>
              <Col span={4} style={{ textAlign: 'end' }}>
                <SmallText bold>ราคา / หน่วย</SmallText>
              </Col>
              <Col span={4} style={{ textAlign: 'end' }}>
                <SmallText bold>ส่วนลด / หน่วย</SmallText>
              </Col>
            </Row>
            <Divider margin='8px 0' />
            {
              _.map(v.quotationItems, (item, i) => ItemRowRenderer(item, i))
            }
            <Divider margin='8px 0' />
            <SummaryPrice
              isWithShipping
              margin='8px 0 20px'
              data={{
                total: _.sum(_.map(v?.quotationItems, (item) => (item.quantity || 0) * (item.price || 0))),
                discount: {
                  type: 'Baht',
                  value: (
                    _.sum(_.map(v?.quotationItems, (item) => (item.discount || 0) * (item.quantity || 0)))
                  ),
                },
                shipping: v?.deliveryCost || 0,
              }}
            />
          </Div>
        ))
      }
      <Div display='flex' flexDirection='column' padding='16px' margin={modal ? '16px 0 20px' : '16px 0 100px'} style={{ border: `1px solid ${theme.color.secondaryColor}`, borderRadius: 5, background: theme.color.veryLightBlue }}>
        <H4 bold color={theme.color.primaryColor} margin='0 0 16px 0'>ทั้งหมด</H4>
        <SummaryPrice
          isWithShipping
          data={{
            total: _.sum(data?.quotationInfo?.data?.quotationLots.map((v) => _.sum(v.quotationItems.map((item) => (item.quantity || 0) * (item.price || 0))))),
            discount: {
              type: 'Baht',
              value: (
                _.sum(data?.quotationInfo?.data?.quotationLots.map((v) => _.sum(v.quotationItems.map((item) => (item.discount || 0) * (item.quantity || 0)))))
              ),
            },
            shipping: _.sum(data?.quotationInfo?.data?.quotationLots.map((v) => v.deliveryCost || 0)) || 0,
          }}
        />
      </Div>
    </Div >
  )
}

export default ({
  modal, setModal, currentProviderId, id,
}) => {
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const getId = parseInt(params?.id) || id
  const { authorization } = useContext(GlobalContext)
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const {
    data, loading, refetch,
  } = useQuery(QUOTATION_DETAIL, { variables: { id: getId } })
  const { data: providerData } = useQuery(POSSIBLE_PROVIDER, { variables: { id: getId } })
  const [sendQuotationToChat, { loading: sendQuotationLoading }] = useMutation(SEND_QUOTATION)
  const [tokenData] = useMutation(GENERATE_TOKEN)

  const getData = data?.quotationInfo?.data

  useEffect(() => {
    updateNavigateDetail({
      ...navigateDetail,
      pageTitle: 'รายละเอียด',
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => history.push({
        pathname: PATH?.quotation,
        state: {
          ...location?.state,
        },
      }),
      rightText: 'แก้ไข',
      rightFn: () => history.push({
        pathname: `${PATH?.quotation}/${getId}/edit`,
        state: {
          ...location?.state,
        },
      }),
    })
  }, [])

  const sendQuotation = (providerId) => {
    sendQuotationToChat({
      variables: {
        channelId: getData?.channelId,
        quotationId: data?.quotationInfo?.data?.id,
      },
    }).then((resp) => {
      if (resp.data.replyFlexMessage.success) {
        message.success(resp.data.replyFlexMessage.message)
        const obj = _.find(providerData?.possibleProvider?.data, (v) => v.providerId === parseInt(providerId))
        if (modal) {
          setModal(false)
        }
        if (currentProviderId !== parseInt(providerId)) {
          history.push({
            pathname: obj?.name === 'MESSENGER' ? PATH.chatMessenger : PATH.chatLine,
          })
        }
      } else message.error(resp.data.replyFlexMessage.message)
    })
  }

  const bottomDetail = [
    { title: 'ช่องทางชำระเงิน', value: getData?.isCOD ? PAYMENT_CHANNEL?.COD?.text : PAYMENT_CHANNEL?.FULLAMOUNT?.text },
    { title: 'วันที่ส่ง', value: isEmpty(getData?.quotationLots?.[0]?.deliveryDate) ? '-' : dayjs(getData?.quotationLots?.[0]?.deliveryDate).format('DD/MM/YYYY') },
    { title: 'ช่องทางการจัดส่ง', value: DELIVERY_TYPE_TEXT[getData?.quotationLots?.[0]?.receiveType] },
  ]

  const productList = getData?.quotationLots?.[0]?.quotationItems?.map((pdDetail) => (
    {
      id: pdDetail?.id,
      isFree: pdDetail?.isFree,
      saleId: pdDetail?.seller?.id,
      sale: isEmpty(getData?.createdByUser) ? '-' : `${getData?.createdByUser?.firstName} ${getData?.createdByUser?.lastName}`,
      img: pdDetail?.pack?.inventoryOption?.files?.[0]?.url || pdDetail?.set?.file?.url,
      name: pdDetail?.pack?.variantName || pdDetail?.set?.name,
      amount: pdDetail?.quantity,
      salePrice: pdDetail?.isFree ? 0 : pdDetail?.price || 0,
      cost: pdDetail?.cost || 0,
      discount: pdDetail?.isFree ? 0 : pdDetail?.discount || 0,
      com: pdDetail?.isFree ? 0 : pdDetail?.commission || 0,
    }
  ))

  const sellerComDetail = reduce(productList, (newObj, pdDetail) => {
    const newCom = !isUndefined(newObj[pdDetail?.saleId]) ? newObj[pdDetail?.saleId]?.com + (pdDetail?.com * pdDetail?.amount) : (pdDetail?.com * pdDetail?.amount)
    const setNewObj = {
      ...newObj,
      [pdDetail?.saleId]: {
        name: pdDetail?.sale,
        com: newCom,
      },
    }
    return setNewObj
  }, {})

  const isHavePermissionShowCost = authorization?.Quotation?.showCost

  const totalPriceBeforeDiscount = reduce(productList, (sum, pdDetail) => sum + (pdDetail?.salePrice * pdDetail?.amount), 0)
  const totalDiscount = reduce(productList, (sum, pdDetail) => sum + (pdDetail?.discount * pdDetail?.amount), 0)
  const summaryDetail = flatten([
    isHavePermissionShowCost ? [
      { title: 'ต้นทุน', value: `฿ ${numeral(reduce(productList, (sum, pdDetail) => sum + (pdDetail?.cost * pdDetail?.amount), 0)).format('0,0.00')}` },
      { title: 'กำไร', value: `฿ ${numeral(reduce(productList, (sum, pdDetail) => sum + (((pdDetail?.salePrice - pdDetail?.discount) - pdDetail?.cost) * pdDetail?.amount), 0)).format('0,0.00')}`, color: theme?.color?.success },
      ...keys(sellerComDetail)?.map((sellerId) => (
        {
          title: 'ค่าคอมฯ', subTitle: `(แอดมิน : ${sellerComDetail?.[sellerId]?.name})`, value: `฿ ${numeral(sellerComDetail?.[sellerId]?.com).format('0,0.00')}`, color: theme?.color?.success,
        }
      )),
      { title: 'ค่าคอมฯโบนัส', value: `฿ ${numeral(getData?.totalBonusSale).format('0,0.00')}`, color: theme?.color?.success },
    ] : null,
    { title: 'รวมค่าสินค้า', value: `฿ ${numeral(totalPriceBeforeDiscount).format('0,0.00')}` },
    { title: 'ค่าขนส่ง', value: `฿ ${numeral(getData?.quotationLots?.[0]?.deliveryCost).format('0,0.00')}` },
    { title: 'ส่วนลด', value: `฿ ${numeral(totalDiscount).format('0,0.00')}` },
  ])

  const isNotMatchSellerAndOwner = !isEmpty(getData?.chatOwner) && `${getData?.chatOwner?.firstName} ${getData?.chatOwner?.lastName}` !== `${getData?.seller?.firstName} ${getData?.seller?.lastName}`
  const upperDetail = [
    { title: 'ช่องทาง', value: getData?.chatProvider?.name || '-' },
    { title: 'ลูกค้า', value: getData?.customer?.name || '-' },
    { title: 'ยอดชำระ', value: `฿ ${numeral(totalPriceBeforeDiscount - totalDiscount).format('0,0.00')}` },
    { title: 'เจ้าของแชต', value: isEmpty(getData?.chatOwner) ? '-' : `${getData?.chatOwner?.firstName} ${getData?.chatOwner?.lastName}`, color: isNotMatchSellerAndOwner && theme?.color?.error },
    { title: 'แอดมินที่ได้รับค่าคอมฯ', value: isEmpty(getData?.seller) ? '-' : `${getData?.seller?.firstName} ${getData?.seller?.lastName}`, color: isNotMatchSellerAndOwner && theme?.color?.error },
  ]

  if (loading && isEmpty(data)) return <FullPageLoading height='calc(100vh - 53px)' fontSize={theme?.fonts?.size?.subTitle} />

  const menu = providerData?.possibleProvider?.data.length > 1 ? (
    <Menu disabled={_.isEmpty(providerData)} onClick={(e) => {
      sendQuotation(e.key)
    }}>
      {
        providerData?.possibleProvider?.data?.map((v) => (
          <Menu.Item key={v.providerId}>{v.name}</Menu.Item>
        ))
      }
    </Menu>
  ) : (
    <Menu></Menu>
  )

  const isCanVerifySlipAndConfirmPayment = !getData?.isCOD && getData?.status === QUOTATION_STATUS[1].value

  return (
    <Div padding='16px'>
      <Div display='flex' alignItems='flex-start' justifyContent='space-between' margin='0 0 12px'>
        <Div>
          <P display='block' color={theme?.color?.primaryColor} weightgray100>ใบเสนอราคา</P>
          <P color={theme?.color?.primaryColor} weightgray100>{getData?.refId}</P>
          <SmallText display='block'>วันที่สร้าง: {dayjs(getData?.createdAt).format('DD/MM/YYYY')}</SmallText>
        </Div>
        <Status color={colorSelector(getData?.status)} text={find(QUOTATION_STATUS, { value: getData?.status })?.text || getData?.status} />
      </Div>
      {
        upperDetail?.map((detail) => (
          <Div key={detail?.title} margin='12px 0 0' display='flex' justifyContent='space-between'>
            <SmallText>{detail?.title}</SmallText>
            <SmallText bold textAlign='right' color={detail?.color}>{detail?.value}</SmallText>
          </Div>
        ))
      }
      <Div margin='12px 0 0' display='flex' alignItems='center' justifyContent='space-between'>
        <SmallText>สถานะ Upsell</SmallText>
        <UpsellDropdown quotationId={getData?.id} value={getData?.upsellStatus} onSuccess={refetch} />
      </Div>
      <Div margin='24px 0 0' display='flex' justifyContent='center'>
        <Div margin='0 8px'>
          {
            providerData?.possibleProvider?.data.length > 1
              ? <Dropdown overlay={menu} disabled={sendQuotationLoading}>
                <Button disabled={sendQuotationLoading} loading={sendQuotationLoading} color={theme?.color?.primaryColor} inverse text='ส่งใบเสนอราคาเข้าแชต' />
              </Dropdown>
              : <Button disabled={sendQuotationLoading} loading={sendQuotationLoading} color={theme?.color?.primaryColor} inverse text='ส่งใบเสนอราคาเข้าแชต' onClick={() => sendQuotation(providerData?.possibleProvider?.data[0]?.providerId)} />
          }
        </Div>
        {
          isCanVerifySlipAndConfirmPayment
            && <Button margin='0 8px' text='ยืนยันการชำระเงิน' color={theme.color.primaryColor}
              onClick={() => {
                tokenData({
                  variables: {
                    type: 'SALES',
                  },
                }).then((resp) => {
                  if (resp.data.generateOneTimeToken.success) {
                    window.open(`${getURL().WEB_SERVER}/quotation-detail/${resp.data.generateOneTimeToken.data.token}/${id}`, '_blank')
                  } else message.error(resp.data.generateOneTimeToken.message)
                })
              }}
            />
        }
      </Div>
      {
        <Div margin='24px 0 0' padding='24px 0 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray30}` }}>
          <P weightgray100 color={theme?.color?.primaryColor}>รายการสินค้า</P>
          <SmallText margin='0 0 0 6px'>({numeral(productList?.length).format('0,0')} รายการ)</SmallText>
          {
            productList?.map((pdDetail) => {
              const totalPrice = (pdDetail?.salePrice * pdDetail?.amount) - (pdDetail?.discount * pdDetail?.amount)
              const totalCost = pdDetail?.cost * pdDetail?.amount
              return (
                <ProductDisplay
                  key={pdDetail?.id}
                  pdDetail={pdDetail}
                  totalPrice={totalPrice}
                  totalCost={totalCost}
                  displayOnly
                />
              )
            })
          }
        </Div>
      }
      {
        bottomDetail?.map((detail) => (
          <Div key={detail?.title} margin='24px 0 0' display='flex' justifyContent='space-between'>
            <SmallText>{detail?.title}</SmallText>
            <SmallText bold textAlign='right'>{detail?.value}</SmallText>
          </Div>
        ))
      }
      <Div margin='16px 0 0' padding='4px 0 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray30}` }}>
        {
          summaryDetail?.map((detail) => (
            <Div key={detail?.title} margin='12px 0 0' display='flex' justifyContent='space-between'>
              <SmallText bold>{detail?.title}</SmallText>
              <SmallText weightgray100 textAlign='right' color={detail?.color || theme?.color?.gray100}>{detail?.value}</SmallText>
            </Div>
          ))
        }
        <Div margin='12px 0 0' display='flex' justifyContent='space-between'>
          <P weightgray100>ยอดทั้งหมด</P>
          <P weightgray100 textAlign='right' color={theme?.color?.primaryColor}>{`฿ ${numeral(getData?.totalPrice).format('0,0.00')}`}</P>
        </Div>
      </Div>
    </Div>
  )
}
