import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Div, Icon,
  IconButton,
  Input,
  Label,
  P,
  SmallText,
  ExpandWithChoice, FullPageLoading } from '@konsys-ui-custom'
import { Drawer, message } from 'antd'
import { DatePicker } from 'antd4'
import {
  compact,
  filter,
  find, isEmpty, isNil,
  map,
  reduce,
  uniq,
} from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, {
  useContext,
  useEffect,
  useRef, useState,
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import NavigateContainer from '../../components/navigate'
import { SEND_QUOTATION } from '../../constants/graph/mutation'
import { PROVIDER_LIST, QUOTATION_LIST_IN_CHAT } from '../../constants/graph/query'
import { PATH } from '../../constants/path'
import { QUOTATION_STATUS, colorSelector } from '../../constants/status/quotation'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import { delayInput } from '../../utils/util-services'
import { Status, UpsellDropdown } from './components'

const limit = 10

const QuotationCard = ({ data, refetch, location }) => {
  const history = useHistory()
  const [sendQuotationToChat, { loading: sendQuotationLoading }] = useMutation(SEND_QUOTATION)

  const sendQuotation = () => {
    sendQuotationToChat({
      variables: {
        channelId: data?.channelId,
        quotationId: data?.id,
      },
    }).then((resp) => {
      if (resp.data.replyFlexMessage.success) {
        message.success(resp.data.replyFlexMessage.message)
      } else message.error(resp.data.replyFlexMessage.message)
    })
  }

  const clickToDetailPage = (e) => {
    const overlayContainer = document?.getElementsByClassName(`upsell-${data?.id}`)
    const dropdownContainer = document?.getElementById(`upsell-dropdown-${data?.id}`)
    const sendToChatContainer = document?.getElementById(`send-to-chat-${data?.id}`)
    if (!dropdownContainer?.contains(e.target) && !overlayContainer?.[0]?.contains(e.target) && !sendToChatContainer?.contains(e.target)) {
      history.push({
        pathname: `${PATH?.quotation}/${data?.id}`,
        state: {
          ...location?.state,
        },
      })
    }
  }

  const totalPrice = reduce(filter(data?.quotationLots?.[0]?.quotationItems, (obj) => !obj?.isFree), (sum, pdDetail) => sum + pdDetail?.totalPrice, 0)

  return (
    <Div id={`quotation-${data?.id}`} padding='12px 0' moreStyle={{ cursor: 'pointer' }} onClick={clickToDetailPage}>
      <Div display='flex' alignItems='center' justifyContent='space-between'>
        <Div display='flex' alignItems='center'>
          <P bold style={{ marginRight: 8 }}>{data?.refId}</P>
          {
            data.editCount > 0 && <span style={{
              marginRight: 6, display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 6, border: `1px solid ${theme.color.error}`, color: theme.color.error, padding: '1px 4px',
            }}>
              <Icon icon='fal fa-pencil' color={theme.color.error} style={{ marginRight: 2 }}/>
              {data.editCount}
            </span>
          }
        </Div>
        {
          !isEmpty(data?.status)
          && <Status color={colorSelector(data?.status)} text={find(QUOTATION_STATUS, { value: data?.status })?.text || data?.status} />
        }
      </Div>
      <Div margin='16px 0 12px' display='flex' alignItems='center' justifyContent='space-between'>
        <SmallText>ชื่อลูกค้า</SmallText>
        <SmallText textAlign='right'>{data?.customer?.name || '-'}</SmallText>
      </Div>
      <Div margin='0 0 12px' display='flex' alignItems='center' justifyContent='space-between'>
        <SmallText>ยอดชำระ</SmallText>
        <SmallText textAlign='right'>฿ {numeral(data?.totalPrice).format('0,0.00')}</SmallText>
      </Div>
      <Div display='flex' alignItems='center' justifyContent='space-between'>
        <SmallText>สถานะ Upsell</SmallText>
        <Div id={`upsell-dropdown-${data?.id}`}>
          <UpsellDropdown quotationId={data?.id} value={data?.upsellStatus} onSuccess={refetch} />
        </Div>
      </Div>
      <Div id={`send-to-chat-${data?.id}`} display='flex' alignItems='center' justifyContent='flex-end' padding='12px 0 0' margin='12px 0 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray10}`, cursor: sendQuotationLoading ? 'not-allowed' : 'pointer' }} onClick={!sendQuotationLoading && sendQuotation}>
        {
          !isNil(data?.channelId)
        && <>
          <SmallText color={theme?.color?.gray40}>ส่งใบเสนอราคาเข้าแชต</SmallText>
          <Icon color={theme?.color?.gray40} margin='0 0 0 8px' icon={sendQuotationLoading ? 'fal fa-spinner fa-pulse' : 'fal fa-paper-plane'} />
        </>
        }
      </Div>
    </Div>
  )
}

const FilterPage = ({
  filterVariables, setFilterVariables, closeModal, providerList,
}) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [vars, setVars] = useState(filterVariables)

  const closeFilter = () => closeModal()

  useEffect(() => {
    updateNavigateDetail({
      pageTitle: 'Filter',
      leftIcon: 'fal fa-chevron-left',
      leftFn: closeFilter,
      rightText: 'ล้าง',
      rightFn: () => setVars(filterVariables),
    })
  }, [])

  const onCheck = (filterTitle, key, e) => {
    if (e.target.value === 'checkAll') {
      setVars({
        ...vars,
        [key]: key === 'providerIds' && providerList.length > (vars?.providerIds?.length || 0) ? providerList.map((p) => p.id) : [],
      })
    } else {
      setVars({
        ...vars,
        [key]: !e.target.checked
          ? compact(map(vars?.[key], (id) => (id !== e.target.value ? id : null)))
          : uniq([...(vars?.[key] || []), e.target.value]),
      })
    }
  }

  const onSelectRadio = (key, e) => setVars({ ...vars, [key]: e.target.value })

  const callSetFilterVariables = () => {
    setFilterVariables({ ...filterVariables, ...vars })
    closeModal()
  }

  const checkDisabledButton = () => {
    const checkCreatedAt = !isEmpty(vars?.createdStartDate) && isEmpty(vars?.createdEndDate)
    return checkCreatedAt
  }

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div padding='16px' height={`${window.innerHeight - 128}px`} moreStyle={{ overflow: 'auto' }}>
        <ExpandWithChoice
          title='เรียงตาม'
          isRadio
          checkboxList={[{ value: 'DESC', label: 'Sort new-old' }, { value: 'ASC', label: 'Sort old-new' }]}
          varKey='sortBy'
          onSelectRadio={onSelectRadio}
          vars={vars?.sortBy}
        />
        {
          providerList
            ? <ExpandWithChoice
              margin='32px 0 0'
              title='ช่องทาง'
              checkboxList={map(providerList, (provider) => ({ value: provider?.id, label: provider?.name }))}
              varKey='providerIds'
              onCheck={onCheck}
              isHaveCheckAll
              vars={vars?.providerIds}
            />
            : <Div width='100%' margin='40px 0 0 0' display='flex' justifyContent='center' height={`${window.innerHeight - 168}px`}>
              <Icon icon='fal fa-spinner fa-pulse' style={{ height: 'fit-content' }} fontSize={theme.fonts.size.title} color={theme.color.gray40} />
            </Div>
        }

        <ExpandWithChoice
          margin='32px 0 0'
          title='วันที่สร้าง'
        >
          <Div display='flex'>
            <Div margin='0 16px 0 0' width='calc((100% - 16px) / 2)'>
              <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่เริ่ม</Label>
              <DatePicker inputReadOnly={true} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่เริ่ม' style={{ width: '100%' }} value={vars?.createdStartDate} onChange={(selectedDate) => setVars({ ...vars, createdStartDate: selectedDate, createdEndDate: undefined })} />
            </Div>
            <Div width='calc((100% - 16px) / 2)'>
              <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่สิ้นสุด</Label>
              <DatePicker inputReadOnly={true} disabledDate={(current) => current && current < moment(vars?.createdStartDate).subtract(1, 'd').endOf('day') } disabled={!vars?.createdStartDate} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่สิ้นสุด' style={{ width: '100%' }} value={vars?.createdEndDate} onChange={(selectedDate) => setVars({ ...vars, createdEndDate: selectedDate })} />
            </Div>
          </Div>
        </ExpandWithChoice>
      </Div>
      <Div moreStyle={{ boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.09)' }} padding='16px'>
        <Button disabled={checkDisabledButton()} fullWidth text='ฟีลเตอร์' color={theme?.color?.success} inverse onClick={callSetFilterVariables} />
      </Div>
    </NavigateContainer>
  )
}

const QuotationContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const ListScrollContainer = useRef()
  const [modalVisible, setModalVisible] = useState(false)
  const [filterVariables, setFilterVariables] = useState({
    search: '',
    sortBy: 'DESC',
  })
  const {
    data, loading, refetch, fetchMore,
  } = useQuery(QUOTATION_LIST_IN_CHAT, {
    variables: {
      limit,
      offset: 0,
      sortBy: filterVariables?.sortBy,
      search: filterVariables?.search,
      channelId: location?.state?.channelId,
      providerIds: isEmpty(filterVariables?.providerIds) ? undefined : filterVariables?.providerIds,
      createdAt: (isEmpty(filterVariables?.createdStartDate) && isEmpty(filterVariables?.createdEndDate)) ? undefined : [filterVariables?.createdStartDate, filterVariables?.createdEndDate],
    },
  })
  const { updateNavigateDetail } = useContext(GlobalContext)

  useEffect(() => {
    if (!modalVisible) {
      updateNavigateDetail()
    }
  }, [modalVisible])

  const { data: providerData } = useQuery(PROVIDER_LIST, { variables: { isCrm: true }, skip: !modalVisible })

  useEffect(() => {
    if (refetch) refetch()
  }, [filterVariables])

  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)
  const detectScroll = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight && fetchMore) {
      fetchMore({
        variables: { offset: data?.quotationList?.data?.list.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return {
            ...prev,
            quotationList: {
              ...prev.quotationList,
              data: {
                ...prev.quotationList.data,
                total: prev.quotationList.data.total,
                list: [...prev.quotationList.data.list, ...fetchMoreResult.quotationList.data.list],
              },
            },
          }
        },
      })
    }
  }

  // if ((loading && (isEmpty(data) || data?.quotationList?.data?.total === 0))) return <FullPageLoading height='calc(100vh - 53px)' fontSize={theme?.fonts?.size?.subTitle} />

  return (
    <Div padding='16px'>
      {
        modalVisible
        && <Drawer
          placement='right'
          closable={false}
          width='100%'
          visible={modalVisible}
          bodyStyle={{ padding: 0, height: window.innerHeight }}
          style={{ transform: 'translateX(0) !important' }}
        >
          <FilterPage
            filterVariables={filterVariables}
            setFilterVariables={setFilterVariables}
            closeModal={closeModal}
            providerList={providerData?.providerList?.data?.list}
          />
        </Drawer>
      }
      <Div display='flex' margin='0 0 16px'>
        <Input padding='6px 8px 5px 30px' placeholder='ค้นหาชื่อลูกค้า' prefix={<Icon icon='prefix fal fa-search' />} width='calc(100% - 48px)' onChange={(e) => {
          const getValue = e.target.value
          delayInput(() => setFilterVariables({ ...filterVariables, search: getValue }))
        }} />
        <IconButton iconColor={theme?.color?.gray40} color={theme?.color?.gray10} inverse margin='0 0 0 8px' onlyIcon buttonStyle='rectangle' icon='fal fa-sort-amount-down' onClick={openModal} />
      </Div>
      <Button margin='0 0 12px' inverse color={theme?.color?.success} fullWidth text='สร้างใบเสนอราคา' icon='fal fa-plus' onClick={() => history.push({
        pathname: PATH.quotationCreate,
        state: {
          ...location?.state,
        },
      })} />
      {
        !loading && isEmpty(data?.quotationList?.data?.list)
          ? <Div margin='40px' display='flex' alignItems='center' justifyContent='center'>
            <P margin='24px' color={theme?.color?.gray30}>ไม่มีข้อมูล</P>
          </Div>
          : <Div ref={ListScrollContainer} height='calc(100vh - 179px)' moreStyle={{ overflow: 'auto' }} onScroll={(e) => detectScroll(e)}>
            {
              data?.quotationList?.data?.list?.map((quotationDetail) => (
                <QuotationCard key={quotationDetail?.id} data={quotationDetail} refetch={refetch} location={location} />
              ))
            }
          </Div>
      }
      {
        loading && !isEmpty(data)
        && <FullPageLoading padding='16px' center fontSize={theme?.fonts?.size?.normal} />
      }
    </Div>
  )
}

export default QuotationContainer
