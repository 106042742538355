const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
}

export async function startRecording(setRecorderState) {
  const isChromeMobile = window.navigator.userAgent.match(/CriOS/i)
  const isSafariIos = !!(window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i)) && !!window.navigator.userAgent.match(/WebKit/i) && !window.navigator.userAgent.match(/CriOS/i)
  const isSafari = isSafariIos || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)) //eslint-disable-line

  if (isSafariIos || isChromeMobile) {
    let stream = null
    const audioSetting = {
      channelCount: 1,
    }
    const constraints = { video: false, audio: audioSetting }

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints) //eslint-disable-line
    } catch (error) {
      console.info(`recording - err: ${error.name} - ${error.message}`)
    }

    const recordMimeType = (isChromeMobile || isSafariIos) ? 'audio/mp4' : isSafari ? 'audio/mp3' : 'audio/webm;codecs=opus'

    const recorder = new MediaRecorder(stream, { mimeType: recordMimeType, audioBitsPerSecond: 128000 }) //eslint-disable-line

    const stopEle = document.getElementById('stop-recording-el')

    const recordedChunks = []
    recorder.addEventListener('dataavailable', ({ data }) => {
      recordedChunks.push(data)
      const blob = new Blob(recordedChunks, { type: recordMimeType }) //eslint-disable-line
      setRecorderState((prevState) => ({
        ...initialState,
        recordingMinutes: prevState?.recordingMinutes,
        recordingSeconds: prevState?.recordingSeconds,
        originalBlobFile: blob,
        audio: window.URL.createObjectURL(blob),
      }))
    })

    stopEle.addEventListener('click', () => {
      recorder.stop()
    })

    recorder.start()
  } else {
    try {
      const audioSetting = {
        channelCount: 1,
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: audioSetting, video: false }) //eslint-disable-line

      setRecorderState((prevState) => (
        {
          ...prevState,
          initRecording: true,
          mediaStream: stream,
        }
      ))
    } catch (err) {
      console.info('recording - err: ', err)
    }
  }
}

export function saveRecording(recorder) {
  if (recorder.state !== 'inactive') recorder.stop()
}
