import _ from 'lodash'
import Compressor from 'compressorjs' //eslint-disable-line
import { Modal, message } from 'antd'
import moment from 'moment'
import imageCompression from 'browser-image-compression'
import kbank from '../assets/bank/kbank.png'
import scb from '../assets/bank/SCB.png'
import bbl from '../assets/bank/BBL.png'
import Line from '../assets/channel/line.png'
import Messenger from '../assets/channel/messenger.png'
import Facebook from '../assets/channel/facebook.png'
import Instagram from '../assets/channel/instagram.png'
import Tiktok from '../assets/channel/tiktok.png'

export const isEveryIndex = (arr, value) => (
  arr.every((v) => v === value)
)

export const passwordValidator = (password) => (password || true)

export default isEveryIndex

export const checkInt = (input) => {
  const acceptedKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 37, 38, 39, 40, 9]
  return _.includes(acceptedKeyCode, input.keyCode)
}

export const checkFloat = (input) => {
  const acceptedKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 110, 190, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 37, 38, 39, 40, 9]
  return _.includes(acceptedKeyCode, input.keyCode)
}

export const checkNotNumber = (input) => {
  const acceptedKeyCode = [69, 107, 109, 187, 189]
  return _.includes(acceptedKeyCode, input.keyCode)
}

export const checkSpecial = (input) => /[!@#$%^&*)(]/g.test(input)

export const monthConverter = (input) => {
  switch (input) {
    case '01': return 'มกราคม'
    case '02': return 'กุมภาพันธ์'
    case '03': return 'มีนาคม'
    case '04': return 'เมษายน'
    case '05': return 'พฤษภาคม'
    case '06': return 'มิถุนายน'
    case '07': return 'กรกฎาคม'
    case '08': return 'สิงหาคม'
    case '09': return 'กันยายน'
    case '10': return 'ตุลาคม'
    case '11': return 'พฤศจิกายน'
    case '12': return 'ธันวาคม'
    default: return 'none'
  }
}

export const toSafeFloat = (value) => (_.isNaN(parseFloat(value)) ? 0 : parseFloat(value))

export const renderText = (value, isHideNilValue) => (value || (!isHideNilValue ? 'ไม่ระบุ' : ''))

const isInvalidValue = (value) => (
  _.isNaN(value) || _.isNil(value) || value === '' || (_.isArray(value) && _.isEmpty(value))
)

const checkValue = (requiredFields) => (
  _.some(requiredFields, (itemInRequiredFields) => {
    if (!_.isUndefined(itemInRequiredFields) && moment.isMoment(itemInRequiredFields)) {
      return false
    }
    // Item is array
    if (_.isArray(itemInRequiredFields)) {
      return _.size(itemInRequiredFields) === 0 || _.some(itemInRequiredFields, (valueInArrayItem) => {
        if (_.isObject(valueInArrayItem)) {
          return _.some(valueInArrayItem, (valueInObject) => isInvalidValue(valueInObject))
        } return isInvalidValue(valueInArrayItem)
      })
    }
    // Item is not array
    return isInvalidValue(itemInRequiredFields) || (_.isEmpty(itemInRequiredFields) && _.isObject(itemInRequiredFields)) || _.some(itemInRequiredFields, (valueInItem) => {
      if (_.isObject(valueInItem)) {
        return _.some(valueInItem, (valueInObject) => isInvalidValue(valueInObject))
      }
      return isInvalidValue(valueInItem)
    })
  })
)

// ---------------****** value of object can not be array ******--------------- //
export const validateData = (isCreate, requiredFields, allFields) => (
  isCreate
    ? _.isEmpty(requiredFields) || checkValue(requiredFields)
    : _.isEmpty(allFields)
      || _.every(_.values(allFields), (o) => (_.isArray(o) && _.isEmpty(o)))
      || _.some(_.flatten(_.values(allFields)), (o) => _.some(_.values(o), (v) => _.isUndefined(v)))
      || _.some(requiredFields, (o) => isInvalidValue(o) && !_.isUndefined(o))
)

export const getVariantName = (variant) => _.join(_.dropRightWhile([variant.option1 && variant.option1.optionName, variant.option2 && variant.option2.optionName, variant.option3 && variant.option3.optionName], (o) => _.isNull(o)), ' / ')
export const renderVariantNameFromInventoryOption = (data) => {
  const name = _.join(_.compact(['option1', 'option2', 'option3'].map(((o) => (!_.isEmpty(data[o]) ? data[o].optionName : '')))), ' / ')
  return _.isEmpty(name) ? '-' : name
}

export const permissionRedirect = () => (
  Modal.error({
    title: 'คุณไม่มีสิทธิการเข้าถึง',
    onOk: () => {
      window.location = '/'
    },
  })
)

export const mapImageBank = (bankName) => (
  bankName === 'BBL'
    ? bbl
    : bankName === 'SCB'
      ? scb
      : bankName === 'KBANK'
      && kbank
)

let timeout = null
export const delayInput = (callback, timer = 1000) => {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    callback()
  }, timer)
}

export const showMessageMutation = (data, callback) => {
  if (data?.success) {
    message.success(data?.message)
    if (callback) callback()
  } else {
    message.error(data?.error ?? data?.message ?? 'Something went wrong')
  }
}

export const getLogo = (type) => {
  switch (type) {
    case 'FACEBOOK':
      return Facebook
    case 'MESSENGER':
      return Messenger
    case 'LINE':
      return Line
    case 'INSTAGRAM':
      return Instagram
    case 'TIKTOK':
      return Tiktok
    default:
      return null
  }
}

export const compressFile = (file, quality) => {
  const compressPromise = new Promise((resolve) => {
    new Compressor(file, { //eslint-disable-line
      quality: quality || 0,
      success: (compressedResult) => resolve(compressedResult),
    })
  })
  return compressPromise
}

export const compressImageFile = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }
  try {
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (error) {
    return error
  }
}
