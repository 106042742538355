/* eslint-disable import/no-cycle */
import { Div } from '@konsys-ui-custom'
import React from 'react'
import styled, { css } from 'styled-components'
import LINE from '../../assets/line.png'
import MESSENGER from '../../assets/messenger.png'
import { theme } from '../../styles/variables'

export const WaveWrapper = styled.div`
  height: 32px;
  width: 112px;
`
export const DividerStyled = styled.hr`
  border: none;
  border-top: 1px solid ${(props) => props.color || theme.color.tertiaryColor};
  margin: ${(props) => props.margin || '16px 0'};
`
export const AvatarContainerWithChannel = styled.div`
  /* border: 2px solid red; */
  position: relative;
  height: fit-content;
  > img {
    width: 16px;
    height: 16px;
    border-radius: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${theme.color.gray40};
  cursor: pointer;
  ${(props) => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
`

export const AvatarSmall = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${theme.color.gray40};
  margin: ${(props) => props.margin || '0'};
  ${(props) => props.img && css`
    background-image: url(${props.img});
    background-size: cover;
    background-position: center;
  `}
`

export const ActiveLine = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${theme.color.primaryColor};
  width: 4px;
  max-height: 0;
  transition: all 0.5s ease;
  ${(props) => props.active && css`
    height: 100%;
    ${props.animateDown && css`
      top: 0;
    `}
    max-height: 200px;
  `}
`

export const BankLogo = styled.img`
  width: 31px;
  height: 31px;
`

export const StatusDot = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${(props) => (props.active ? theme.color.success : 'none')};
  border: 2px solid ${(props) => (props.active ? theme.color.success : theme.color.gray40)};
  border-radius: 50%;
`

export const TabWrapper = styled((props) => <Div {...props} />)`
  background-color: ${(props) => (props.white ? theme.color.offWhite : theme.color.gray10)};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

export const TabHeaderWrapper = styled((props) => <Div {...props} />)`
  border-bottom: 1px solid ${theme.color.veryLightBlue};
  display: flex;
  justify-content: space-between;
`

export const TabContentWrapper = styled((props) => <Div {...props} />)`
  padding: 16px 16px 0;
  overflow: auto;
  background-color: ${(props) => (props.white ? theme.color.offWhite : theme.color.gray10)};
  height: calc(100% - 60px);
  ${(props) => props.filterHeight && css`
    position: absolute;
    left: 0;
    right: 0;
    overflow: auto;
  `}
  ${(props) => props.contentHeight && css`
    height: ${props.contentHeight};
  `}
`

export const TabTitleWrapper = styled((props) => <Div {...props} />)`
  display: inline-block;
  padding: 8px 16px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  span {
    cursor: pointer;
  }
  ${(props) => props.active && css`
    background-color: ${theme.color.secondaryColor};
  `}
`

export const CollapseContentWrapper = styled.div`
  min-height: 0px;
  height: 0;
  transition: min-height ${theme.animate.time.maxHeight} ease-in-out;
  overflow: hidden;
  margin: 16px;
`

export const CollapseWrapper = styled((props) => <Div {...props} />)`
  > div {
    > i {
      transition: ${theme.animate.transition};
    }
  }
  ${(props) => props.open && css`
    > div {
      > i {
        transform: rotate(-180deg);
      }
    }
    ${CollapseContentWrapper} {
      min-height: ${props.minHeight};
      overflow: auto;
    }
    ${props.fullHeight && css`
      ${CollapseContentWrapper} {
        height: auto;
        overflow: hidden;
      }
    `}
  `}
`

export const Badge = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: -5px 0 0 -5px;
  border: solid white 2px;
  cursor: pointer;
  ${(props) => (props.provider === 'LINE'
  ? css`
      background-image: url(${LINE});
      background-size: 130%;
      background-position: 50% 30%;
    `
  : css`
      background-image: url(${MESSENGER});
      background-size: 140%;
      background-position: 50% 50%;
    `)
}
`

export const StatusLine = styled.div`
  height: 14px;
  width: 2px;
  background-color: ${(props) => (props.active ? theme.color.success : theme.color.gray40)};
  margin-left: 5px;
`

export const RotateChevronContainer = styled.div`
  cursor: pointer;
  > i {
    transition: all 0.3s ease;
  }
  ${(props) => props.open && css`
    > i {
      transform: rotate(180deg);
    }
  `}
`

export const a = 1
