import styled, { css } from 'styled-components'
import {
  Select as SelectAntd,
} from 'antd'
import { theme } from '../../styles/variables'

export const RecordWrapper = styled.div`
  position: relative;
  > div > div > div {
    background-color: transparent !important;
  }
  > div > div > div > div > div > button:first-child {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  > div > div > div > div > div {
    > div > span {
      color: red !important;
      font-size: 48px;
    }
    > p {
      color: blue !important;
      font-family: 'Sarabun';
      font-size: 16px;
    }
  }
`

export const Select = styled(SelectAntd)`
  .ant-select-selection {
    height: auto;
    .ant-select-selection__rendered {
      padding: 5px 0;
      margin: 0 16px;
      line-height: unset;
      display: flex;
      align-items: center;
      .ant-select-selection-selected-value {
        line-height: 1.6;
      }
    }
  }
`

export const IconUploadWrapper = styled.label``

export const IconUploadContainer = styled.label`
  > div {
    display: none;
  }
`

export const PreviewWrapper = styled.div`
  background-color white;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  margin: ${(props) => props.margin || '0'};
  width: 80px;
  min-width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 100%;
    object-fit: contain;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease;
    > i {
      display: none;
    }
  }
  &:hover {
    > div {
      background-color: rgba(0, 0, 0, 0.8);
      > i {
        display: block;
      }
    }
  }
`

export const ChatInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  > div > div {
    display: flex;
  }
  textarea,
  input {
    border: 2px solid blue;
    &:disabled {
      background-color: transparent;
      &::placeholder {
        color: ${theme.color.gray40};
      }
    }
  }
`

export const FacebookImage = styled.div`
  width: 100%;
  padding-top: 100%;
  margin: 4px 0;
  ${(props) => props.totalImage && css`
    &:last-child,
    &:nth-of-type(2) {
      margin-left: 8px;
      margin-right: ${props.totalImage <= 2 ? '0' : '8px'};
    }
    width: ${props.totalImage >= 3 ? 'calc((100% - 16px) / 3)' : props.totalImage === 2 ? 'calc((100% - 8px) / 2)' : '100%'};
    padding-top: ${props.totalImage >= 3 ? 'calc((100% - 16px) / 3)' : props.totalImage === 2 ? 'calc((100% - 8px) / 2)' : '100%'};
  `}
  ${(props) => props.src && css`
    background-image: url(${props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `}
`

export const RotateChevronContainer = styled.div`
  cursor: pointer;
  > i {
    transition: all 0.3s ease;
  }
  ${(props) => props.open && css`
    > i {
      transform: rotate(180deg);
    }
  `}
`

export const CustomDrawer = styled.div`
  background-color: ${theme?.color?.offWhite};
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  transition: all 0.3s ease;
  overflow: auto;
  ${(props) => props?.open && css`
    z-index: 1000;
    width: 100%;
  `}
`

export default Select
