import { PATH } from './path'
import { CHANNEL_TYPE } from './db-const'

export const getGroupChat = (type) => (
  [
    {
      id: 'all',
      path: type === 'ALL' ? PATH.chat : type === CHANNEL_TYPE.FACEBOOK ? PATH.chatComment : type === CHANNEL_TYPE.MESSENGER ? PATH.chatMessenger : PATH.chatLine,
      name: 'ทั้งหมด (0)',
      icon: 'fa-list',
      exact: true,
      dbId: 0,
    },
    {
      id: 'open-quotation',
      path: type === 'ALL' ? PATH.chatGroup.chatOpenQuotation : type === CHANNEL_TYPE.MESSENGER ? PATH.chatGroup.chatMessengerOpenQuotation : PATH.chatGroup.chatLineOpenQuotation,
      name: 'เสนอราคาแล้ว (0)',
      icon: 'fa-file-alt',
      exact: true,
      dbId: 1,
    }, {
      id: 'waiting-so',
      path: type === 'ALL' ? PATH.chatGroup.chatWaitingSo : type === CHANNEL_TYPE.MESSENGER ? PATH.chatGroup.chatMessengerWaitingSo : PATH.chatGroup.chatLineWaitingSo,
      name: 'รอเปิดSO (0)',
      icon: 'fa-exclamation-triangle',
      exact: true,
      dbId: 4,
    }, {
      id: 'waiting-delivery',
      path: type === 'ALL' ? PATH.chatGroup.chatWaitingDelivery : type === CHANNEL_TYPE.MESSENGER ? PATH.chatGroup.chatMessengerWaitingDelivery : PATH.chatGroup.chatLineWaitingDelivery,
      name: 'รอจัดส่ง (0)',
      icon: 'fa-shipping-timed',
      exact: true,
      dbId: 2,
    }, {
      id: 'success',
      path: type === 'ALL' ? PATH.chatGroup.chatSuccess : type === CHANNEL_TYPE.MESSENGER ? PATH.chatGroup.chatMessengerSuccess : PATH.chatGroup.chatLineSuccess,
      name: 'เสร็จสิ้น (0)',
      icon: 'fa-check',
      exact: true,
      dbId: 3,
    },
  ]
)

export const GROUP_CHAT = [
  {
    id: 'all',
    path: PATH.chat,
    name: 'ทั้งหมด (0)',
    icon: 'fa-list',
    exact: true,
    dbId: 0,
  },
  {
    id: 'open-quotation',
    path: PATH.chatGroup.chatLineOpenQuotation,
    name: 'เสนอราคาแล้ว (0)',
    icon: 'fa-file-alt',
    exact: true,
    dbId: 1,
  }, {
    id: 'waiting-so',
    path: PATH.chatGroup.chatLineWaitingSo,
    name: 'รอเปิดSO (0)',
    icon: 'fa-exclamation-triangle',
    exact: true,
    dbId: 4,
  }, {
    id: 'waiting-delivery',
    path: PATH.chatGroup.chatLineWaitingDelivery,
    name: 'รอจัดส่ง (0)',
    icon: 'fa-shipping-timed',
    exact: true,
    dbId: 2,
  }, {
    id: 'success',
    path: PATH.chatGroup.chatLineSuccess,
    name: 'เสร็จสิ้น (0)',
    icon: 'fa-check',
    exact: true,
    dbId: 3,
  },
]

export const menuItems = [
  {
    id: 'chat',
    path: PATH.chat,
    name: 'ข้อความ',
    icon: 'fa-comments-alt',
    children: [
      {
        name: 'channel',
        list: [
          {
            id: 'chat-all',
            path: PATH.chat,
            name: 'All',
            icon: 'fa-comment-alt-lines',
            exact: false,
          },
          {
            id: 'chat-line',
            path: PATH.chatLine,
            name: 'Line',
            icon: 'fa-comment-alt-lines',
            exact: false,
          },
          {
            id: 'chat-messenger',
            path: PATH.chatMessenger,
            name: 'FB Messenger',
            icon: 'fa-comment-alt-lines',
            exact: false,
          },
          {
            id: 'chat-comment',
            path: PATH.chatComment,
            name: 'FB Comment',
            icon: 'fa-comment-alt-lines',
            exact: false,
          },
        ],
      }, {
        name: 'สถานะ',
        list: [],
      },
    ],
  }, {
    id: 'quotation',
    path: PATH.quotation,
    name: 'ใบเสนอราคา',
    icon: 'fa-money-check-edit-alt',
    children: [
      {
        name: '',
        list: [
          {
            id: 'quotation-all',
            path: PATH.quotation,
            name: 'ทั้งหมด',
            icon: 'fa-list',
            exact: true,
          }, {
            id: 'quotation-create',
            path: PATH.quotationCreate,
            name: 'สร้าง',
            icon: 'fa-plus',
            exact: true,
          },
        ],
      },
    ],
  }, {
    id: 'broadcast',
    path: PATH.broadcastLine,
    name: 'ประกาศ',
    icon: 'fa-bullhorn',
    children: [
      {
        name: 'channel',
        list: [
          {
            id: 'broadcast-line',
            path: PATH.broadcastLine,
            name: 'Line',
            icon: 'fa-comment-alt-lines',
          },
        ],
      },
    ],
  }, {
    id: 'role',
    path: PATH.role,
    name: 'การเข้าถึง',
    icon: 'fas fa-fingerprint',
    children: [
      {
        name: '',
        list: [
          {
            id: 'role-all',
            path: PATH.role,
            name: 'ทั้งหมด',
            icon: 'fa-list',
            exact: true,
          },
        ],
      },
    ],
  },
]

export default menuItems
