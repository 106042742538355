import jwt from 'jwt-decode'
import URL from '../constants/environment'

const PRE_FIX_LOCAL_DATA = 'wink-white-official-crm:'

export const saveLocalStorage = (key, value) => {
  localStorage.setItem(`${PRE_FIX_LOCAL_DATA}${key}`, value)
}

export const getLocalStorage = (key) => localStorage.getItem(`${PRE_FIX_LOCAL_DATA}${key}`)

export const removeLocalStorage = (key) => localStorage.removeItem(`${PRE_FIX_LOCAL_DATA}${key}`)

export const getAccessToken = () => getLocalStorage('accessToken')

export const getRefreshToken = () => getLocalStorage('refreshToken')

export const setAccessToken = (value) => {
  saveLocalStorage('accessToken', value)
}

export const removeAccessToken = () => {
  removeLocalStorage('accessToken')
}

export const setRefreshToken = (value) => {
  saveLocalStorage('refreshToken', value)
}

export const removeRefreshToken = () => {
  removeLocalStorage('refreshToken')
}

export const getURL = () => (
  process.env.REACT_APP_BUILD_MODE === 'production' ? URL.production : (process.env.REACT_APP_BUILD_MODE === 'staging') ? URL.staging : URL.development
)

export const getTokenData = () => {
  if (getAccessToken()) return jwt(getAccessToken())
  return getAccessToken()
}
