import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Button, Div,
  FullPageLoading,
  Icon,
  Label,
  P,
  SmallText,
  TextArea,
} from '@konsys-ui-custom'
import {
  Checkbox,
  Drawer,
  Modal,
  Select,
  Upload,
  message,
} from 'antd'
import _, {
  compact,
  filter,
  isNil,
  lowerCase,
  map,
  uniq,
  upperCase,
  upperFirst,
} from 'lodash'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import NavigateContainer from '../../components/navigate'
import { MULTI_CAST_MSG } from '../../constants/graph/mutation'
import { CHAT_GROUP_LIST, PROVIDER_LIST, TAG_LIST } from '../../constants/graph/query'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import {
  compressImageFile,
  delayInput,
  getLogo,
} from '../../utils/util-services'

export const BroadcastForm = ({ closeBroadcastForm, title, providerId }) => {
  const {
    chatGroupStatus, updateChatGroupStatus, navigateDetail, updateNavigateDetail,
  } = useContext(GlobalContext)
  const [getChatGroupList, {
    loading: chatGroupLoading, data: chatGroupData,
  }] = useLazyQuery(CHAT_GROUP_LIST)
  const [getTagList, { loading: getting, data: tagData }] = useLazyQuery(TAG_LIST)
  const [doBroadcast, { loading: broadcastLoading }] = useMutation(MULTI_CAST_MSG)

  const [text, setText] = useState()
  const [fileList, setFileList] = useState([])
  const [previewFile, setPreviewFile] = useState([])
  const [check, setCheck] = useState([])
  const [selectedTag, setSelectedTag] = useState([])
  const [selectedFacebookTag, setSelectedFacebookTag] = useState()
  const [modalConfirmData, setModalConfirmData] = useState({
    visible: false,
  })
  const isLine = providerId === 1
  const FACEBOOK_MESSAGE_TAGS = [
    {
      label: 'Account Update',
      value: 'ACCOUNT_UPDATE',
    },
    {
      label: 'Confirmed Event Update',
      value: 'CONFIRMED_EVENT_UPDATE',
    },
    {
      label: 'Post Purchase Update',
      value: 'POST_PURCHASE_UPDATE',
    },
  ]

  const callGetTagList = (searchValue) => {
    getTagList({
      variables: {
        input: {
          name: searchValue,
        },
      },
    })
  }

  useEffect(() => {
    updateNavigateDetail({
      ...navigateDetail,
      pageTitle: title,
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => closeBroadcastForm(),
    })
  }, [])

  useEffect(() => {
    getChatGroupList({
      variables: {
        providerId,
      },
    })
    callGetTagList()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(chatGroupData?.chatGroupStatusList?.data?.list)) {
      updateChatGroupStatus(_.uniqBy([
        {
          id: 1,
          list: chatGroupData?.chatGroupStatusList?.data?.list,
        },
        ...chatGroupStatus,
      ], 'id'))
    }
  }, [chatGroupData])

  const statusList = _.find(chatGroupStatus, (v) => v.id === 1)?.list

  const callBroadCast = (isConfirm) => {
    const promises = fileList?.map((file) => compressImageFile(file?.originFileObj)?.then((resolveFile) => resolveFile))
    Promise.all(promises).then((results) => {
      doBroadcast({
        variables: {
          files: results,
          providerId,
          text,
          isAll: check?.length === statusList?.length,
          chatgroupId: check,
          isMyChat: check.includes(999) && check?.length !== statusList?.length,
          tagIds: _.isEmpty(selectedTag) ? undefined : selectedTag,
          isConfirm,
          messageTag: isLine ? undefined : selectedFacebookTag,
        },
      }).then((resp) => {
        if (resp.data && resp.data.multicastMessage.success) {
          if (resp.data.multicastMessage.message === 'กรุณายืนยัน') {
            setModalConfirmData({ ...modalConfirmData, visible: true, data: resp.data.multicastMessage.data })
          } else {
            message.success(resp.data.multicastMessage.message)
            setFileList([])
            setText('')
            closeBroadcastForm()
          }
        } else if (!resp.loading && !resp.data.multicastMessage.success) {
          message.error(`ไม่สามารถส่งข้อความได้: ${resp.data.multicastMessage.message || 'เกิดข้อผิดพลาด'}`)
        }
      })
    })
  }

  const handleSelectTag = (value) => setSelectedTag(value)

  const handleSelectFacebookTag = (value) => setSelectedFacebookTag(value)

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      {
        modalConfirmData?.visible
        && <Modal
          visible={modalConfirmData?.visible}
          title='ยืนยันจำนวนแชทที่จะประกาศ'
          onCancel={() => setModalConfirmData({ ...modalConfirmData, visible: false })}
          onOk={() => {
            setModalConfirmData({ ...modalConfirmData, visible: false })
            callBroadCast(true)
          }}
        >
          <P>จำนวนแชตทั้งหมด {numeral(modalConfirmData?.data?.length).format('0,0')} คน</P>
        </Modal>
      }
      {
        chatGroupLoading && _.isEmpty(chatGroupData)
          ? <FullPageLoading padding='24px' center fontSize={theme?.fonts?.size?.normal} />
          : <>
            <Div padding='16px 16px 40px' moreStyle={{ height: 'calc(100vh - 127px)', overflow: 'auto' }}>
              <SmallText weightgray100>เลือกกลุ่มสถานะ</SmallText>
              <Div margin='12px 0 0 0' padding='16px 0 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray10}` }}>
                {
                  _.map(statusList, (v, i) => (
                    <Div key={v?.name} margin={i !== 0 ? '16px 0 0' : '0'}>
                      <Checkbox
                        disabled={v.total <= 0}
                        checked={v.total > 0 && _.includes(check, v.id)}
                        onChange={(e) => {
                          if (v?.id === 0) {
                            if (e.target.checked) setCheck(statusList?.map((statusDetail) => statusDetail?.id))
                            else setCheck([])
                          } else if (e.target.checked) {
                            let newList = uniq([v.id, ...check])
                            if (newList?.length === statusList?.length - 1) newList = [...newList, 0]
                            setCheck(newList)
                          } else {
                            const newList = compact(check?.map((selectedId) => {
                              if (selectedId === 0 && check?.length === statusList?.length) return null
                              if (selectedId === v?.id) return null
                              return selectedId
                            }))
                            setCheck(newList)
                          }
                        }}
                      ><SmallText bold>{v.name}</SmallText><Label bold margin='0 0 0 4px' color={theme?.color?.gray40}>({numeral(v.total).format('0,0')} คน)</Label></Checkbox>
                    </Div>
                  ))
                }
              </Div>
              <SmallText margin='32px 0 0' weightgray100>เลือกแท็ก</SmallText>
              <Select
                className='select-with-bg tags'
                mode='multiple'
                filterOption={false}
                style={{ width: '100%' }}
                placeholder='เลือกแท็ก'
                onChange={handleSelectTag}
                loading={getting}
                onSearch={(searchValue) => {
                  delayInput(() => callGetTagList(searchValue))
                }}
              >
                {
                  map(tagData?.tagList?.data?.list, (tag) => (
                    <Select.Option key={tag?.id} value={tag?.id}>{tag?.name}</Select.Option>
                  ))
                }
              </Select>
              {
                !isLine
                && <>
                  <SmallText margin='32px 0 0' weightgray100>Facebook message tag</SmallText>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='เลือกแท็ก'
                    onChange={handleSelectFacebookTag}
                  >
                    {
                      map(FACEBOOK_MESSAGE_TAGS, (tag) => (
                        <Select.Option key={tag?.value} value={tag?.value}>{tag?.label}</Select.Option>
                      ))
                    }
                  </Select>
                </>
              }
              <SmallText margin='32px 0 0' weightgray100>ข้อความ</SmallText>
              <Div margin='12px 0 0 0' padding='16px 0 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray10}` }}>
                <TextArea placeholder='กรอกข้อความ' style={{ width: '100%' }} onChange={(e) => setText(e.target.value)} disabled={broadcastLoading || fileList.length > 0} value={text} />
              </Div>
              <SmallText margin='32px 0 0' weightgray100>เพิ่มรูปภาพ</SmallText>
              <Div display='flex' margin='12px 0 0 0' padding='16px 0 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray10}` }}>
                {
                  fileList.length > 0
                    ? <Div margin='0 8px 0 0' className='broadcast-upload-preview'>
                      <Upload
                        listType='picture-card'
                        fileList={previewFile}
                        onChange={(e) => {
                          setFileList(_.filter(fileList, (o) => o.uid !== e.file.uid))
                          setPreviewFile(e.fileList)
                        }}
                      >
                      </Upload>
                    </Div>
                    : <Upload
                      fileList={fileList}
                      disabled={broadcastLoading || !_.isEmpty(text)}
                      onChange={(e) => {
                        if (_.includes((e.file.type), 'image')) {
                          setFileList(e.fileList)
                          if (previewFile.length === 0 || !(e.file.uid === previewFile[previewFile.length - 1].uid)) {
                            setPreviewFile(
                              [
                                ...previewFile,
                                {
                                  uid: e.file.uid,
                                  name: e.file.name,
                                  url: URL.createObjectURL(e.file.originFileObj),
                                },
                              ],
                            )
                          }
                        }
                      }}
                      showUploadList={false}
                    >
                      <Div height='74px' width='74px' bgColor={theme?.color?.gray10} display='flex' alignItems='center' justifyContent='center' moreStyle={{ borderRadius: '4px', cursor: (broadcastLoading || !_.isEmpty(text)) ? 'not-allowed' : 'pointer' }}>
                        <Icon icon='fas fa-plus' color={theme.color.gray30} />
                      </Div>
                    </Upload>
                }
              </Div>
            </Div>
            <Div display='flex' padding='16px' moreStyle={{ boxShadow: '0px 1px 14px 0px #00000017' }}>
              <Button
                disabled={
                  (_.isEmpty(text) && fileList.length === 0)
                  || _.isEmpty(check)
                  || broadcastLoading
                  || (!isLine && isNil(selectedFacebookTag))
                }
                loading={broadcastLoading}
                weightgray100
                fullWidth
                inverse
                text='ส่งข้อความ'
                color={theme?.color?.success}
                onClick={() => callBroadCast(_.isEmpty(selectedTag))}
              />
            </Div>
          </>
      }
    </NavigateContainer>
  )
}

export default () => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [drawerData, setDrawerData] = useState({
    channelBroadcastVisible: false,
    channelType: '',
  })
  const [getProviderList, { data: providerListData, loading: getting }] = useLazyQuery(PROVIDER_LIST)

  useEffect(() => {
    getProviderList()
  }, [])

  useEffect(() => {
    if (!drawerData?.channelBroadcastVisible) {
      updateNavigateDetail({
        ...navigateDetail,
        pageTitle: 'ประกาศ',
        leftIcon: undefined,
        leftFn: undefined,
      })
    }
  }, [drawerData?.channelBroadcastVisible])

  const closeChannel = () => setDrawerData({ ...drawerData, channelBroadcastVisible: false })

  const drawer = (
    <Drawer
      placement='right'
      closable={false}
      width='100%'
      visible={drawerData?.channelBroadcastVisible}
      bodyStyle={{ padding: 0, height: '100vh' }}
      style={{ transform: 'translateX(0) !important' }}
    >
      <BroadcastForm closeBroadcastForm={closeChannel} title={drawerData?.channelType} providerId={drawerData?.providerId} />
    </Drawer>
  )

  const channelList = filter(providerListData?.providerList?.data?.list, (obj) => obj?.shortName === 'line' || obj?.shortName === 'messenger')?.map((providerDetail) => (
    {
      id: providerDetail?.id,
      type: upperCase(providerDetail?.shortName),
      text: upperFirst(lowerCase(providerDetail?.name)),
    }
  ))

  if (getting) return <FullPageLoading padding='24px' center fontSize={theme?.fonts?.size?.normal} />

  return (
    <Div padding='16px'>
      { drawerData?.channelBroadcastVisible && drawer }
      <SmallText weightgray100 margin='0 0 8px'>ช่องทาง</SmallText>
      {
        channelList?.map((channelDetail) => (
          <Div key={channelDetail?.id} display='flex' alignItems='center' justifyContent='space-between' padding='16px 0' moreStyle={{ cursor: 'pointer', borderBottom: `1px solid ${theme?.color?.gray10}` }} onClick={() => setDrawerData({
            ...drawerData, channelBroadcastVisible: true, channelType: channelDetail?.text, providerId: channelDetail?.id,
          })}>
            <Div display='flex' alignItems='center'>
              <img width='16px' src={getLogo(channelDetail?.type)} />
              <SmallText weightgray100 margin='0 0 0 8px'>{channelDetail?.text}</SmallText>
            </Div>
            <Icon icon='fal fa-chevron-right' color={theme?.color?.gray40} />
          </Div>
        ))
      }
    </Div>
  )
}
