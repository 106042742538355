import React, { createContext, useReducer } from 'react'

export const AccountantRemainingJobsContext = createContext({})
export const AuthorizationContext = createContext({})
export const GlobalContext = createContext({})

const initialState = {
  authorization: {
    test: true,
    User: {
      all: false,
    },
    Permission: {
      all: false,
    },
  },
  chatGroupStatus: [],
  navigateDetail: {},
}

const accountantRemaingJobsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_JOBS':
      return {
        ...state,
        accountantRemainingJobs: action.payload,
      }
    default: return state
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AUTHORIZATION':
      return {
        ...state,
        authorization: action.payload,
      }
    case 'UPDATE_CHAT_GROUP_STATUS':
      return {
        ...state,
        chatGroupStatus: action.payload,
      }
    case 'UPDATE_NAVIGATE':
      return {
        ...state,
        navigateDetail: action.payload,
      }
    default: return state
  }
}

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { authorization, chatGroupStatus, navigateDetail } = state

  const updateAuthorization = (payload) => dispatch({ type: 'UPDATE_AUTHORIZATION', payload })
  const updateChatGroupStatus = (payload) => dispatch({ type: 'UPDATE_CHAT_GROUP_STATUS', payload })
  const updateNavigateDetail = (payload) => dispatch({ type: 'UPDATE_NAVIGATE', payload })

  return (
    <GlobalContext.Provider value={{
      authorization, updateAuthorization, chatGroupStatus, updateChatGroupStatus, navigateDetail, updateNavigateDetail,
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const AccountantRemaingJobsProvider = ({ children }) => {
  const [accountantRemaingJobsState, accountantRemaingJobsDispatch] = useReducer(
    accountantRemaingJobsReducer,
    initialState,
  )

  const { accountantRemainingJobs } = accountantRemaingJobsState

  const updateJobs = (payload) => accountantRemaingJobsDispatch({ type: 'UPDATE_JOBS', payload })

  return (
    <AccountantRemainingJobsContext.Provider value={{ accountantRemainingJobs, updateJobs }}>
      {children}
    </AccountantRemainingJobsContext.Provider>
  )
}
