export const PATH = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  chat: '/chat',
  chatAll: '/chat/all',
  chatLine: '/chat/line',
  chatGroup: {
    chatOpenQuotation: '/chat/open-quotation',
    chatWaitingSo: '/chat/waiting-so',
    chatWaitingDelivery: '/chat/waiting-delivery',
    chatSuccess: '/chat/success',
    chatLineOpenQuotation: '/chat/line/open-quotation',
    chatLineWaitingSo: '/chat/line/waiting-so',
    chatLineWaitingDelivery: '/chat/line/waiting-delivery',
    chatLineSuccess: '/chat/line/success',
    chatMessengerOpenQuotation: '/chat/messenger/open-quotation',
    chatMessengerWaitingSo: '/chat/messenger/waiting-so',
    chatMessengerWaitingDelivery: '/chat/messenger/waiting-delivery',
    chatMessengerSuccess: '/chat/messenger/success',
  },
  chatMessenger: '/chat/messenger',
  chatComment: '/chat/comment',
  quotation: '/quotation',
  quotationCreate: '/quotation/create',
  userCreate: '/user/create',
  userUpdate: '/user/update',
  user: '/user',
  broadcast: '/broadcast',
  broadcastLine: '/broadcast/line',
  userQuotation: '/quotation-detail/:token/:id',
  role: '/role',
}

export default PATH
