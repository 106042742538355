export const RECEIVE_TYPE = {
  MANUAL: 'รับเอง',
  TRANSFER: 'จัดส่ง',
  MANUAL_SUPPLIER: 'รับเองที่ Supplier',
}

export const CHAT_READ_TYPE = [
  'ALL',
  'UNREAD',
]

export const DELIVERY_TYPE_TEXT = {
  MANUAL: 'รับเอง',
  TRANSFER: 'จัดส่ง',
  MANUAL_SUPPLIER: 'รับเองกับผู้ผลิต',
}

export const PAYMENT_TYPE_TEXT = {
  CREDIT: 'เครดิต',
  CASH: 'เงินสด',
  TRANSFER: 'โอนเงิน',
}

export const PAYMENT_TYPE = {
  CREDIT: 'CREDIT',
  CASH: 'CASH',
  TRANSFER: 'TRANSFER',
}

export const DELIVERY_TYPE = {
  MANUAL: 'MANUAL',
  TRANSFER: 'TRANSFER',
  MANUAL_SUPPLIER: 'MANUAL_SUPPLIER',
}

export const CHANNEL_TYPE = {
  LINE: 'LINE',
  MESSENGER: 'MESSENGER',
  FACEBOOK: 'FACEBOOK',
}

export const TRASFER_TYPE = {
  FULLAMOUNT: 'FULLAMOUNT',
  DEPOSIT: 'DEPOSIT',
}

export default RECEIVE_TYPE
