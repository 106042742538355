export const PAYMENT_CHANNEL = {
  COD: {
    key: 'COD',
    text: 'COD เก็บเงินปลายทาง',
  },
  FULLAMOUNT: {
    key: 'FULLAMOUNT',
    text: 'โอนเต็มจำนวน',
  },
}

export const DELIVERY_CHANNEL = {
  MANUAL: {
    key: 'MANUAL',
    text: 'รับเอง',
  },
  TRANSFER: {
    key: 'TRANSFER',
    text: 'จัดส่ง',
  },
}

export const DELIVERY_CHANNEL_PROVIDER = {
  EMS: {
    KEY: 'EMS',
    LABEL: 'EMS',
  },
  // KERRY: {
  //   KEY: 'KERRY',
  //   LABEL: 'Kerry',
  // },
  OTHER: {
    KEY: 'OTHER',
    LABEL: 'Other',
  },
}

export default PAYMENT_CHANNEL
