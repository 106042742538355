export default {
  Chat: {
    TITLE: 'แชท',
    CHILDREN: {
      newCustomer: 'Chat Admin ลูกค้าใหม่',
      crmCustomer: 'Chat Admin CRM',
    },
  },
  Quotation: {
    TITLE: 'ใบเสนอราคา',
    CHILDREN: {
      showCost: 'แสดงต้นทุนกำไรค่าคอมฯ',
      editSeller: 'แก้ไขคนรับค่าคอม',
    },
  },
  Permission: {
    TITLE: 'ดูหน้าที่และการเข้าถึง',
    CHILDREN: {
      all: 'ทั้งหมด',
    },
  },
  User: {
    TITLE: 'จัดการผู้ใช้',
    CHILDREN: {
      all: 'ทั้งหมด',
    },
  },
}
