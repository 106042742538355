import React, { cloneElement } from 'react'

import { AccountantRemaingJobsProvider, GlobalProvider } from './StoreProvider'

function ProviderComposer({ contexts, children }) {
  return contexts.reduce(
    (kids, parent) => cloneElement(parent, {
      children: kids,
    }),
    children,
  )
}
export default ({ children }) => (
  <ProviderComposer
    contexts={[<AccountantRemaingJobsProvider key='account-remaining-jobs-store' />, <GlobalProvider key='autorization-store' />]}
  >
    {children}
  </ProviderComposer>
)
