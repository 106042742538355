/* eslint-disable no-underscore-dangle */
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { ApolloClient, split, ApolloLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from '@apollo/client/cache'
import { matchPath } from 'react-router-dom'
import lodash from 'lodash'
import { Modal } from 'antd'
import { getAccessToken, getURL, removeAccessToken } from './utils/local-data-service'
import URL from './constants/environment'
import history from './history'
import { PATH } from './constants/path'

const errorCode = {
  FORBIDDEN: 500,
  UNAUTHENTICATED: 401,
}

let modalHandleStatus = false

console.info({
  ...process.env,
})

const url = process.env.REACT_APP_BUILD_MODE === 'production' ? URL.production : (process.env.REACT_APP_BUILD_MODE === 'staging') ? URL.staging : URL.development

const token = getAccessToken()
const NETWORK_INTERFACE_URL = lodash.isEmpty(matchPath(history.location.pathname, PATH.userQuotation)) && token ? url.GRAPH_PRIVATE : url.GRAPH_PUBLIC
const SUBSCRIPTION_CLIENT_URL = url.GRAPH_PRIVATE_WS

console.info('////////////')
console.info(NETWORK_INTERFACE_URL, SUBSCRIPTION_CLIENT_URL)
console.info('/////////////')

const authLink = setContext((_, { headers }) => ({
  headers: lodash.isEmpty(matchPath(history.location.pathname, PATH.userQuotation)) && token ? {
    ...headers,
    authorization: `Bearer ${token}`,
  } : {
    ...headers,
  },
}))

const uploadLink = createUploadLink({
  uri: NETWORK_INTERFACE_URL,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: SUBSCRIPTION_CLIENT_URL,
    options: {
      reconnect: true,
    },
  }),
)

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(uploadLink),
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({
      graphQLErrors, networkError,
    }) => {
      console.error(graphQLErrors)
      console.error(networkError)
      if (!lodash.isEmpty(graphQLErrors) && document.getElementsByClassName('error-modal').length === 0) {
        switch (graphQLErrors[0].statusCode) {
          case errorCode.FORBIDDEN:
          {
            if (!modalHandleStatus) {
              modalHandleStatus = !modalHandleStatus
              Modal.error({
                title: graphQLErrors[0].message,
                content: 'code: 1',
                onOk() {
                  modalHandleStatus = !modalHandleStatus
                  window.location = `${getURL().WEB_SERVER}/`
                },
              })
              console.error('FORBIDDEN')
            }
            break
          }
          case errorCode.UNAUTHENTICATED:
          {
            Modal.error({
              title: graphQLErrors[0].message,
              content: 'code: 2',
              onOk() {
                modalHandleStatus = !modalHandleStatus
                removeAccessToken()
                window.location = `${getURL().WEB_SERVER}/`
              },
            })
            console.error('UNAUTHENTICATED')
            break
          }
          default:
          {
            if (lodash.includes(graphQLErrors[0].message, 'jwt expired')) {
              Modal.error({
                className: 'error-modal',
                title: lodash.includes(graphQLErrors[0].message, 'jwt expired') ? 'Session หมดอายุกรุณา Log in ใหม่' : graphQLErrors[0].message,
                content: 'code: 0',
                onOk() {
                  if (lodash.includes(graphQLErrors[0].message, 'jwt expired')) {
                    removeAccessToken()
                    window.location = `${getURL().WEB_SERVER}/login`
                  } else window.location = `${getURL().WEB_SERVER}/`
                },
              })
            }
            break
          }
        }
      }
    }),
    link,
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : null),
  }),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default client
