import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  Button,
  ChatCard,
  Div, DrawerBottom,
  FullPageLoading,
  Icon, IconButton, Input, Label,
  SmallText,
} from '@konsys-ui-custom'
import { useHistory } from 'react-router-dom'
import {
  Drawer,
  Popconfirm,
  message, notification,
} from 'antd'
import dayjs from 'dayjs'
import _, {
  compact, find,
  first,
  flattenDeep, includes, isEmpty,
  isNil,
  isNull, orderBy, uniq, upperCase,
} from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import NavigateContainer from '../../components/navigate'
import { CHANNEL_TYPE } from '../../constants/db-const'
import {
  DELETE_REMINDER, REPLY_MESSAGE, SET_UNREAD, UPDATE_READ_MSG,
} from '../../constants/graph/mutation'
import {
  CHAT_DETAIL, CHAT_LIST,
  PROVIDER_LIST,
} from '../../constants/graph/query'
import { CHAT_CHANGE, CHAT_DETAIL_SUBSCRIPTION } from '../../constants/graph/subscription'
import { getGroupChat } from '../../constants/menu-config'
import { PATH } from '../../constants/path'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import { getLocalStorage, getTokenData } from '../../utils/local-data-service'
import { delayInput, showMessageMutation } from '../../utils/util-services'
import {
  ChatMessages, FilterPage, ModalQuotationForm, OrderHistory, Reminder, SelectCustomerModal, SetOwnerModal, UserQuotationDetail,
} from './components'
import { CustomDrawer, FacebookImage, RotateChevronContainer } from './styled'

const limit = 20

const LoadingInList = ({ margin }) => (
  <Div textAlign='center' margin={margin || '56px 0 0'}>
    <Icon icon='fal fa-spinner fa-pulse' fontSize={theme.fonts.size.subTitle} color={theme.color.tertiaryColor} />
  </Div>
)

const TabTitle = ({
  active, title, icon, margin, onClick,
}) => (
  <Div cursor='pointer' margin={margin} padding='0 0 8px' moreStyle={{ borderBottom: active && `1px solid ${theme.color.primaryColor}` }} onClick={() => onClick()}>
    <Icon color={active ? theme.color.primaryColor : theme.color.gray90} fontSize={theme.fonts.size.label} icon={icon} margin='0 6px 0 0' />
    <SmallText color={active ? theme.color.primaryColor : theme.color.gray90} bold={active}>{title}</SmallText>
  </Div>
)

const ChatDetail = ({
  channelId, messageId, channelType, isCommentChannel, channelName, goBack,
}) => {
  const history = useHistory()
  const [drawerBottomVisible, setDrawerBottomVisible] = useState(false)
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [visible, setVisible] = useState({
    info: false,
    setCustomer: false,
    setOwner: false,
    orderHistory: false,
    userQuotation: false,
  })
  const [isFetchMore, setIsFetchMore] = useState(false)
  const [offset, setOffset] = useState(0)
  const [messages, setMessages] = useState([])
  const [forceLoading, setForceLoading] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [openCollapse, setOpenCollapse] = useState(false)
  const postContent = useRef()
  const [quotationCreateModal, setQuotationCreateModal] = useState(false)
  const {
    data, loading, subscribeToMore, fetchMore: chatMessageFetchMore, refetch: chatDetailRefetch,
  } = useQuery(CHAT_DETAIL, {
    variables: {
      channelId, offset: 0, limit, messageId: messageId || undefined,
    },
  })
  const [doReply, { loading: replyLoading, data: replayData }] = useMutation(REPLY_MESSAGE)
  const [doUpdateReadMsg] = useMutation(UPDATE_READ_MSG)
  const [doSetUnread] = useMutation(SET_UNREAD)
  const [doDeleteReminder, { loading: deleting }] = useMutation(DELETE_REMINDER)

  const callUpdateNavidateCustomer = () => {
    updateNavigateDetail({
      pageTitle: channelName,
      subPageTitle: !isEmpty(data?.chatChannelInfo?.data?.owner)
        ? `(เจ้าของแชต: ${data?.chatChannelInfo?.data?.owner?.firstName} ${data?.chatChannelInfo?.data?.owner?.lastName})${data?.chatChannelInfo?.data?.currentUpsell ? `\n(Upsell: ${data?.chatChannelInfo?.data?.currentUpsell?.firstName} ${data?.chatChannelInfo?.data?.currentUpsell?.lastName})` : ''}`
        : '',
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => {
        goBack()
      },
      rightComponent: <Div padding='0 8px' onClick={() => setDrawerBottomVisible(true)}>
        <Icon color={theme?.color?.offWhite} icon='far fa-ellipsis-v' style={{ cursor: 'pointer' }} />
      </Div>,
    })
  }
  useEffect(() => {
    if (!visible?.setCustomer || !visible?.setOwner) {
      callUpdateNavidateCustomer()
    }
  }, [visible?.setCustomer, quotationCreateModal, data, visible?.setOwner])

  useEffect(() => {
    if (!visible?.userQuotation) callUpdateNavidateCustomer()
  }, [visible?.userQuotation])

  useEffect(() => {
    setForceLoading(true)
    setOffset(0)
    setMessages([])
    subscribeToMore({
      document: CHAT_DETAIL_SUBSCRIPTION,
      variables: { channelId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !(subscriptionData.data.chatChannelInfoSub.data.id === prev.chatChannelInfo.data.id)) return prev
        setMessages(_.uniqBy([
          ...prev.chatChannelInfo.data.chatMessages,
          ...subscriptionData.data.chatChannelInfoSub.data.chatMessages,
        ], 'id'))
        doUpdateReadMsg({ variables: { channelId } })
        return {
          ...prev,
          chatChannelInfo: {
            ...prev.chatChannelInfo,
            data: {
              ...prev.chatChannelInfo.data,
              chatMessage: [
                ..._.differenceBy(prev.chatChannelInfo.data.chatMessages, subscriptionData.data.chatChannelInfoSub.data.chatMessages, 'id'),
                ...subscriptionData.data.chatChannelInfoSub.data.chatMessages,
              ],
            },
          },
        }
      },
    })
  }, [channelId])

  useEffect(() => {
    if (!loading) setForceLoading(false)
  }, [loading])

  useEffect(() => {
    if (!loading && !_.isUndefined(data) && offset === 0) {
      setMessages(data.chatChannelInfo.data.chatMessages)
    }
  }, [data])

  useEffect(() => {
    setOffset(0)
    setIsFetchMore(false)
  }, [messageId])

  useEffect(() => {
    if (postContent?.current?.getBoundingClientRect()?.height > 30 || !_.isEmpty(data?.chatChannelInfo?.data?.facebookPost?.images)) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  })

  if (forceLoading || (loading && _.isEmpty(data))) return <FullPageLoading height={`calc(${window.innerHeight - 54}px)`} center fontSize={theme?.fonts?.size?.subTitle} />

  const openSetCustomer = () => setVisible({ ...visible, setCustomer: true })
  const closeSetCustomer = () => setVisible({ ...visible, setCustomer: false })

  const openSetOwner = () => setVisible({ ...visible, setOwner: true })
  const closeSetOwner = () => setVisible({ ...visible, setOwner: false })

  const openOrderHistory = () => setVisible({ ...visible, orderHistory: true })
  const closeOrderHistory = () => setVisible({ ...visible, orderHistory: false })

  const openUserQuotation = (url) => setVisible({ ...visible, userQuotation: true, userQuotationUrl: url })
  const closeUserQuotation = () => setVisible({ ...visible, userQuotation: false, userQuotationUrl: undefined })

  const closeBottomDrawer = () => setDrawerBottomVisible(false)

  const callDoSetUnread = () => {
    message.loading('ทำเครื่องหมายยังไม่อ่าน')
    closeBottomDrawer()
    doSetUnread({
      variables: {
        id: channelId,
      },
    }).then((resp) => showMessageMutation(resp?.data?.setUnread, () => {
      goBack()
    }))
  }

  const DrawerBottomList = () => {
    const menuList = [
      {
        key: 'setCustomer',
        text: 'ผูกลูกค้าจาก ERP',
        onClick: () => {
          closeBottomDrawer()
          openSetCustomer()
        },
      },
      {
        key: 'quotationView',
        text: 'ดูใบเสนอราคา',
        onClick: () => history.push({
          pathname: PATH?.quotation,
          state: {
            goBackPath: PATH?.chat,
            customerId: data?.chatChannelInfo?.data?.chatContact?.customer?.id,
            customerName: data?.chatChannelInfo?.data?.chatContact?.customer?.name,
            chatProviderName: data?.chatChannelInfo?.data?.chatProvider?.name,
            chatProviderId: data?.chatChannelInfo?.data?.chatProvider?.id,
            chatChannelId: channelId,
            channelName,
            noNeedCustomerId: true,
          },
        }),
      },
      {
        key: 'orderHistory',
        text: 'ดูประวัติออเดอร์ทั้งหมด',
        onClick: () => {
          closeBottomDrawer()
          openOrderHistory()
        },
      },
      {
        key: 'setUnread',
        text: 'ทำเครื่องหมายยังไม่อ่าน',
        onClick: callDoSetUnread,
      },
      {
        key: 'changeOwner',
        text: 'เปลี่ยนเจ้าของแชต',
        onClick: () => {
          closeBottomDrawer()
          openSetOwner()
        },
      },
    ]
    return (
      <DrawerBottom
        onClose={closeBottomDrawer}
        visible={drawerBottomVisible}
      >
        {
          menuList?.map((menuDetail, i) => (
            <Div key={menuDetail?.key} display='flex' alignItems='center' justifyContent='space-between' padding={i !== 0 ? '16px 0' : '0 0 16px'} onClick={menuDetail?.onClick} moreStyle={{ cursor: 'pointer', borderTop: i !== 0 && `1px solid ${theme?.color?.gray10}` }}>
              <SmallText>{menuDetail?.text}</SmallText>
            </Div>
          ))
        }
      </DrawerBottom>
    )
  }

  const closeQuotationCreateModal = () => setQuotationCreateModal(false)
  const callDoDeleteReminder = () => {
    doDeleteReminder({
      variables: {
        chatChannelId: data?.chatChannelInfo?.data?.id,
      },
    }).then((resp) => showMessageMutation(resp?.data?.deleteReminder, () => chatDetailRefetch()))
  }

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div display='flex' flexDirection='column' height={!isEmpty(navigateDetail?.subPageTitle) ? `calc(${window.innerHeight - 90}px)` : `calc(${window.innerHeight - 54}px)`} key={channelId} moreStyle={{ overflow: 'hidden' }}>
        {
          drawerBottomVisible && <DrawerBottomList />
        }
        {
          quotationCreateModal
          && <Drawer
            placement='right'
            closable={false}
            width='100%'
            visible={quotationCreateModal}
            bodyStyle={{ padding: 0, height: window.innerHeight }}
            style={{ transform: 'translateX(0) !important' }}
          >
            <ModalQuotationForm
              visible={quotationCreateModal}
              setVisible={closeQuotationCreateModal}
              quotationFormProps={{
                chatChannelId: channelId,
                customerId: data?.chatChannelInfo?.data?.chatContact?.customer?.id,
                chatProviderId: data?.chatChannelInfo?.data?.providerId,
                customerName: data?.chatChannelInfo?.data?.chatContact?.customer?.name,
                chatProviderName: data?.chatChannelInfo?.data?.chatProvider?.name,
                chatOwner: isEmpty(data?.chatChannelInfo?.data?.owner) ? undefined : { id: data?.chatChannelInfo?.data?.owner?.id, name: `${data?.chatChannelInfo?.data?.owner?.firstName} ${data?.chatChannelInfo?.data?.owner?.lastName}`, roleId: first(data?.chatChannelInfo?.data?.owner?.roles)?.id },
              }}
            />
          </Drawer>
        }
        {
          visible?.orderHistory
          && <Drawer
            placement='right'
            closable={false}
            width='100%'
            visible={visible?.orderHistory}
            onCancel={closeOrderHistory}
            bodyStyle={{ padding: 0, height: window.innerHeight }}
            style={{ transform: 'translateX(0) !important' }}
          >
            <OrderHistory
              onCancel={() => {
                closeOrderHistory()
                callUpdateNavidateCustomer()
              }}
              customerId={data?.chatChannelInfo?.data?.chatContact?.customer?.id}
            />
          </Drawer>
        }
        {
          (visible?.setCustomer || visible?.setOwner)
          && <Drawer
            placement='right'
            closable={false}
            width='100%'
            visible={visible?.setCustomer || visible?.setOwner}
            bodyStyle={{ padding: 0, height: window.innerHeight }}
            style={{ transform: 'translateX(0) !important' }}
          >
            {
              visible?.setCustomer
                ? <SelectCustomerModal
                  data={{
                    customerId: data?.chatChannelInfo?.data?.chatContact?.customer?.id,
                    addressId: data?.chatChannelInfo?.data?.chatContact?.address?.id,
                    customerName: data?.chatChannelInfo?.data?.chatContact?.customer?.name,
                  }}
                  visible={visible?.setCustomer}
                  chatId={channelId}
                  chatContactId={data?.chatChannelInfo?.data?.chatContactId}
                  onCancel={closeSetCustomer}
                  onSuccess={() => {
                    setVisible({ ...visible, setCustomer: false })
                    if (chatDetailRefetch) chatDetailRefetch()
                  }}
                />
                : <SetOwnerModal
                  chatId={channelId}
                  onCancel={closeSetOwner}
                  onSuccess={() => {
                    closeSetOwner()
                    if (chatDetailRefetch) chatDetailRefetch()
                  }}
                />
            }
          </Drawer>
        }
        {
          <Drawer
            placement='right'
            closable={false}
            width='100%'
            visible={visible?.userQuotation}
            bodyStyle={{ padding: 0, height: window.innerHeight }}
            style={{ transform: 'translateX(0) !important' }}
          >
            <UserQuotationDetail
              isOpen={visible?.userQuotation}
              onCancel={closeUserQuotation}
              url={visible?.userQuotationUrl}
            />
          </Drawer>
        }
        <Div padding='0 16px' moreStyle={{ height: 'auto' }}>
          {
            channelId > 0
            && <Div margin='0 -16px' padding='16px' bgColor={theme?.color?.gray10}>
              <Div display='flex' alignItems='flex-start' justifyContent='space-between'>
                <SmallText bold>ข้อมูลลูกค้า</SmallText>
                <RotateChevronContainer open={visible?.info} onClick={() => setVisible({ ...visible, info: !visible?.info })}>
                  <SmallText bold textAlign='right'>{visible?.info ? 'ซ่อน' : 'แสดง'}</SmallText>
                  <Icon icon='fal fa-chevron-up' color={theme?.color?.gray40} margin='0 0 0 8px' fontSize={theme?.fonts?.size?.label} />
                </RotateChevronContainer>
              </Div>
              <Div moreStyle={{ transition: 'max-height 0.3s', overflow: 'hidden', maxHeight: visible?.info ? 1000 : 0 }}>
                {
                  isCommentChannel
                  && <Div margin='12px 0 20px' padding='12px 0 0' moreStyle={{ borderTop: `1px solid ${theme.color.gray40}`, maxHeight: '500px', overflow: 'auto' }}>
                    <SmallText bold display='block'>{data?.chatChannelInfo?.data?.facebookPost?.pageName}<Label margin='0 8px'>-</Label><Label margin='2px 0 0'>โพสต์เมื่อ {moment(data.chatChannelInfo.data.facebookPost?.createTime).format('DD/MM/YYYY HH:mm')}</Label></SmallText>
                    <Div margin='6px 0 0' height={openCollapse ? '100%' : '30px'} moreStyle={{ overflow: openCollapse ? 'auto' : 'hidden', maxHeight: '45vh' }}>
                      <Div display='flex' alignItems='flex-start' justifyContent='space-between' onClick={() => {
                        setOpenCollapse(!openCollapse)
                      }} moreStyle={{ cursor: collapse ? 'pointer' : 'default' }}>
                        <SmallText ref={postContent} style={{ whiteSpace: 'break-spaces' }}>
                          {data.chatChannelInfo.data.facebookPost?.message}
                        </SmallText>
                        {
                          collapse
                          && <Icon margin='4px 0 0' icon='fal fa-chevron-down' style={{ transform: openCollapse ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                        }
                      </Div>
                      <Div margin='8px 0 0' display='flex' alignItems='flex-start' width='100%' moreStyle={{ flexWrap: 'wrap' }}>
                        {
                          data.chatChannelInfo.data.facebookPost?.images?.map((v) => (
                            <FacebookImage totalImage={data.chatChannelInfo.data.facebookPost?.images?.length} key={v.id} src={v.url} />
                          ))
                        }
                      </Div>
                    </Div>
                  </Div>
                }
                {
                  !isCommentChannel && data?.chatChannelInfo?.data?.chatContact
                  && <Div margin='16px 0 0'>
                    <Div display='flex' alignItems='flex-start' justifyContent='space-between'>
                      <SmallText bold color={theme?.color?.gray40}>ชื่อลูกค้า</SmallText>
                      <SmallText bold textAlign='right'>{data?.chatChannelInfo?.data?.chatContact?.customer?.name || '-'}</SmallText>
                    </Div>
                    <Div margin='8px 0 0' display='flex' alignItems='flex-start' justifyContent='space-between'>
                      <SmallText bold color={theme?.color?.gray40}>เบอร์ติดต่อ2</SmallText>
                      <SmallText bold textAlign='right' style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // eslint-disable-next-line no-undef
                          navigator.clipboard.writeText(data.chatChannelInfo.data?.chatContact?.address?.contactPhoneNumber || data.chatChannelInfo.data?.chatContact?.address?.contactOfficeTel)
                          notification.info({
                            message: 'ก็อปปี้เบอร์โทรสำเร็จ',
                          })
                        }}
                      >
                        {data.chatChannelInfo.data?.chatContact?.address?.contactPhoneNumber || data.chatChannelInfo.data?.chatContact?.address?.contactOfficeTel || '-'}
                      </SmallText>
                    </Div>
                  </Div>
                }
                {
                  !isEmpty(data?.chatChannelInfo?.data?.reminderUser)
                  && <>
                    <Div margin='24px 0 0' display='flex' alignItems='flex-start' justifyContent='space-between'>
                      <SmallText bold color={theme?.color?.gray40}>แอดมิน CRM</SmallText>
                      <SmallText bold textAlign='right'>{data?.chatChannelInfo?.data?.reminderUser?.firstName} {data?.chatChannelInfo?.data?.reminderUser?.lastName}</SmallText>
                    </Div>
                    <Div margin='8px 0 0' display='flex' alignItems='flex-start' justifyContent='space-between'>
                      <SmallText bold color={theme?.color?.gray40}>วันที่แจ้งเตือน</SmallText>
                      <SmallText bold textAlign='right'>{dayjs(data?.chatChannelInfo?.data?.reminderDate).format('DD/MM/YYYY')}</SmallText>
                    </Div>
                    <Div margin='8px 0 0' display='flex' alignItems='flex-start' justifyContent='space-between'>
                      <SmallText bold color={theme?.color?.gray40} margin='0 32px 0 0'>หมายเหตุ</SmallText>
                      <SmallText bold textAlign='right'>{data?.chatChannelInfo?.data?.reminder || '-'}</SmallText>
                    </Div>
                    <Popconfirm
                      title='ยืนยันยกเลิกการแจ้งเตือน'
                      onConfirm={callDoDeleteReminder}
                      okText='ยกเลิกแจ้งเตือน'
                      cancelText='ไม่ยกเลิกแจ้งเตือน'
                    >
                      <Button loading={deleting} disabled={deleting} small text='ยกเลิกแจ้งเตือน' icon='fal fa-times' inverse color={theme?.color?.gray40} margin='16px 0 0' style={{ float: 'right' }} />
                    </Popconfirm>
                  </>
                }
                <Button fullWidth margin='16px 0 0' icon='fal fa-plus' text='สร้างใบเสนอราคา' inverse color={theme?.color?.success} onClick={() => {
                  setVisible({ ...visible, info: false })
                  setQuotationCreateModal(true)
                }} />
              </Div>
            </Div>
          }
        </Div>
        <ChatMessages
          isCommentChannel={isCommentChannel}
          channelType={channelType}
          data={data}
          replyLoading={replyLoading}
          replayData={replayData}
          doReply={doReply}
          channelId={channelId}
          messages={messages}
          setMessages={setMessages}
          messageId={messageId}
          isFetchMore={isFetchMore}
          setOpenUserQuotaion={openUserQuotation}
          doFetchMore={() => {
            if (data?.chatChannelInfo?.data?.chatMessages?.length < data?.chatChannelInfo?.data?.totalMessages) {
              chatMessageFetchMore({
                variables: {
                  offset: data?.chatChannelInfo?.data?.chatMessages?.length,
                  channelId,
                  messageId: undefined,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult || !fetchMoreResult.chatChannelInfo.data.id === prev.chatChannelInfo.data.id) return prev
                  if (fetchMoreResult.chatChannelInfo.data.chatMessages.length === 0) return prev
                  setOffset(offset + 10)
                  setIsFetchMore(true)
                  setMessages(_.orderBy(_.uniqBy([
                    ...messages,
                    ...fetchMoreResult.chatChannelInfo.data.chatMessages,
                  ], 'id'), ['id'], 'asc'))
                  return {
                    ...prev,
                    chatChannelInfo: {
                      ...prev.chatChannelInfo,
                      data: {
                        ...prev.chatChannelInfo.data,
                        chatMessages: _.orderBy(_.uniqBy([
                          ...messages,
                          ...fetchMoreResult.chatChannelInfo.data.chatMessages,
                        ], 'id'), ['id'], 'asc'),
                      },
                    },
                  }
                },
              })
            }
          }
          }
        />
      </Div>
    </NavigateContainer>
  )
}

const MessagePage = ({
  channelName, goBack, chatDetailProps,
}) => (
  <ChatDetail {...chatDetailProps} channelName={channelName} goBack={goBack} />
)

const getFilterInputVars = (filterInput) => ({
  name: filterInput.search,
  ..._.pick(filterInput, ['startDate', 'endDate', 'firstMessageStartDate', 'firstMessageEndDate', 'quotationCreatedStartDate', 'quotationCreatedEndDate', 'searchMessages', 'chatgroupIds', 'providerIds', 'tagIds']),
  isRead: filterInput.readType || 'ALL',
  ownerIds: (isEmpty(filterInput?.selectedOwner) || filterInput?.chatOwner === 'checkAll' || filterInput.chatOwner === 'UPSELL')
    ? undefined
    : filterInput?.selectedOwner?.map((o) => o.id),
  upsellIds: filterInput.chatOwner === 'UPSELL'
    ? (isEmpty(filterInput?.selectedOwner) || filterInput?.chatOwner === 'checkAll')
      ? undefined
      : filterInput?.selectedOwner?.map((o) => o.id)
    : undefined,
})

const ChatListWithMessage = (props) => {
  const [innerHeight, setInnerHeight] = useState(window.visualViewport.height)
  const [chatList, setChatList] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [openModal, setOpenModal] = useState({
    customer: false,
    chatGroup: false,
  })
  const [selectedUserReminder, setSelectedUserReminder] = useState([])
  const [drawerBottomVisible, setDrawerBottomVisible] = useState()
  const [isReminderAll, setIsReminderAll] = useState(false)
  const { active } = props
  const selectedChatProvider = _.find(chatList, (v) => v.id === active.activeChat)?.provider

  const resizeHandler = () => {
    const viewport = window.visualViewport
    setTimeout(() => {
      setInnerHeight(viewport.height)
    }, 100)
  }

  useEffect(() => {
    window.visualViewport.addEventListener('resize', resizeHandler)
  }, [])

  useEffect(() => {
    const getLatestText = (obj) => {
      if (obj?.sendedMessage?.type === 'audio' || obj?.receivedFacebookMessageWithAttachment?.attachments?.[0]?.type === 'audio') return '(audio)'
      if (!_.isEmpty(obj?.sendedMessage) || (!_.isEmpty(obj?.receivedImage) || (!isEmpty(obj?.receivedFacebookMessageWithAttachment) && obj?.receivedFacebookMessageWithAttachment?.attachments?.[0]?.type !== 'audio'))) return obj?.sendedMessage?.text || '(image)'
      if (!_.isEmpty(obj?.receivedVideo)) return '(video)'
      if (!_.isEmpty(obj?.receivedSticker)) return '(sticker)'
      if (!_.isEmpty(obj?.sendedFlexMessage)) return 'รายละเอียด Quotation'
      return obj?.receivedMessage?.text || obj?.receivedFacebookText?.text || ''
    }

    const isFacebookComment = selectedChatProvider === CHANNEL_TYPE.FACEBOOK

    const newList = !_.isEmpty(props?.filterInput?.searchMessages) && _.isEmpty(props?.filterInput?.search)
      ? props?.list?.map((v) => {
        const messageList = isFacebookComment ? v.messages : v?.chatMessages
        return (
          {
            name: isFacebookComment ? v?.from?.name : v?.chatContact?.displayName,
            foundItem: isFacebookComment ? v?.messages?.length : v?.chatMessages?.length,
            list: messageList?.map((o) => {
              const latestText = isFacebookComment ? o.message : getLatestText(o)
              const searchText = props?.filterInput?.searchMessages
              const latestTextWithHilight = latestText.replace(searchText, ` ${searchText} `).split(' ').map((splitText, i) => {
                if (splitText === searchText) {
                  return <SmallText color={theme.color.gray50} style={{ backgroundColor: theme.color.hilight }} key={i}>{splitText}</SmallText>
                }
                return splitText
              })

              return (
                {
                  id: v?.id,
                  img: isFacebookComment ? v?.from?.profileUrl : v?.chatContact?.profileUrl,
                  name: isFacebookComment ? v?.from?.name : v?.chatContact?.displayName,
                  time: moment(o?.createdAt).lang('en').calendar(null, {
                    sameDay: moment.tz(o?.createdAt, 'Asia/Bangkok').format('HH:mm'),
                    lastDay: `[${moment().diff(o?.createdAt, 'days') || 1}d]`,
                    lastWeek: `[${moment().diff(o?.createdAt, 'weeks') + 1}w]`,
                    sameElse: 'DD/MM/YYYY',
                  }),
                  message: latestTextWithHilight,
                  messageId: o.id,
                  noti: 0,
                  messageList: 'message detail here',
                  provider: upperCase(v?.chatProvider?.shortName),
                  reminderDate: v?.reminderDate,
                }
              )
            }),
          }
        )
      })
      : props?.list?.map((v) => {
        const latestMessage = v?.chatMessages[v.chatMessages.length - 1]
        const latestText = getLatestText(latestMessage)
        const createAt = isFacebookComment ? v?.createTime : latestMessage?.createdAt
        const latestUpsell = find(orderBy(flattenDeep(v.quotations.map((o) => o?.salesOrder?.lots?.[0].items)), ['createdAt'], ['desc']), (item) => item?.isUpsell)
        return (
          {
            id: v?.id,
            img: v?.chatContact?.profileUrl,
            name: v?.chatContact?.displayName,
            ownerName: v?.owner ? `${v?.owner?.firstName} ${v?.owner?.lastName}` : undefined,
            upsellName: latestUpsell ? `${latestUpsell?.seller?.firstName} ${latestUpsell?.seller?.lastName}` : undefined,
            time: moment(createAt).lang('en').calendar(null, {
              sameDay: moment.tz(createAt, 'Asia/Bangkok').format('HH:mm'),
              lastDay: `[${moment().diff(createAt, 'days') || 1}d]`,
              lastWeek: `[${moment().diff(createAt, 'weeks') + 1}w]`,
              sameElse: 'DD/MM/YYYY',
            }),
            message: latestText,
            noti: v?.id === props?.active?.activeChat ? 0 : v.totalUnread || 0,
            messageList: 'message detail here',
            provider: upperCase(v?.chatProvider?.shortName),
            providerFullname: v?.chatProvider?.name,
            code: v.chatProvider?.shortName !== 'line' ? v?.chatProvider?.code : null,
            reminderDate: v?.reminderDate,
          }
        )
      })
    setChatList(newList)
  }, [props.list])

  useEffect(() => {
    props.subscrieInList()
  }, [props])

  useEffect(() => {
    if (!props?.isSetReminder) {
      setSelectedUserReminder([])
    }
  }, [props?.isSetReminder])

  const detectScroll = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight && props.fetchMore) {
      props.fetchMore()
    }
  }

  useEffect(() => {
    if (active.activeChat && innerHeight < 460) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 100)
    }
  }, [innerHeight])

  const drawer = (
    <CustomDrawer open={active.activeChat !== 0}>
      {
        active.activeChat !== 0
        && <MessagePage
          channelName={active?.activeChatName}
          navigateDetail={props?.navigateDetail}
          updateNavigateDetail={props?.updateNavigateDetail}
          goBack={() => props.setActive({ previousActiveChat: 0, activeChat: 0 })}
          chatDetailProps={{
            channelType: selectedChatProvider,
            isCommentChannel: selectedChatProvider === CHANNEL_TYPE.FACEBOOK,
            onSelectCustomerMenu: () => setOpenModal({ ...openModal, customer: true }),
            onSelectGroupMenu: () => setOpenModal({ ...openModal, chatGroup: true }),
            channelId: active.activeChat,
            messageId: props.messageId,
            customerDisabled: false,
            chatContactData: find(chatList, (obj) => obj?.id === active.activeChat),
            refetchList: props.refetchList,
          }}
        />
      }
    </CustomDrawer>
  )

  const onSelectUser = (selectedId) => {
    if (includes(selectedUserReminder, selectedId)) {
      setSelectedUserReminder(compact(selectedUserReminder?.map((id) => {
        if (id !== selectedId) return id
        return null
      })))
    } else {
      setSelectedUserReminder(uniq([selectedId, ...selectedUserReminder]))
    }
  }

  const renderChatCard = (list, doSetActive) => (
    list?.map((v, index) => (
      <ChatCard
        type={v.provider}
        key={index}
        data={v}
        active={doSetActive && active.activeChat === v.id}
        onClick={(messageId) => {
          _.find(list, (o) => o.id === v.id).noti = 0
          if (props.setActive) {
            props.setActive({ previousActiveChat: _.findIndex(list, (o) => o.id === active.activeChat), activeChat: v.id, activeChatName: v?.name })
          }
          if (props.setMessageId && !_.isNil(messageId)) {
            props.setMessageId(messageId)
          }
        }}
        animateDown={active.previousActiveChat < index}
        isCanSelect={props?.isSetReminder}
        selectedUserReminder={selectedUserReminder}
        setSelectedUserReminder={onSelectUser}
      />
    ))
  )

  const openFilter = () => props?.setFilterVisible(true)
  const closeFilter = () => props?.setFilterVisible(false)

  const filter = (
    <Drawer
      placement='right'
      closable={false}
      width='100%'
      visible={props?.filterVisible}
      bodyStyle={{ padding: 0, height: window.innerHeight }}
      style={{ transform: 'translateX(0) !important' }}
    >
      <FilterPage
        closeFilter={closeFilter}
        providerList={props?.providerList}
        filterInput={props?.filterInput}
        setfilterInput={props?.setfilterInput}
      />
    </Drawer>
  )

  const openBottomDrawer = () => setDrawerBottomVisible(true)
  const closeBottomDrawer = () => {
    setIsReminderAll(false)
    setDrawerBottomVisible(false)
  }

  const reminder = (
    <DrawerBottom
      onClose={closeBottomDrawer}
      visible={drawerBottomVisible}
    >
      <Reminder
        filterInput={getFilterInputVars(props?.filterInput)}
        isReminderAll={isReminderAll}
        selectedUserReminder={selectedUserReminder}
        close={() => closeBottomDrawer()}
        closeAndRefetchList={() => {
          if (props.setIsSetReminder) props.setIsSetReminder(false)
          if (props.refetchList) props.refetchList()
          closeBottomDrawer()
        }}
      />
    </DrawerBottom>
  )

  return (
    <Div bgColor={theme.color.offWhite} display='flex' flexDirection='column' width='100%' moreStyle={{ borderRight: `1px solid ${theme.color.gray40}` }}>
      { drawerBottomVisible && reminder }
      { drawer }
      { props?.filterVisible && filter }
      <Div display='flex' padding='16px'>
        <Input
          padding='6px 8px 5px 30px'
          placeholder={props.isCommentChannel ? 'ชื่อผู้ใช้, ข้อความ' : 'รายชื่อ, ผู้ใช้, ข้อความ'}
          prefix={<Icon icon='prefix fal fa-search' />}
          width='calc(100% - 48px)'
          value={
            props?.search
          }
          onChange={(e) => {
            props.setSearch(e.target.value)
            delayInput(() => {
              if (props.setfilterInput) {
                props.setfilterInput({
                  ...props.filterInput,
                  search: activeTab === 0
                    ? props.search
                    : '',
                  searchMessages: activeTab === 1
                    ? props.search
                    : '',
                  searchUpsellName: activeTab === 2
                    ? props.search
                    : '',
                }, false)
              }
            })
          }}
        />
        <IconButton iconColor={theme?.color?.gray40} color={theme?.color?.gray10} inverse margin='0 0 0 8px' onlyIcon buttonStyle='rectangle' icon='fal fa-filter' onClick={openFilter} />
      </Div>
      <Div flexGrow='1' display='flex' flexDirection='column' moreStyle={{ overflow: 'hidden' }}>
        {
          !_.isEmpty(props?.filterInput?.search) || !_.isEmpty(props?.filterInput?.searchMessages) || !_.isEmpty(props?.filterInput?.searchUpsellName)
            ? <Div>
              <Div margin='0 0 16px 16px' display='flex' moreStyle={{ borderBottom: `1px solid ${theme.color.gray50}` }}>
                <TabTitle
                  onClick={() => {
                    setActiveTab(0)
                    if (props.setfilterInput) {
                      props.setfilterInput({
                        ...props.filterInput, search: props?.filterInput?.searchMessages || props?.filterInput?.searchUpsellName, searchMessages: '', searchUpsellName: '',
                      }, false)
                    }
                  }}
                  active={activeTab === 0}
                  title='ค้นหาผู้ใช้'
                  icon='fal fa-user'
                  margin='0 24px 0 0'
                />
                <TabTitle
                  onClick={() => {
                    setActiveTab(1)
                    if (props.setfilterInput) {
                      props.setfilterInput({
                        ...props.filterInput, searchMessages: props?.filterInput?.search || props?.filterInput?.searchUpsellName, search: '', searchUpsellName: '',
                      }, false)
                    }
                  }}
                  active={activeTab === 1}
                  title='ค้นหาข้อความ'
                  icon='fal fa-comment-alt-lines'
                  margin='0 24px 0 0'
                />
                <TabTitle
                  onClick={() => {
                    setActiveTab(2)
                    if (props.setfilterInput) {
                      props.setfilterInput({
                        ...props.filterInput, searchUpsellName: props?.filterInput?.searchMessages || props?.filterInput?.search, search: '', searchMessages: '',
                      }, false)
                    }
                  }}
                  active={activeTab === 2}
                  title='ค้นหา Upsell'
                  icon='fal fa-chart-user'
                  margin='0 24px 0 0'
                />
              </Div>
              {
                !props.listLoading && activeTab !== 1 && (_.isEmpty(chatList)
                  ? <SmallText margin='56px 0 0' textAlign='center' color={theme.color.gray40} style={{ width: '100%' }}>ไม่พบชื่อผู้ใช้ที่ค้นหา</SmallText>
                  : <SmallText margin='0 16px 8px'>จำนวนแชตทั้งหมด {numeral(props.total || 0).format('0,0')} แชต</SmallText>)
              }
            </Div>
            : props?.isSetReminder
              ? <Div display='flex' justifyContent='space-between' alignItems='center' margin='0 16px 8px'>
                <SmallText bold>เลือกทั้งหมด {numeral(selectedUserReminder?.length).format('0,0')} แชต</SmallText>
                <SmallText bold color={selectedUserReminder?.length > 0 ? theme?.color?.primaryColor : theme?.color?.gray30} style={{ cursor: selectedUserReminder?.length > 0 ? 'pointer' : 'not-allowed' }} onClick={selectedUserReminder?.length > 0 && openBottomDrawer}>ตั้งแจ้งเตือน</SmallText>
              </Div>
              : !props.listLoading && activeTab !== 1 && <Div display='flex' justifyContent='space-between' alignItems='center' margin='0 16px 8px'>
                <SmallText bold>จำนวนแชตทั้งหมด {numeral(props.total || 0).format('0,0')} แชต</SmallText>
                <SmallText bold color={theme?.color?.primaryColor} style={{ cursor: 'pointer' }} onClick={() => {
                  setIsReminderAll(true)
                  openBottomDrawer()
                }}>ตั้งแจ้งเตือน (ทั้งหมด {numeral(props.total || 0).format('0,0')} แชต)</SmallText>
              </Div>
        }
        <Div moreStyle={{ overflow: 'auto', flexBasis: 'calc(100vmax - 147px)' }} onScroll={(e) => detectScroll(e)}>
          {
            props?.listLoading
              ? <LoadingInList />
              : !_.isEmpty(props?.filterInput?.searchMessages) && activeTab === 1
                ? <Div>
                  {
                    _.isEmpty(chatList)
                      ? <SmallText margin='56px 0 0' textAlign='center' color={theme.color.gray40} style={{ width: '100%' }}>ไม่พบข้อความ</SmallText>
                      : chatList.map((v, i) => (
                        <Div key={i} margin={i !== chatList.length - 1 ? '0 0 16px' : '0'}>
                          <Div bgColor={theme.color.veryLightBlue} padding='16px 24px'>
                            <SmallText bold>{v.name}</SmallText>
                            <SmallText margin='0 0 0 8px'>{`พบ ${numeral(v.foundItem).format('0,0')} ข้อความ`}</SmallText>
                          </Div>
                          <Div moreStyle={{ height: 'auto', maxHeight: '500px', overflow: 'auto' }}>
                            {
                              renderChatCard(v?.list, false, '0 0 1px')
                            }
                          </Div>
                        </Div>
                      ))
                  }
                </Div>
                : renderChatCard(chatList, true)
          }
        </Div>
      </Div>
    </Div>
  )
}

export default (props) => {
  const latestFilter = JSON.parse(getLocalStorage('filter-chatList'))
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [innerHeight, setInnerHeight] = useState(window.visualViewport?.height)
  const [search, setSearch] = useState(latestFilter?.search)
  const [filterInput, setfilterInput] = useState({
    search: latestFilter?.search || '',
    providerIds: latestFilter?.channel?.map((o) => parseInt(o)),
    chatOwner: latestFilter?.chatOwner,
    tagIds: latestFilter?.tagIds,
    searchMessages: latestFilter?.searchMessages || '',
    searchUpsellName: latestFilter?.searchUpsellName || '',
    chatgroupIds: latestFilter?.chatStatus?.map((o) => parseInt(o)),
    readType: latestFilter?.readType || 'ALL',
    firstMessageStartDate: latestFilter?.firstMessageStartDate,
    startDate: latestFilter?.startDate,
    endDate: latestFilter?.endDate,
    firstMessageEndDate: latestFilter?.firstMessageEndDate,
    quotationCreatedStartDate: latestFilter?.quotationCreatedStartDate,
    quotationCreatedEndDate: latestFilter?.quotationCreatedEndDate,
    selectedOwner: latestFilter?.selectedOwner,
  })
  const [filterVisible, setFilterVisible] = useState(false)
  const [active, setActive] = useState({
    activeChat: props?.history?.location?.state?.channelId || 0,
    previousActiveChat: 0,
  })
  const [isSetReminder, setIsSetReminder] = useState(false)
  const [messageId, setMessageId] = useState()
  const [isFirstLoadList, setIsFirstLoadList] = useState(true)
  const [getProviders, { data: providerData }] = useLazyQuery(PROVIDER_LIST)
  const [getChatList, {
    data, loading, subscribeToMore: subscribeToMoreChatList, fetchMore, refetch,
  }] = useLazyQuery(CHAT_LIST)

  useEffect(() => {
    if (!isNil(props.channelType) || filterVisible) {
      getProviders({ variables: { isCrm: true } })
    }
  }, [props.channelType, filterVisible])
  let selectedChannelProvider = _.find(data?.chatChannelList?.data?.list, (v) => v.id === active.activeChat)?.chatProvider?.name || props.channelType
  const providerId = _.find(providerData?.providerList?.data?.list, (v) => v.name === selectedChannelProvider)?.id
  const initialInput = JSON.parse(getLocalStorage('filter-chatList'))

  const openReminder = () => setIsSetReminder(true)
  const closeReminder = () => setIsSetReminder(false)

  const updateNavigate = () => {
    updateNavigateDetail({
      pageTitle: undefined,
      rightText: !isSetReminder ? 'เลือก' : 'ยกเลิก',
      rightFn: !isSetReminder ? openReminder : closeReminder,
    })
  }

  const resizeHandler = () => {
    const viewport = window.visualViewport
    setInnerHeight(viewport.height)
  }

  useEffect(() => {
    updateNavigate()
    window.visualViewport.addEventListener('resize', resizeHandler)
    if (!isNull(initialInput)) {
      setfilterInput(initialInput)
    }
  }, [])

  useEffect(() => {
    updateNavigate()
  }, [active, filterVisible, isSetReminder])

  useEffect(() => {
    setActive({ ...active, activeChat: 0 })
    if (_.isEmpty(_.find(data?.chatChannelList?.data?.list, (v) => v.id === active.activeChat))) {
      selectedChannelProvider = props.channelType
    }
  }, [props.channelType])

  useEffect(() => {
    getChatList({
      variables: {
        limit,
        offset: 0,
        ...getFilterInputVars(filterInput),
      },
    })
    setIsFirstLoadList(false)
  }, [props.channelType, filterInput])

  useEffect(() => {
    if (!_.isUndefined(props.location?.pathname)) {
      const group = getGroupChat(props.location?.pathname.includes(PATH.chatLine) ? 'LINE' : props.location?.pathname.includes(PATH.chatMessenger) ? 'MESSENGER' : 'ALL')
      switch (props.location?.pathname) {
        case group[1].path:
          setfilterInput({
            ...filterInput,
            chatgroupId: group[1].dbId,
          })
          break
        case group[2].path:
          setfilterInput({
            ...filterInput,
            chatgroupId: group[2].dbId,
          })
          break
        case group[3].path:
          setfilterInput({
            ...filterInput,
            chatgroupId: group[3].dbId,
          })
          break
        case group[4].path:
          setfilterInput({
            ...filterInput,
            chatgroupId: group[4].dbId,
          })
          break
        default:
          setfilterInput({
            ...filterInput,
            chatgroupId: undefined,
          })
          break
      }
    }
  }, [props.location?.pathname])

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setActive({ activeChat: 0, previousActiveChat: 0 })
    }
  }, [filterInput.chatgroupId])

  useEffect(() => {
    if (!_.isUndefined(props?.history?.location?.state?.channelId)) {
      setActive({ ...active, activeChat: props?.history?.location?.state?.channelId, activeChatName: props?.history?.location?.state?.channelName })
    }
  }, [props?.history?.location?.state?.channelId])

  if ((loading) && isFirstLoadList) return <FullPageLoading height={`calc(${innerHeight - 54}px)`} center fontSize={theme?.fonts?.size?.subTitle} />

  const isCommentChannel = selectedChannelProvider === CHANNEL_TYPE.FACEBOOK

  return (
    <Div display='flex' width='100%' height={`calc(${innerHeight - 74}px)`} moreStyle={{ overflow: 'hidden' }}>
      <ChatListWithMessage
        isSetReminder={isSetReminder}
        setIsSetReminder={setIsSetReminder}
        filterVisible={filterVisible}
        setFilterVisible={setFilterVisible}
        navigateDetail={navigateDetail}
        updateNavigateDetail={updateNavigateDetail}
        isCommentChannel={isCommentChannel}
        channelType={selectedChannelProvider}
        providerId={providerId}
        active={active}
        setActive={(value) => {
          setActive(value)
        }}
        messageId={messageId}
        setMessageId={(value) => setMessageId(value)}
        listLoading={loading && isFirstLoadList}
        listSectionLoading={loading}
        list={data?.chatChannelList?.data?.list}
        refetchList={() => refetch()}
        total={data?.chatChannelList?.data?.total}
        filterInput={filterInput}
        search={search}
        setSearch={setSearch}
        setfilterInput={(value, firstLoadValue) => {
          setfilterInput(value)
          if (_.isEmpty(value.search) || _.isEmpty(value.searchMessages)) {
            setActive({ ...active, activeChat: 0 })
            setMessageId()
          }
          if (!_.isUndefined(firstLoadValue)) {
            setIsFirstLoadList(firstLoadValue)
          }
        }}
        fetchMore={() => {
          setIsFirstLoadList(false)
          fetchMore({
            variables: { offset: data?.chatChannelList?.data?.list.length },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev
              return {
                ...prev,
                chatChannelList: {
                  ...prev.chatChannelList,
                  data: {
                    ...prev.chatChannelList.data,
                    total: prev.chatChannelList.data.total,
                    list: _.uniqBy([...prev.chatChannelList.data.list, ...fetchMoreResult.chatChannelList.data.list], 'id'),
                  },
                },
              }
            },
          })
        }}
        subscrieInList={() => {
          if (!_.isEmpty(data)) {
            subscribeToMoreChatList({
              document: CHAT_CHANGE,
              variables: {
                name: filterInput.search,
                chatgroupId: filterInput.chatgroupId,
                isRead: filterInput.readType,
              },
              updateQuery: (prev, { subscriptionData }) => {
                const tokenData = getTokenData()

                const hasNewCustomerPermissionIds = [1, 2]
                const hasCrmCustomerPermissionIds = [3, 4]

                const getSubChatGroupId = subscriptionData?.data?.chatChannelChanged?.data?.chatGroupStatus?.id

                const isInCrmCustomer = tokenData?.hasCrmCustomerPermission && includes(hasCrmCustomerPermissionIds, getSubChatGroupId)
                const isInNewCustomer = tokenData?.hasNewCustomerPermission && includes(hasNewCustomerPermissionIds, getSubChatGroupId)
                const isNotUpdate = !isInCrmCustomer && !isInNewCustomer
                if (!subscriptionData.data || isNotUpdate) return prev
                if (
                  (
                    (_.isNil(providerId) && (_.isNil(latestFilter?.channel) || _.isEmpty(latestFilter?.channel)))
                  || subscriptionData.data.chatChannelChanged.data.providerId === providerId
                  || includes(latestFilter?.channel, `${subscriptionData?.data?.chatChannelChanged?.data?.chatProvider?.id}`)
                  ) && _.isEmpty(filterInput.searchMessages)) {
                  if (subscriptionData.data.chatChannelChanged.type === 'PUSH_MESSAGE') {
                    const newList = _.intersectionBy([subscriptionData.data.chatChannelChanged.data], prev.chatChannelList.data.list, 'id')
                    return {
                      ...prev,
                      chatChannelList: {
                        ...prev.chatChannelList,
                        data: {
                          ...prev.chatChannelList.data,
                          total: prev.chatChannelList.data.total,
                          list: (!_.isEmpty(filterInput.search) || !_.isUndefined(filterInput.chatgroupId))
                            ? _.uniqBy(_.union((newList || []), prev.chatChannelList.data.list), 'id')
                            : _.uniqBy(_.union([subscriptionData.data.chatChannelChanged.data], prev.chatChannelList.data.list), 'id'),
                        },
                      },
                    }
                  }
                  const index = _.findIndex(prev.chatChannelList.data.list, (o) => o.id === subscriptionData.data.chatChannelChanged.data.id)
                  const newList = _.cloneDeep(prev.chatChannelList.data.list)
                  newList[index] = subscriptionData.data.chatChannelChanged.data
                  return {
                    ...prev,
                    chatChannelList: {
                      ...prev.chatChannelList,
                      data: {
                        ...prev.chatChannelList.data,
                        total: prev.chatChannelList.data.total,
                        list: newList,
                      },
                    },
                  }
                }
                return prev
              },
            })
          }
        }}
        providerList={providerData?.providerList?.data?.list}
      />
    </Div>
  )
}
