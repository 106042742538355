import React, { useEffect, useState, useContext } from 'react'
import numeral from 'numeral'
import { nanoid } from 'nanoid'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Div, Input, SmallText, DrawerBottom, Icon, Button, Label, FullPageLoading,
} from '@konsys-ui-custom'
import { Radio, Menu, Dropdown } from 'antd'
import { isEmpty, keys, find, round } from 'lodash'
import { GlobalContext } from '../../store/StoreProvider'
import NavigateContainer from '../../components/navigate'
import { theme } from '../../styles/variables'
import { delayInput, showMessageMutation } from '../../utils/util-services'
import { RowSelectProductWrapper, CustomButton, TextDropdownContainer } from './styled'
import { VARIANT_LIST_FOR_QUOTATION, VARIANT_INFO_FOR_QUOTATION } from '../../constants/graph/query'
import { UPDATE_UPSELL_STATUS } from '../../constants/graph/mutation'
import { UPSELL_STATUS } from '../../constants/status/quotation'

export const Status = ({ text, color }) => (
  <Div bgColor={color || theme?.color?.primaryColor} padding='4px 16px' moreStyle={{ borderRadius: '32px' }}>
    <Label color={theme?.color?.offWhite}>{text}</Label>
  </Div>
)

const RowProduct = ({
  isFree, isSelect, data, onClick,
}) => {
  const isShowProduct = !isSelect || (isSelect && !isEmpty(data))
  return (
    <Div padding='12px' display='flex' alignItems='center' justifyContent='space-between' bgColor={theme?.color?.gray10} moreStyle={{ border: `1px solid ${theme?.color?.gray10}`, borderRadius: '4px', cursor: 'pointer' }} onClick={onClick}>
      <Div display='flex' alignItems='center'>
        {
          isShowProduct && !isEmpty(data?.img)
          && <Div width='40px' margin='0 12px 0 0'>
            <img width='100%' src={data?.img} />
          </Div>
        }
        <SmallText color={isShowProduct ? theme?.color?.gray100 : theme?.color?.gray40}>{isShowProduct ? data?.name : 'เลือกสินค้า'}</SmallText>
      </Div>
      <Div>
        {
          isShowProduct && <SmallText weightgray100 color={theme?.color?.primaryColor}>฿ {numeral(isFree ? 0 : data?.salePrice).format('0,0.00')}</SmallText>
        }
        {
          isSelect && <Icon margin='0 0 0 16px' icon='fal fa-chevron-down' color={theme?.color?.gray40} />
        }
      </Div>
    </Div>
  )
}

const SelectProductList = ({
  selectedProduct, onCancel, onSelect, sellerData,
}) => {
  const [selectItem, setSelectItem] = useState(selectedProduct)
  const [getVariantList, { loading: variantLoading, data: variantData }] = useLazyQuery(VARIANT_LIST_FOR_QUOTATION)
  const [getVariantInfo, { loading: variantInfoLoading, data: variantInfoData }] = useLazyQuery(VARIANT_INFO_FOR_QUOTATION)

  const callGetVariantList = (search) => {
    getVariantList({
      variables: {
        search,
      },
    })
  }

  useEffect(() => {
    callGetVariantList()
  }, [])

  const productList = variantData?.variantList?.data?.map((variantDetail) => (
    {
      id: variantDetail?.id,
      img: variantDetail?.file?.url,
      name: variantDetail?.variantName,
      salePrice: variantDetail?.salesPrice,
    }
  ))

  useEffect(() => {
    if (!variantInfoLoading && !isEmpty(variantInfoData?.variantInfo?.data)) {
      const getData = variantInfoData?.variantInfo?.data
      onSelect({
        ...find(productList, (obj) => obj?.id === getData?.id),
        cost: getData?.inventoryOption?.avgCost,
        com: getData?.commission,
      })
      onCancel()
    }
  }, [variantInfoData])

  const getOtherDetailSelectItem = (selectedItem) => {
    getVariantInfo({
      variables: {
        id: selectedItem?.id,
        sellerId: sellerData?.id,
      },
    })
  }

  return (
    <Div margin='32px -16px 0' padding='0 16px 16px' moreStyle={{ height: '100%', overflow: 'hidden' }}>
      <Input padding='6px 8px 5px 30px' placeholder='ค้นหารายการสินค้า / บริการ' prefix={<Icon icon='prefix fal fa-search' />} width='100%' onChange={(e) => {
        const getValue = e.target.value
        delayInput(() => callGetVariantList(getValue))
      }} />
      <SmallText bold margin='24px 0 8px'>รายการสินค้า / บริการ</SmallText>
      <Div moreStyle={{ height: 'auto', maxHeight: 'calc(90vh - 247px)', overflow: 'auto' }}>
        {
          variantLoading
            ? <FullPageLoading padding='24px' center fontSize={theme?.fonts?.size?.normal} />
            : productList?.map((productDetail, i) => (
              <RowSelectProductWrapper key={productDetail?.id} margin={i === 0 ? '0' : '8px 0 0'}>
                <input type='radio' id={`product-${productDetail?.id}`} name='productList' checked={selectItem?.id === productDetail?.id} />
                <label htmlFor={`product-${productDetail?.id}`}>
                  <RowProduct data={productDetail} onClick={() => setSelectItem(productDetail)} />
                </label>
              </RowSelectProductWrapper>
            ))
        }
      </Div>
      <Div margin='40px -16px 0' display='flex' padding='16px' moreStyle={{ boxShadow: '0px 1px 14px 0px #00000017' }}>
        <Button weightgray100 fullWidth margin='0 16px 0 0' inverse text='ยกเลิก' color={theme?.color?.gray10} textColor={theme?.color?.gray40} onClick={onCancel} />
        <Button disabled={isEmpty(selectItem) || variantInfoLoading} loading={variantInfoLoading} weightgray100 fullWidth inverse text='เลือก' color={theme?.color?.success} onClick={() => getOtherDetailSelectItem(selectItem)} />
      </Div>
    </Div>
  )
}

export const ProductForm = ({
  selectedData, closeProductForm, onSelect, isEdit, userData, sellerData,
}) => {
  const defaultVars = {
    id: nanoid(),
    productType: 'PRODUCT',
    selectedProduct: {},
    amount: 1,
    discount: 0,
  }
  const [vars, setVars] = useState(selectedData || defaultVars)
  const [showSelectProduct, setShowSelectProduct] = useState(false)
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)

  useEffect(() => {
    updateNavigateDetail({
      ...navigateDetail,
      pageTitle: `${isEdit ? 'แก้ไข' : 'เพิ่ม'}สินค้าและบริการ`,
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => closeProductForm(),
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(userData?.userDetail?.data)) {
      setVars({
        ...vars,
        sale: `${userData?.userDetail?.data?.firstName} ${userData?.userDetail?.data?.lastName}`,
      })
    }
  }, [userData])

  const openSelectProduct = () => setShowSelectProduct(true)
  const closeSelectProduct = () => setShowSelectProduct(false)
  const checkDisabledButton = () => isEmpty(vars?.selectedProduct) || isEmpty(vars?.productType)

  const drawer = (
    <DrawerBottom
      onClose={closeSelectProduct}
      visible={showSelectProduct}
    >
      <SelectProductList sellerData={sellerData} selectedProduct={vars?.selectedProduct} onCancel={closeSelectProduct} onSelect={(value) => setVars({ ...vars, selectedProduct: value })} />
    </DrawerBottom>
  )

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      { showSelectProduct && drawer }
      <Div padding='16px' moreStyle={{ height: 'calc(100vmax - 127px)', overflow: 'auto' }}>
        <Div>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 16px'>เลือกประเภทสินค้า</SmallText>
          <Radio.Group value={vars?.productType} onChange={(e) => setVars({ ...vars, productType: e.target.value })}>
            <Radio value='PRODUCT'><SmallText>สินค้าปกติ</SmallText></Radio>
            <Radio value='FREE'><SmallText>ของแถม</SmallText></Radio>
          </Radio.Group>
        </Div>
        <Div margin='16px 0 0'>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 16px'>เลือกสินค้า</SmallText>
          <RowProduct isFree={vars?.productType === 'FREE'} isSelect data={vars?.selectedProduct} onClick={openSelectProduct} />
        </Div>
        <Div margin='16px 0 0'>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 16px'>เลือกจำนวน</SmallText>
          <Input pattern='\d*' type='number' state='default' rule={{ type: 'int' }} width='100%' placeholder='จำนวน' value={vars?.amount} onChange={(e) => setVars({ ...vars, amount: e.target.value })} />
        </Div>
        <Div margin='16px 0 0'>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 16px'>ส่วนลด (ต่อชิ้น)</SmallText>
          <Input pattern='\d*' type='number' disabled={vars?.productType === 'FREE'} state='default' rule={{ type: 'float' }} value={(vars?.discount === 0 || vars?.productType === 'FREE') ? '' : vars?.discount} width='100%' placeholder='ราคาส่วนลด (หากมี)' onChange={(e) => setVars({ ...vars, discount: round(parseFloat(e.target.value), 2) })} />
        </Div>
      </Div>
      <Div display='flex' padding='16px' bgColor={theme?.color?.offWhite} moreStyle={{
        position: 'fixed', bottom: 0, left: 0, right: 0, boxShadow: '0px 1px 14px 0px #00000017', zIndex: 2,
      }}>
        <Button disabled={checkDisabledButton()} weightgray100 fullWidth inverse text={`${isEdit ? 'แก้ไข' : 'เพิ่ม'}รายการ`} color={theme?.color?.success} onClick={() => {
          onSelect(vars)
          closeProductForm()
        }} />
      </Div>
    </NavigateContainer>
  )
}

export const ProductDisplay = ({
  pdDetail, totalPrice, totalCost, setDrawerDetail, removeSelectedProduct, displayOnly,
}) => {
  const { authorization } = useContext(GlobalContext)
  const isHavePermissionShowCost = authorization?.Quotation?.showCost

  return (
    <Div margin='16px 0 0'>
      <Div display='flex' alignItems='center' justifyContent='space-between'>
        <SmallText bold>{pdDetail?.isFree ? 'ของแถม' : 'สินค้า'}</SmallText>
        <SmallText bold color={theme?.color?.gray40}>เพิ่มโดย: {pdDetail?.sale}</SmallText>
      </Div>
      <Div margin='8px -16px 0' padding='16px 16px 0' bgColor={theme?.color?.gray10}>
        <Div display='flex'>
          {
            !isEmpty(pdDetail?.img)
            && <Div width='15%' margin='0 16px 0 0' moreStyle={{ minWidth: '48px', maxWidth: '150px' }}>
              <img src={pdDetail?.img} width='100%' />
            </Div>
          }
          <Div moreStyle={{ flexGrow: 1 }}>
            <Div display='flex' justifyContent='space-between' alignItems='flex-start'>
              <SmallText weightgray100 breakAll style={{ width: '50%' }}>{pdDetail?.name}</SmallText>
              <Div margin='0 0 0 8px'>
                <SmallText bold textAlign='right' margin='0 8px 0 0' color={theme?.color?.gray40}>{numeral(pdDetail?.amount).format('0,0')}X</SmallText>
                <SmallText bold textAlign='right'>฿ {numeral(pdDetail?.isFree ? 0 : pdDetail?.salePrice).format('0,0.00')}</SmallText>
              </Div>
            </Div>
            <Div margin='8px 0' display='flex' justifyContent='space-between' alignItems='center'>
              <SmallText style={{ width: '50%' }}>ส่วนลด</SmallText>
              <SmallText bold textAlign='right' color={theme?.color?.gray40}>฿ {numeral(pdDetail?.isFree ? 0 : pdDetail?.discount * pdDetail?.amount).format('0,0.00')}</SmallText>
            </Div>
            <Div display='flex' justifyContent='space-between' alignItems='center'>
              <SmallText style={{ width: '50%' }}>ราคารวม</SmallText>
              <SmallText weightgray100 textAlign='right' color={theme?.color?.primaryColor}>฿ {numeral(pdDetail?.isFree ? 0 : totalPrice).format('0,0.00')}</SmallText>
            </Div>
          </Div>
        </Div>
        {
          isHavePermissionShowCost
          && <Div display='flex' padding={displayOnly ? '16px 0' : '0'} margin='16px 0 0'>
            <Div width='calc(100% / 3)' display='flex' justifyContent='space-between' alignItems='center' padding='2px 8px'>
              <Label color={theme?.color?.gray40}>ต้นทุน</Label>
              <Label bold textAlign='right'>฿ {numeral(totalCost).format('0,0.00')}</Label>
            </Div>
            <Div width='calc(100% / 3)' display='flex' justifyContent='space-between' alignItems='center' padding='2px 8px' moreStyle={{ borderLeft: '1px solid #C2C9D24D', borderRight: '1px solid #C2C9D24D' }}>
              <Label color={theme?.color?.gray40}>กำไร</Label>
              <Label bold textAlign='right'>฿ {pdDetail?.isFree && '-'}{numeral(pdDetail?.isFree ? totalCost : totalPrice - totalCost).format('0,0.00')}</Label>
            </Div>
            <Div width='calc(100% / 3)' display='flex' justifyContent='space-between' alignItems='center' padding='2px 8px'>
              <Label color={theme?.color?.gray40}>ค่าคอมฯ</Label>
              <Label bold textAlign='right'>฿ {numeral(pdDetail?.isFree ? 0 : pdDetail?.com * pdDetail?.amount).format('0,0.00')}</Label>
            </Div>
          </Div>
        }
        {
          !displayOnly
          && <Div display='flex' margin='16px 0 0' padding='8px 0' moreStyle={{ borderTop: '1px solid #C2C9D24D', borderBottom: `1px solid ${theme?.color?.gray30}` }}>
            <Div width='50%' moreStyle={{ borderRight: '1px solid #C2C9D24D' }}>
              <CustomButton small rightBorder color={theme?.color?.gray100} fullWidth text='แก้ไขรายละเอียด' icon='fal fa-edit' onClick={() => setDrawerDetail({ isShowProductForm: true, editId: pdDetail?.keyId })} />
            </Div>
            <CustomButton small width='50%' color={theme?.color?.gray100} text='ลบสินค้า' icon='fal fa-trash-alt' onClick={() => removeSelectedProduct(pdDetail?.keyId)} />
          </Div>
        }
      </Div>
    </Div>
  )
}

export const UpsellDropdown = ({ quotationId, value, onSuccess }) => {
  const [updateUpsellStatus, { loading: updating }] = useMutation(UPDATE_UPSELL_STATUS)
  const clickUpsellStatus = (newStatus) => {
    updateUpsellStatus({
      variables: {
        quotationId,
        status: newStatus,
      },
    }).then((resp) => showMessageMutation(resp?.data?.updateUpsellStatus, () => onSuccess && onSuccess()))
  }
  const menu = (
    <Menu style={{ minWidth: '100px' }}>
      {
        keys(UPSELL_STATUS)?.map((statusKey) => (
          <Menu.Item disabled={statusKey === value} key={statusKey} onClick={() => clickUpsellStatus(statusKey)}>
            <SmallText id={`${quotationId}-${statusKey}`} display='block'>{UPSELL_STATUS?.[statusKey]?.text}</SmallText>
          </Menu.Item>
        ))
      }
    </Menu>
  )
  return (
    <Dropdown overlayClassName={`upsell-${quotationId}`} overlay={menu} disabled={updating} style={{ cursor: 'not-allowed' }}>
      <TextDropdownContainer loading={updating}>
        <Label bold margin='0 8px 0 0' color={UPSELL_STATUS?.[value]?.color}>{UPSELL_STATUS?.[value]?.text}</Label>
        <Icon color={theme?.color?.gray30} icon={updating ? 'fal fa-spinner fa-pulse' : 'fal fa-chevron-down'} />
      </TextDropdownContainer>
    </Dropdown>
  )
}

export default ProductForm
