export default {
  development: {
    CLIENT_ID: process.env.CLIENT_ID,
    CLIENT_SECRET: process.env.CLIENT_SECRET,
    GRANT_TYPE: process.env.GRANT_TYPE,
    GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE_DEV,
    GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC_DEV,
    FILE_SERVER: process.env.REACT_APP_FILE_SERVER_DEV,
    API_HOST: process.env.REACT_APP_FILE_SERVER_DEV,
    GRAPH_PRIVATE_WS: process.env.REACT_APP_GRAPH_PRIVATE_SUBSCRIPTION_DEV,
    WEB_SERVER: process.env.REACT_APP_WEB_SERVER_DEV,
    EPR_SERVER: process.env.REACT_APP_ERP_SERVER_DEV,
  },
  staging: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: process.env.GRANT_TYPE,
    GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE_UAT,
    GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC_UAT,
    FILE_SERVER: process.env.REACT_APP_FILE_SERVER_UAT,
    API_HOST: process.env.REACT_APP_FILE_SERVER_UAT,
    GRAPH_PRIVATE_WS: process.env.REACT_APP_GRAPH_PRIVATE_SUBSCRIPTION_UAT,
    WEB_SERVER: process.env.REACT_APP_WEB_SERVER_UAT,
    EPR_SERVER: process.env.REACT_APP_ERP_SERVER_UAT,
  },
  production: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: process.env.GRANT_TYPE,
    GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE_PROD,
    GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC_PROD,
    FILE_SERVER: process.env.REACT_APP_FILE_SERVER_PROD,
    API_HOST: process.env.REACT_APP_FILE_SERVER_PROD,
    GRAPH_PRIVATE_WS: process.env.REACT_APP_GRAPH_PRIVATE_SUBSCRIPTION_PROD,
    WEB_SERVER: process.env.REACT_APP_WEB_SERVER_PROD,
    EPR_SERVER: process.env.REACT_APP_ERP_SERVER_PROD,
  },
}
