/* eslint-disable import/no-cycle */
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  AutoComplete,
  Checkbox as CheckboxAntd,
  Drawer,
  Radio as RadioAntd,
  Select as SelectAntd,
  Skeleton,
  Tooltip, Upload,
  message,
} from 'antd'
import dayjs from 'dayjs'
import Konsys from 'konsys-ui'
import _, { includes, omit } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css, withTheme } from 'styled-components'
import { searchAddressByZipcode } from 'thai-address-database'
import WaveSurfer from 'wavesurfer.js'
import Facebook from '../../assets/channel/facebook.png'
import Line from '../../assets/channel/line.png'
import Messenger from '../../assets/channel/messenger.png'
import { SEND_QUOTATION } from '../../constants/graph/mutation'
import { USER_LIST } from '../../constants/graph/query'
import { QUOTATION_STATUS, colorSelector } from '../../constants/status/quotation'
import { theme } from '../../styles/variables'
import { getURL } from '../../utils/local-data-service'
import { delayInput } from '../../utils/util-services'
import {
  ActiveLine,
  Avatar,
  AvatarContainerWithChannel,
  AvatarSmall,
  CollapseContentWrapper, CollapseWrapper,
  DividerStyled,
  RotateChevronContainer,
  StatusDot, StatusLine,
  TabContentWrapper,
  TabHeaderWrapper, TabTitleWrapper,
  TabWrapper,
  WaveWrapper,
} from './styled'

export const Icon = withTheme(Konsys.Icon)

export const H1 = withTheme(Konsys.H1)
export const H2 = withTheme(Konsys.H2)
export const H3 = withTheme(Konsys.H3)
export const H4 = withTheme(Konsys.H4)
export const Radio = withTheme(Konsys.Radio)
export const Loading = withTheme(Konsys.Loading)
export const Select = withTheme(Konsys.Select)
export const Collapse = withTheme(Konsys.Collapse)
export const LoadingIcon = withTheme(Konsys.LoadingIcon)

export const TextLink = withTheme(Konsys.TextLink)
export const Checkbox = withTheme(Konsys.Checkbox)
export const RadioGroup = withTheme(Konsys.RadioGroup)

export const InputWithLabelCustom = withTheme(Konsys.InputWithLabel)

export const Div = withTheme(Konsys.Div)

/* ====================== EXTEND COMPONENT =========================== */

const defaultFont = css`
  ${(props) => props.weightgray100 && css`
    font-weight: ${theme?.fonts?.style?.gray100};
  `}
  ${(props) => props.breakAll && css`
    word-break: break-all;
  `}
`

const defaultInput = css`
  line-height: 27px;
  background-color: ${theme?.color?.gray10};
  border: 1px solid ${theme?.color?.gray10};
  padding: ${(props) => props.padding || '8px 8px 4px'};
`

export const P = styled(withTheme(Konsys.P))`
  ${defaultFont}
`

export const SmallText = styled(withTheme(Konsys.SmallText))`
  ${defaultFont}
`

export const Label = styled(withTheme(Konsys.Label))`
  ${defaultFont}
`

export const Input = styled(withTheme(Konsys.Input))`
  ${defaultInput}
  &::placeholder {
    line-height: 27px;
  }
  + i.prefix {
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Button = styled(withTheme(Konsys.Button))`
  font-weight: ${theme.fonts.style.light};
  > p {
    font-family: ${theme.fonts.family.normal};
    ${(props) => props.textColor && css`
      color: ${props.textColor} !important;
    `}
    ${(props) => props.weightgray100 && css`
      font-weight: ${theme?.fonts?.style?.gray100};
    `}
  }
  &:disabled {
    > i {
      color: ${(props) => (props.color ? `${props.color}33` : `${theme.color.primaryColor}33`)} !important;
    }
  }
  ${(props) => props.asMenuRow && css`
    padding: 12px 0;
    border-radius: 0;
    border: none !important;
    border-bottom: 1px solid ${theme?.color?.gray10} !important;
    text-align: left;
    > p {
      font-size: ${theme?.fonts?.size?.small};
      color: ${theme?.color?.gray100} !important;
    }
    &:disabled {
      background-color: ${theme?.color?.offWhite} !important;
    }
  `}
`

export const InputSearchList = styled(withTheme(Input))`
  border: none !important;
  border-bottom: 1px solid ${theme.color.gray40} !important;
  padding: 21px 0 21px 46px;
  line-height: 30px;
  &::placeholder {
    line-height: 30px;
  }
  + i {
    color: ${theme.color.gray90} !important;
    font-size: ${theme.fonts.size.normal};
    left: 16px !important;
    top: 30px !important;
  }
`

export const InputChat = styled(withTheme(Input))`
  border: none !important;
  padding: 0;
`

export const SuperSmallText = styled(Label)`
  font-size: 10px;
`

export const ExpandButton = styled(withTheme(Konsys.Button))`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  transition: all 0.3s ease;
  > i {
    line-height: 0.8;
    margin: 0 !important;
  }
  > span {
    font-size: ${theme.fonts.size.small};
    display: inline-block;
    width: auto;
    max-width: 0px;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.1s ease;
  }
  &:hover {
    padding: 4px 16px;
    width: auto;
    background-color: ${theme.color.primaryColor};
    > i {
      color: white;
      margin: 0 6px 0 0 !important;
    }
    > span {
      color: white;
      max-width: 400px;
    }
  }
`

/* ====================== CUSTOM COMPONENT =========================== */

export const TextArea = styled.textarea`
  ${defaultInput}
  outline: 0 !important;
  transition: all 0.5s ease;
  border-radius: 4px;
  font-family: ${theme?.fonts?.family?.normal};
  font-size: ${theme?.fonts?.size?.small};
  color: ${theme?.color?.gray100}
  &:hover {
    border: 1px solid ${theme?.color?.primaryColor};
  }
  &::placeholder {
    color: ${theme?.color?.gray30};
  }
  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${theme?.color?.gray10};
  }
`

export const CustomLink = styled.a`
  color: ${theme.color.secondaryColor};
  text-decoration: underline !important;
  display: block;
  font-size: ${theme.fonts.size.normal};
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${theme.color.tertiaryColor};
  }
`

export const ComponentWithLabelLarge = (props) => (
  <Div display='inline-block' {...props}>
    <P display='block' {...props.title}>
      {
        !_.isUndefined(props.title.icon) && <Icon margin='0 8px 0 0' icon={props.title.icon} />
      }
      {props.title.text}
    </P>
    {
      props.children
    }
  </Div>
)

export const InputWithLabel = (props) => (
  <ComponentWithLabelLarge margin='8px 0' {...props}>
    <Input {...props.input} onChange={(e) => props.onChange && props.onChange(e)} />
  </ComponentWithLabelLarge>
)

export const SelectWithLabel = (props) => (
  <ComponentWithLabelLarge {...props}>
    <CustomSelect {...props.select} style={{ width: 200, ...props.select.style }} options={props.select.options} />
  </ComponentWithLabelLarge>
)

export const CustomSelect = (props) => (
  <SelectAntd {...props}>
    {
      !_.isEmpty(props.options) && props.options.map((v, i) => (
        <SelectAntd.Option key={i} value={v.value} {...v}>
          {
            props.tooltip ? <Tooltip title={v.text}>{v.text}</Tooltip> : v.text
          }
        </SelectAntd.Option>
      ))
    }
  </SelectAntd>
)

export const CustomCollapse = (props) => {
  const [isOpen, setIsOpen] = useState(props.open)
  return (
    <CollapseWrapper open={isOpen} fullHeight={props.fullHeight} minHeight={props.minHeight} {..._.omit(props, ['onClick'])}>
      <Konsys.Div
        padding='0 0 6px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        cursor='pointer'
        moreStyle={{ borderBottom: `1px solid ${theme.color.veryLightBlue}` }}
        onClick={() => {
          setIsOpen(!isOpen)
          if (props.onClick) {
            props.onClick()
          }
        }}
      >
        {
          !_.isObject(props.text)
            ? <TextWithIcon text={props.text} bold />
            : props.text.render
        }
        <Konsys.Icon icon='fal fa-chevron-up' color={theme.color.lightBlue} />
      </Konsys.Div>
      <CollapseContentWrapper>
        {
          props.children
        }
      </CollapseContentWrapper>
    </CollapseWrapper>
  )
}

export const Divider = (props) => (
  <DividerStyled {...props} />
)

export const DashedButton = (props) => (
  <Button small style={{
    borderStyle: 'dashed', background: 'none', padding: '6px 16px', color: theme.color.tertiaryColor, borderColor: theme.color.tertiaryColor,
  }} onClick={(e) => props.onClick(e)} {...props} />
)

export const TextWithIcon = (props) => (
  <Div {...props}>
    {
      !_.isEmpty(props.link)
        ? props.type === 'a'
          ? <TextLink href={props.link} color={props.color}>
            {props.icon && <Icon icon={props.icon} margin='0 8px 0 0' />}
            {props.text}
          </TextLink>
          : <Konsys.ReactRouterLink to={props.link} color={props.color}>
            {props.icon && <Icon icon={props.icon} margin='0 8px 0 0' />}
            {props.text}
          </Konsys.ReactRouterLink>
        : <P bold={props.bold} color={props.color}>
          {props.icon && <Icon icon={props.icon} margin='0 8px 0 0' color={props.color} />}
          {props.text}
        </P>
    }
  </Div>
)

export const TextBetween = (props) => (
  <Div display='flex' justifyContent='space-between' alignItems='center' margin={props.margin}>
    <TextWithIcon {...props.title}>{props.title.text}</TextWithIcon>
    <TextWithIcon textAlign='right' {...props.content} />
  </Div>
)

export const TextareaWithLabel = (props) => (
  <ComponentWithLabelLarge {...props}>
    <Konsys.Input as='textarea' rows={5} margin='8px 0 0' {...props.textarea} />
  </ComponentWithLabelLarge>
)

export const SummaryPrice = (props) => (
  <Div margin={props.margin}>
    <TextBetween margin='0 0 8px' title={{ text: 'ยอดรวม' }} content={{ text: `${numeral(props.data.total).format('0,0.00')} บาท` }} />
    {
      props.data && props.isWithShipping
      && <Div>
        <Div display='flex' justifyContent='space-between' alignItems='center' margin='0 0 8px'>
          <TextWithIcon text='ค่าขนส่ง' />
          <TextWithIcon textAlign='right' text={`${numeral(props.data.shipping).format('0,0.00')} บาท`} />
        </Div>
        <TextBetween margin='0 0 8px' title={{ text: 'ยอดรวมค่าขนส่ง' }} content={{ text: `${numeral(parseFloat(props.data.total) + parseFloat(props.data.shipping)).format('0,0.00')} บาท` }} />
      </Div>
    }
    {
      props.data && props.data.discount
      && <Div display='flex' justifyContent='space-between' alignItems='center' margin='0 0 8px'>
        <TextWithIcon text='ส่วนลด' />
        <TextWithIcon textAlign='right' text={`-${numeral(props.data.discount.value).format('0,0.00')} บาท`} />
      </Div>
    }
    <Divider margin='8px 0' />
    <TextBetween title={{ text: 'ยอดทั้งหมด', bold: true }} content={{ text: `${numeral(parseFloat(props.data.total) + parseFloat(props.data.shipping) - parseFloat(props.data.discount.value)).format('0,0.00')} บาท`, bold: true }} />
  </Div>
)

export const ChatDayBreaker = ({
  date,
}) => (
  <Div padding='24px 0 16px 0' alignItems='center' flexDirection='row' display='flex' width='100%' justifyContent='center'>
    <Label color={theme.color.gray40} margin='0 16px'>{moment(date).lang('en').calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: '[Last] dddd',
      sameElse: 'DD/MM/YYYY',
    })}</Label>
  </Div>
)

export const StatusCheckbox = ({
  statusName, quantity, check, setCheck, disabled,
}) => (
  <Div bgColor={check ? theme.color.tertiaryColor : theme.color.gray10} margin='0 16px 16px 0' padding='16px' alignItems='center' flexDirection='row' display='flex' width='calc((100% - 48px) / 3)'
    style={{ cursor: disabled ? 'not-allowed' : 'pointer', borderRadius: '4px', maxWidth: '309px' }}
    onClick={() => !disabled && setCheck(!check)}
  >
    <P color={disabled ? theme.color.gray30 : check ? theme.color.offWhite : theme.color.gray90}>{statusName} {quantity} คน</P>
    <Icon icon='fal fa-check' color={disabled ? theme.color.gray30 : theme.color.offWhite} fontSize='16px' style={{ display: check ? 'block' : 'none', marginLeft: 'auto' }}/>
  </Div>
)

export const SendMessage = ({
  msg, setImage, setModalIsOpen, type, onOpenCustomerQuotation,
}) => (
  <Div margin='8px 0 0' flexDirection='row' display='flex' width='100%' justifyContent='flex-end'>
    <Div width='100%' display='flex' alignItems='flex-end' justifyContent='flex-end'>
      {
        msg?.sendedMessage
          ? msg?.sendedMessage?.text
            ? <Div padding='16px 24px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 0 12px', maxWidth: '60%' }}>
              <P style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{msg?.sendedMessage?.text}</P>
            </Div>
            : msg?.sendedMessage?.type === 'audio'
              ? <DisplayAudio isSendedMessage src={msg?.sendedMessage?.url} />
              : <img src={msg?.sendedMessage?.url} style={{
                maxWidth: '75%', maxHeight: '500px', alignSelf: 'flex-end', cursor: 'pointer',
              }}
              onClick={() => {
                setModalIsOpen(true)
                setImage([{ src: msg?.sendedMessage?.url }])
              }}
              />
          : type === 'MESSENGER' && msg?.sendedFlexMessage
            ? <Div display='flex' flexDirection='column' moreStyle={{ cursor: 'pointer' }} onClick={() => onOpenCustomerQuotation(msg?.sendedFlexMessage?.uri)}>
              <Div bgColor={msg?.sendedFlexMessage.isNewVersion && '#F0F2F580'} moreStyle={{
                boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '18px', overflow: 'hidden', background: theme?.color?.offWhite,
              }}>
                {
                  (_.isNull(msg?.sendedFlexMessage.isNewVersion) || !msg?.sendedFlexMessage.isNewVersion) && !_.isEmpty(msg?.sendedFlexMessage?.imageUrl) && <img src={msg?.sendedFlexMessage?.imageUrl} style={{ width: '100%', maxWidth: '230px' }} />
                }
                <Div padding='8px 14px 10px' moreStyle={{ borderBottom: '1px solid #EEEEEE' }}>
                  <P display='block' bold margin='0 0 4px'>{msg?.sendedFlexMessage?.refId}</P>
                  <SmallText display='block' color='#8C8C8D'>item: {msg?.sendedFlexMessage?.quantity} items</SmallText>
                  <SmallText display='block' color='#8C8C8D'>total: {numeral(msg?.sendedFlexMessage?.totalPrice).format('0,0.00')} Baht</SmallText>
                  {
                    msg?.sendedFlexMessage?.itemList?.map((v, i) => (
                      <SmallText key={i} display='block' color='#8C8C8D'>{i + 1}.{v.itemName}: ฿{numeral(v.totalPrice).format('0,0.00')}</SmallText>
                    ))
                  }
                  <SmallText display='block' color='#8C8C8D'>ค่าขนส่ง: ฿{numeral(msg?.sendedFlexMessage?.deliveryCost).format('0,0.00')}</SmallText>
                </Div>
                <SmallText style={msg?.sendedFlexMessage.isNewVersion && { backgroundColor: '#FFFFFF', borderRadius: '8px', padding: '8px 0' }} bold color='#26AAF9' margin='10.5px 14px 12px' display='block' textAlign='center'>View Details</SmallText>
              </Div>
            </Div>
            : msg.message
              ? <Div padding='16px 24px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 0 12px', maxWidth: '60%' }}>
                <P style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{msg?.message}</P>
              </Div>
              : msg.url
                ? <img src={msg.url} style={{
                  maxWidth: '75%', maxHeight: '500px', alignSelf: 'flex-end', cursor: 'pointer',
                }}
                onClick={() => {
                  setModalIsOpen(true)
                  setImage([{ src: msg.url }])
                }}
                />
                : !msg?.sendedFlexMessage.isNewVersion
                  ? <Div display='flex' flexDirection='column' moreStyle={{ cursor: 'pointer' }} onClick={() => onOpenCustomerQuotation(msg?.sendedFlexMessage?.uri)}>
                    <Div padding='16px 80px 24px 24px' display='flex' flexDirection='column' moreStyle={{ backgroundColor: theme.color.primaryColor, borderRadius: '12px 12px 0 0' }}>
                      <H4 margin='0 0 8px' color={theme.color.offWhite}>{msg?.sendedFlexMessage?.refId}</H4>
                      <P margin='0 0 4px' color={theme.color.offWhite}>สินค้า {msg?.sendedFlexMessage?.quantity} รายการ</P>
                      <P margin={msg.sendedFlexMessage?.paymentType === 'CASH' ? '0' : '2px 0'} color={theme.color.offWhite}>{`${msg.sendedFlexMessage?.paymentType === 'CASH' ? 'ยอดชำระ' : 'ยอดทั้งหมด'} ${numeral(msg?.sendedFlexMessage?.totalPrice).format('0,0.00')} บาท`}</P>
                      {
                        !(msg.sendedFlexMessage?.paymentType === 'CASH') && <SmallText color={theme.color.offWhite}>(ชำระด้วยเครดิต)</SmallText>
                      }
                    </Div>
                    <Div padding='16px 24px 24px' display='flex' flexDirection='column' moreStyle={{ border: `solid 1px ${theme.color.secondaryColor}`, borderRadius: '0 0 0 12px' }}>
                      {
                        msg?.sendedFlexMessage?.isActive
                          ? <Div display='flex' flexDirection='column'>
                            <Div flexDirection='row' display='flex' alignItems='center'>
                              <StatusDot active={true}/>
                              <SmallText bold margin='0 0 0 8px' color={theme.color.success} >{msg.sendedFlexMessage.paymentType === 'CASH' ? 'รอชำระเงิน' : 'รอยืนยันที่อยู่'}</SmallText>
                            </Div>
                            <StatusLine active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) > 1}/>
                            <Div flexDirection='row' display='flex' alignItems='center'>
                              <StatusDot active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) > 1}/>
                              <SmallText bold margin='0 0 0 8px' color={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) > 1 ? theme.color.success : theme.color.gray40}>กำลังดำเนินการ</SmallText>
                            </Div>
                            <StatusLine active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) === 5}/>
                            <Div flexDirection='row' display='flex' alignItems='center'>
                              <StatusDot active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) === 5}/>
                              <SmallText bold margin='0 0 0 8px' color={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) === 5 ? theme.color.success : theme.color.gray40}>จัดส่งแล้ว</SmallText>
                            </Div>
                          </Div>
                          : <P bold color={theme.color.error}>ยกเลิก</P>
                      }
                    </Div>
                  </Div>
                  : <Div padding='16px' borderRadius='12px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', minWidth: '250px', cursor: 'pointer' }} onClick={() => onOpenCustomerQuotation(msg?.sendedFlexMessage?.uri)}>
                    <H4 bold>{msg?.sendedFlexMessage?.refId}</H4>
                    <hr style={{ margin: '20px 0', border: 'none', borderTop: `1px solid ${theme.color.gray30}` }} />
                    <Div>
                      {
                        msg?.sendedFlexMessage?.itemList?.map((v) => (
                          <Div key={v.itemName} margin='0 0 2px' display='flex' justifyContent='space-between'>
                            <P margin='0 24px 0 0' style={{
                              maxWidth: '200px',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}>{v.itemName}</P>
                            <P>{numeral(v.totalPrice).format('0,0.00')}</P>
                          </Div>
                        ))
                      }
                      <Div margin='0 0 2px' display='flex' justifyContent='space-between'>
                        <P margin='0 24px 0 0'>ค่าขนส่ง</P>
                        <P>{numeral(msg?.sendedFlexMessage?.deliveryCost).format('0,0.00')}</P>
                      </Div>
                    </Div>
                    <hr style={{ margin: '8px 0', border: 'none', borderTop: `1px solid ${theme.color.gray30}` }} />
                    <Div margin='0 0 2px' display='flex' justifyContent='space-between'>
                      <P margin='0 24px 0 0' color={theme.color.gray90}>สินค้า</P>
                      <P>{msg?.sendedFlexMessage?.quantity} รายการ</P>
                    </Div>
                    <hr style={{ margin: '8px 0 20px', border: 'none', borderTop: `1px solid ${theme.color.gray30}` }} />
                    <Div margin='0 0 2px' display='flex' justifyContent='space-between'>
                      <P margin='0 24px 0 0' color={theme.color.gray90}>ยอดทั้งหมด</P>
                      <P>{numeral(msg?.sendedFlexMessage?.totalPrice).format('0,0.00')} บาท</P>
                    </Div>
                  </Div>
      }
      <Icon icon='far fa-check-circle' margin='0 0 0 6px' color={theme?.color?.gray30} />
    </Div>
  </Div>
)

const DisplayAudio = ({ src, isSendedMessage }) => {
  const containerAudio = useRef()
  const [wavesurfer, setWavesurfer] = useState()
  const [isPlay, setIsPlay] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(wavesurfer)) {
      wavesurfer.load(src)
      wavesurfer.on('play', () => setIsPlay(true))
      wavesurfer.on('pause', () => setIsPlay(false))
    }
  }, [wavesurfer])

  useEffect(() => {
    if (!_.isEmpty(containerAudio?.current) && _.isEmpty(containerAudio?.current?.children) && _.isEmpty(wavesurfer)) {
      setWavesurfer(WaveSurfer.create({
        container: containerAudio?.current,
        waveColor: '#CDD0D6',
        progressColor: theme?.color?.primaryColor,
        cursorColor: 'transparent',
        barWidth: 3,
        barRadius: 4,
        cursorWidth: 1,
        height: 32,
        barGap: 3,
        ignoreSilenceMode: true,
      }))
    }
  }, [containerAudio?.current])

  const playAudio = () => {
    if (wavesurfer) wavesurfer.play()
  }

  const pauseAudio = () => {
    if (wavesurfer) wavesurfer.pause()
  }

  return (
    <Div margin='0 0 0 8px' padding='16px' moreStyle={{
      boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '8px 8px 8px 0', backgroundColor: isSendedMessage ? theme?.color?.offWhite : theme.color.primaryColor,
    }}>
      <Div display='flex' alignItems='center' padding='8px' bgColor={theme?.color?.gray10} moreStyle={{ borderRadius: '4px' }}>
        <Icon icon={isPlay ? 'fas fa-square' : 'fas fa-play'} color={theme?.color?.primaryColor} margin='0 16px 0 0' style={{ cursor: 'pointer' }} onClick={isPlay ? pauseAudio : playAudio} />
        <WaveWrapper ref={containerAudio}></WaveWrapper>
      </Div>
    </Div>
  )
}

export const RecieveMessage = ({
  msg, data, setImage, setModalIsOpen, readUserName,
}) => {
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${(bytes / k ** i).toFixed(dm)} ${sizes[i]}`
  }

  const messageRender = (type) => {
    switch (type) {
      case 'TEXT':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{msg?.receivedMessage?.text || msg?.receivedFacebookText?.text || msg?.message}</P>
        </Div>
      case 'STICKER':
        return <img src={msg?.receivedSticker?.url || msg?.receivedFacebookMessageWithAttachment?.attachments[0]?.payload?.url || msg?.url } style={{ maxWidth: '75%', maxHeight: '500px', margin: '0 0 0 8px' }}/>
      case 'IMAGE':
        if (!_.isEmpty(msg?.receivedFacebookMessageWithAttachment)) {
          return msg?.receivedFacebookMessageWithAttachment?.attachments.map((v, i) => (
            <img key={i} src={msg?.receivedFacebookMessageWithAttachment?.attachments[i]?.payload?.url} style={{
              maxWidth: '75%', maxHeight: '500px', margin: i < msg?.receivedFacebookMessageWithAttachment?.attachments.length - 1 ? '0 0 8px 8px' : '0 0 0 8px', cursor: 'pointer',
            }}
            onClick={() => {
              setModalIsOpen(true)
              setImage([{ src: msg?.receivedFacebookMessageWithAttachment?.attachments[i]?.payload?.url }])
            }}
            />
          ))
        }
        return <img src={msg?.receivedImage?.url.includes('http') ? msg?.receivedImage?.url : `${getURL().FILE_SERVER}/${msg?.receivedImage?.url}`} style={{
          maxWidth: '75%', maxHeight: '500px', margin: '0 0 0 8px', cursor: 'pointer',
        }}
        onClick={() => {
          setModalIsOpen(true)
          setImage([{ src: msg?.receivedImage?.url.includes('http') ? msg?.receivedImage?.url : `${getURL().FILE_SERVER}/${msg?.receivedImage?.url}` }])
        }}
        />
      case 'FILE':
        return <a href={!_.isEmpty(msg?.receivedFile) ? `${getURL().FILE_SERVER}/${msg?.receivedFile?.url}` : msg?.receivedFacebookMessageWithAttachment?.attachments[0]?.payload?.url} target='_blank' rel='noopener noreferrer' download>
          <Div margin='0 0 0 8px' display='flex' flexDirection='row' padding='16px 24px' moreStyle={{
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
          }}>
            <Icon icon='fal fa-file-alt' color={theme.color.offWhite} fontSize='16px' />
            {
              !_.isEmpty(msg?.receivedFile)
                ? <Div display='flex' flexDirection='column' margin='0 0 0 8px'>
                  <SmallText color={theme.color.offWhite}>{msg?.receivedFile?.fileName}</SmallText>
                  <Label color={theme.color.offWhite} margin='6px 0 0 0'>Size: {formatBytes(msg?.receivedFile?.fileSize)}</Label>
                </Div>
                : <Div display='flex' flexDirection='column' margin='0 0 0 8px'>
                  <SmallText color={theme.color.offWhite}>{msg?.receivedFacebookMessageWithAttachment?.attachments[0]?.payload?.filename}</SmallText>
                </Div>
            }
          </Div>
        </a>
      case 'LOCATION':
        return <a href={`https://www.google.com/maps/search/?api=1&query=${msg?.receivedLocation.latitude},${msg?.receivedLocation.longitude}`} target='_blank' rel='noopener noreferrer'>
          <Div margin='0 0 0 8px' display='flex' flexDirection='row' padding='16px 24px' moreStyle={{
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
          }}>
            <Icon icon='fas fa-map-marker-alt' color={theme.color.offWhite} fontSize='16px' />
            <Div display='flex' flexDirection='column' margin='0 0 0 8px'>
              <SmallText color={theme.color.offWhite}>{msg?.receivedLocation?.address}</SmallText>
            </Div>
          </Div>
        </a>
      case 'VIDEO':
        return <video style={{ maxWidth: '500px', maxHeight: '500px', margin: '0 0 0 8px' }} controls>
          <source src={!_.isEmpty(msg?.receivedVideo) ? `${getURL().FILE_SERVER}/${msg?.receivedVideo?.url}` : msg?.receivedFacebookMessageWithAttachment?.attachments[0]?.payload?.url} />
        </video>
      case 'AUDIO':
        return <DisplayAudio key={msg?.id} src={msg?.receivedFacebookMessageWithAttachment?.attachments?.[0]?.payload?.url || `${getURL().FILE_SERVER}/${msg?.receivedAudio?.url}`} />
      default:
        return ''
    }
  }
  return (
    <Div margin='4px 0'>
      <Div display='flex' alignItems='flex-end'>
        <AvatarSmall img={data?.chatContact?.profileUrl || data?.from?.profileUrl} />
        <Div moreStyle={{ maxWidth: '60%' }}>
          {
            messageRender(msg?.messageResponseType)
          }
        </Div>
      </Div>
      <Label display='block' color={theme.color.gray40} margin='4px 0 0 40px'>{moment(msg?.createdAt).format('HH:mm')}{readUserName && `, Read by ${readUserName}`}</Label>
    </Div>
  )
}

export const ChatCard = ({
  margin, data, onClick, type, isUnread, isCanSelect, setSelectedUserReminder, selectedUserReminder,
}) => {
  const onClickCard = () => {
    if (!isCanSelect) onClick(data.messageId)
    else {
      setSelectedUserReminder(data?.id)
    }
  }
  return (
    <Div display='flex' bgColor={(isUnread || data?.noti > 0) ? 'rgba(52, 198, 163, 0.06)' : 'white'} margin={margin} cursor='pointer' onClick={onClickCard}>
      {
        isCanSelect
        && <Div display='flex' alignItems='center'>
          <CheckboxAntd style={{ margin: '0 0 0 16px' }} checked={includes(selectedUserReminder, data?.id)} value={data.id} />
        </Div>
      }
      <Div margin='0 16px 0' padding='16px 0 16px' display='flex' position='relative' moreStyle={{
        flexGrow: 1, borderBottom: `1px solid ${theme?.color?.gray10}`, maxWidth: '100%', overflow: 'hidden',
      }}>
        <AvatarContainerWithChannel>
          <Avatar img={data.img} type={type} />
          <img src={type === 'FACEBOOK' ? Facebook : type === 'MESSENGER' ? Messenger : Line} />
        </AvatarContainerWithChannel>
        <Div display='flex' flexDirection='column' justifyContent='space-between' moreStyle={{ flexGrow: 1, width: '50%' }} margin='0 0 0 8px'>
          <Div display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Div display='flex' alignItems='center' margin='0 8px 0 0'>
              <Div display='flex' alignItems='flex-start'>
                <P bold style={{
                  flexGrow: 1,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  marginRight: 8,
                }}>{data.name}</P>
                <Div display='flex' flexDirection='column'>
                  {
                    data.ownerName
                    && <SmallText style={{
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>(แชตของ: {data.ownerName})</SmallText>
                  }
                  {
                    data.upsellName
                    && <SmallText style={{
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>(Upsell: {data.upsellName})</SmallText>
                  }
                </Div>
              </Div>
              {
                data?.noti > 0
                && <Div margin='0 0 0 6px' display='flex' alignItems='center' padding='4px 8px' bgColor={theme.color.error} borderRadius='20px'>
                  <Label bold color='white' style={{ lineHeight: 0.8 }}>{numeral(data.noti).format('0,0')}</Label>
                </Div>
              }
            </Div>
            <Label style={{ lineHeight: '21px' }} color={theme.color.gray50}>{data.time}</Label>
          </Div>
          <Label bold style={{
            flexGrow: 1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginRight: 8,
          }}>{data?.code ? `[${data?.code}]` : ''} {data.providerFullname}</Label>
          <Div width='100%' display='flex' justifyContent='space-between' alignItems='flex-end' margin='6px 0 0'>
            <SmallText margin={data?.noti > 0 && '0 8px 0 0'} color={theme.color.gray50} style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}>{data.message}</SmallText>
            {
              data?.reminderDate
              && <Div display='flex' alignItems='center'>
                <Icon icon='far fa-bell' margin='0 6px 0 0' color={theme?.color?.error} />
                <Label bold color={theme?.color?.error}>{dayjs(data?.reminderDate).format('DD/MM/YY')}</Label>
              </Div>
            }
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export const AddressBox = ({
  onBlurZipCode, defaultValue, onChange, value, onSelect, onSearch, title, pre, notRequire, disabled,
}) => {
  const [searchAddress, setSearchAddress] = useState([])
  return (
    <Konsys.Div width='100%' display='flex' flexDirection='column' padding='8px' margin='8px 0' style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: theme.color.veryLightBlue }}>
      <Konsys.H4 bold>{title}</Konsys.H4>
      { pre }
      <InputWithLabel
        input={{
          disabled,
          value: value.postcode,
          onBlur: () => {
            if (_.size(searchAddress) > 0) {
              onBlurZipCode(searchAddress)
            }
          },
          onKeyUp: (e) => {
            if (e.keyCode === 13 && _.size(searchAddress) > 0) {
              onBlurZipCode(searchAddress)
            }
          },
          width: '100%',
          defaultValue: defaultValue?.postcode,
          state: !notRequire && _.isEmpty(value?.postcode) && 'error',
        }}
        onChange={(e) => {
          setSearchAddress(searchAddressByZipcode(e.target.value))
          onChange.zipcode(e.target.value)
        }}
        title={{ text: <Konsys.SmallText bold>รหัสไปรษณีย์ {!notRequire && <Konsys.Label color={theme.color.error}>*</Konsys.Label>}</Konsys.SmallText> }}
      />
      <InputWithLabel
        input={{
          disabled,
          value: value.addressNo,
          width: '100%',
          defaultValue: defaultValue?.addressNo,
          state: !notRequire && _.isEmpty(value?.addressNo || defaultValue?.addressNo) && !_.isNaN(value?.addressNo || defaultValue?.addressNo) && 'error',
        }}
        onChange={(e) => onChange.addressNo(e.target.value)}
        title={{ text: <Konsys.SmallText bold>เลขที่ {!notRequire && <Konsys.Label color={theme.color.error}>*</Konsys.Label>}</Konsys.SmallText> }}
      />
      <ComponentWithLabelLarge title={{ text: <Konsys.SmallText bold>แขวง/ตำบล {!notRequire && <Konsys.Label color={theme.color.error}>*</Konsys.Label>}</Konsys.SmallText> }}>
        <AutoComplete
          disabled={disabled}
          value={value.subDistrict}
          dataSource={_.uniq(searchAddress.map((v) => v.district))}
          style={{ width: '100%' }}
          onSelect={(e) => {
            onSelect.subDistrict({ subDistrict: e, district: _.find(searchAddress, (o) => o.district === e)?.amphoe, province: _.find(searchAddress, (o) => o.district === e)?.province })
          }}
          defaultValue={defaultValue?.subDistrict}
          onSearch={(e) => {
            onSearch.subDistrict({ subDistrict: e, district: _.find(searchAddress, (o) => o.district === e)?.amphoe, province: _.find(searchAddress, (o) => o.district === e)?.province })
          }}
          placeholder="ใส่"
          className={!notRequire && ((_.isEmpty(value.subDistrict) && !_.isNumber(value.subDistrict)) || _.isNaN(value.subDistrict)) && 'state-error'}
        />
      </ComponentWithLabelLarge>
      <ComponentWithLabelLarge title={{ text: <Konsys.SmallText bold>เขต/อำเภอ {!notRequire && <Konsys.Label color={theme.color.error}>*</Konsys.Label>}</Konsys.SmallText> }}>
        <AutoComplete
          disabled={disabled}
          value={value.district}
          dataSource={_.uniq(searchAddress.map((v) => v.amphoe))}
          style={{ width: '100%' }}
          onSelect={(e) => {
            onSelect.district(e)
          }}
          defaultValue={defaultValue?.district}
          onSearch={(e) => {
            onSearch.district(e)
          }}
          placeholder="ใส่"
          className={!notRequire && ((_.isEmpty(value.district) && !_.isNumber(value.district)) || _.isNaN(value.district)) && 'state-error'}
        />
      </ComponentWithLabelLarge>
      <ComponentWithLabelLarge title={{ text: <Konsys.SmallText bold>จังหวัด {!notRequire && <Konsys.Label color={theme.color.error}>*</Konsys.Label>}</Konsys.SmallText> }}>
        <AutoComplete
          disabled={disabled}
          value={value.province}
          dataSource={_.uniq(searchAddress.map((v) => v.province))}
          style={{ width: '100%' }}
          onSelect={(e) => {
            onSelect.province(e)
          }}
          defaultValue={defaultValue?.province}
          onSearch={(e) => {
            onSearch.province(e)
          }}
          placeholder="ใส่"
          className={!notRequire && ((_.isEmpty(value.province) && !_.isNumber(value.province)) || _.isNaN(value.province)) && 'state-error'}
        />
      </ComponentWithLabelLarge>
    </Konsys.Div>
  )
}

export const Tab = (props) => {
  const [currentTab, setCurrentTab] = useState(!props.noDefaultTab && 0)

  useEffect(() => {
    if (_.isNumber(props.current)) {
      setCurrentTab(props.current)
    }
  }, [props])

  return (
    <TabWrapper white={props.white}>
      <TabHeaderWrapper>
        <Konsys.Div display='flex'>
          {
            !_.isUndefined(props.title) && !_.isEmpty(props.title) && props.title.map((v, i) => (
              <TabTitle
                link={v.link}
                active={currentTab === i}
                key={i}
                text={v.text}
                icon={v.icon}
                onClick={() => {
                  setCurrentTab(i)
                  if (props.onChangeTab) props.onChangeTab(i)
                }}
              />
            ))
          }
          {
            props.tabLoading && <Loading size='extraSmall' margin='0 0 0 16px'/>
          }
        </Konsys.Div>
        <Konsys.Div>
          <Konsys.Div margin='0 16px 0 0' display='flex' height='100%' alignItems='center' moreStyle={{ flexWrap: 'wrap' }} justifyContent='flex-end'>
            {
              !_.isUndefined(props.buttonList) && !_.isEmpty(props.buttonList) && props.buttonList.map((v) => (
                v
              ))
            }
          </Konsys.Div>
        </Konsys.Div>
      </TabHeaderWrapper>
      <TabContentWrapper white={props.white} contentHeight={props.contentHeight} filterHeight={props.filterHeight} fullHeight={props.fullHeight}>
        {
          props.loading
            ? <Konsys.Div>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Konsys.Div>
            : _.isArray(props.children) ? props.children[currentTab] : props.children
        }
      </TabContentWrapper>
    </TabWrapper>
  )
}

export const FullPageContainer = (props) => (
  <Tab fullHeight title={props.title} buttonList={props.buttonList}>
    <Tab white loading={props.loading} title={props.detail.title} buttonList={props.detail.buttonList} current={props.detail.current}>
      {
        props.children
      }
    </Tab>
  </Tab>
)

const TabTitle = (props) => (
  <TabTitleWrapper active={props.active}>
    <TextWithIconSmall
      link={props.link}
      color={props.active ? theme.color.offWhite : theme.color.gray100}
      text={props.text}
      icon={props.icon}
      onClick={() => props.onClick()}
    />
  </TabTitleWrapper>
)

export const TextWithIconSmall = (props) => (
  <Konsys.Div {...props}>
    {
      !_.isEmpty(props.link)
        ? props.type === 'a'
          ? <Konsys.SmallTextLink href={props.link} color={props.color}>
            {props.icon && <Konsys.Icon fontSize={theme.fonts.size.label} icon={props.icon} margin='0 8px 0 0' />}
            {props.text}
          </Konsys.SmallTextLink>
          : <Konsys.SmallReactRouterLink to={props.link} color={props.color}>
            {props.icon && <Konsys.Icon fontSize={theme.fonts.size.label} icon={props.icon} margin='0 8px 0 0' />}
            {props.text}
          </Konsys.SmallReactRouterLink>
        : <Konsys.SmallText bold={props.bold} color={props.color}>
          {props.icon && <Konsys.Icon fontSize={theme.fonts.size.label} icon={props.icon} margin='0 8px 0 0' color={props.color} />}
          {props.text}
        </Konsys.SmallText>
    }
  </Konsys.Div>
)

export const QuotationListCard = ({
  margin, data, active, animateDown, onClick,
}) => (
  <Div padding='16px' bgColor='white' display='flex' position='relative' margin={margin} cursor='pointer' onClick={() => onClick()}>
    <Div display='flex' justifyContent='space-between' width='100%'>
      <Div>
        <SmallText bold>{data.refId}</SmallText>
        <Label margin='1px 0 0' display='block' >{moment(data.createdAt).format('DD/MM/YYYY')}</Label>
        <SmallText margin='16px 0 0' display='block' >สถานะ</SmallText>
      </Div>
      <Div>
        <SmallText textAlign='right' display='block' bold>{numeral(data.totalPrice).format('0,0.00')}</SmallText>
        <Label margin='1px 0 0' display='block' textAlign='right'>บาท</Label>
        <SmallText textAlign='right' margin='16px 0 0' display='block' color={data?.isActive && !data?.isExpired ? colorSelector(data?.status) : theme.color.error} >{data?.isExpired ? 'หมดอายุ' : !data?.isActive ? 'ยกเลิก' : _.find(QUOTATION_STATUS, { value: data.status }).text}</SmallText>
      </Div>
    </Div>
    <ActiveLine active={active} animateDown={animateDown} />
  </Div>
)

export const UserListCard = ({
  margin, data, active, animateDown, onClick,
}) => (
  <Div padding='16px' bgColor='white' display='flex' position='relative' margin={margin} cursor='pointer' onClick={() => onClick()}>
    <Div display='flex' flexDirection='column' width='100%'>
      <Div display='flex' justifyContent='space-between'>
        <SmallText bold >{data.firstName} {data.lastName}</SmallText>
        <SmallText bold textAlign='right'>{data?.roles[0]?.description}</SmallText>
      </Div>
      <Div margin='16px 0 0' display='flex' justifyContent='space-between'>
        <Label>อีเมล</Label>
        <SmallText bold >{data.email}</SmallText>
      </Div>
    </Div>
    <ActiveLine active={active} animateDown={animateDown} />
  </Div>
)

export const InventoryCard = ({ margin, data }) => (
  <Div padding='16px' bgColor='white' margin={margin}>
    <Div>
      <SmallText bold>{data.name}</SmallText>
      <Label margin='1px 0 0' display='block'>{data.sku}</Label>
      <Label display='block'>{data.brand}</Label>
    </Div>
    <Div display='flex' justifyContent='space-between' margin='16px 0 0'>
      <SmallText>จำนวนพร้อมขาย</SmallText>
      <SmallText bold>{numeral(data.stock).format('0,0.00')}</SmallText>
    </Div>
    <Div margin='16px 0 0'>
      {
        data?.price?.map((v, i) => (
          <Div key={i} display='flex' justifyContent='space-between' margin={i !== v.length - 1 && '0 0 2px'}>
            <SmallText>{v?.pricingType?.name || '-'}</SmallText>
          </Div>
        ))
      }
    </Div>
  </Div>
)

export const LogoImg = styled.img`
  width: 20px;
  margin: 0 0 0 8px;
`

export const QuotationCard = ({
  margin, data, chatId, onSuccess, setModal, setQuotationId,
}) => {
  const [doSendQuotation] = useMutation(SEND_QUOTATION)
  const [disabledButton, setDisabledButton] = useState(false)
  return (
    <Div bgColor='white' padding='16px' margin={margin}>
      <Div display='flex' justifyContent='space-between'>
        <Div>
          <Div display='flex' alignItems='center'>
            <SmallText bold>{data.refId}</SmallText>
            {
              !_.isNull(data.type)
              && <LogoImg src={data.type === 'LINE' ? Line : Messenger} />
            }
          </Div>
          <Label margin='1px 0 0' display='block' >{data.createdDate}</Label>
        </Div>
        <Div>
          <SmallText bold>{numeral(data.total).format('0,0.00')}</SmallText>
          <Label margin='1px 0 0' display='block' textAlign='right'>บาท</Label>
        </Div>
      </Div>
      <Div margin='16px 0 0' display='flex' justifyContent='flex-end'>
        <ExpandButton buttonStyle='round' margin='0 8px 0 0' text='รายละเอียด' icon='far fa-eye'
          onClick={() => {
            setModal(true)
            setQuotationId(data.id)
          }}
        />
        <ExpandButton disabled={disabledButton} loading={disabledButton} buttonStyle='round' text='ส่งลิงค์เข้าแชตนี้' icon='fas fa-repeat-1' onClick={() => {
          setDisabledButton(true)
          if (!_.isUndefined(chatId) && !_.isNull(chatId)) {
            doSendQuotation({
              variables: {
                channelId: chatId,
                quotationId: data?.id,
              },
            }).then((resp) => {
              setDisabledButton(false)
              if (resp?.data?.replyFlexMessage?.success) {
                message.success(resp?.data?.replyFlexMessage?.message)
                if (!_.isUndefined(onSuccess)) {
                  onSuccess()
                }
              } else {
                message.error(resp?.data?.replyFlexMessage?.message)
              }
            })
          }
        }} />
      </Div>
    </Div>
  )
}

export const FullPageLoading = ({ height, fontSize, padding }) => (
  <Div display='flex' flexDirection='column' alignItems='center' justifyContent='center' height={height || '100%'} bgColor={theme.color.gray10} padding={padding}>
    <Icon icon='fal fa-spinner fa-pulse' fontSize={fontSize || theme.fonts.size.heading} color={theme.color.gray40} />
  </Div>
)

export const Modal = () => (
  <Div border='2px solid blue' moreStyle={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'pink',
  }}>
    <p>Test Modal</p>
  </Div>
)

export const Uploader = (props) => {
  const filter = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng && !file.url) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M && !file.url) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M && _.isUndefined(file.url) && !_.isUndefined(file.originFileObj)
  }

  return (
    <Upload
      showUploadList={{
        showRemoveIcon: !props.disabled,
      }}
      disabled={props.disabled}
      customRequest={() => null}
      processData={false}
      fileList={props.data}
      accept={props.accept}
      onChange={(e) => {
        const verifiedFile = e.fileList.map((v) => ({ ...v, isPassed: props.filter ? props.filter(v) : filter(v) }))
        if (props.fileLength && [...props.data, ..._.filter(verifiedFile, (o) => o.isPassed)].length > props.fileLength) return message.error(`กรุณาอัพโหลดรูปไม่เกิน ${props.fileLength} รูป`)
        if (props.isMultiple) props.onChange([...props.data, ..._.filter(verifiedFile, (o) => o.isPassed).map((v) => v.originFileObj)])
        else props.onChange([..._.filter(verifiedFile, (o) => o.isPassed).map((v) => v.originFileObj)])
        return null
      }}
      beforeUpload={() => false}
      multiple={props.isMultiple}
      onRemove={(e) => props.onChange(_.remove(_.cloneDeep(props.data), (obj) => obj.uid !== e.uid))}
      defaultFileList={_.size(props.data) > 0 && _.isArray(props.data) && !_.isEmpty(props.data) && props.data?.map((v) => ({
        name: v.filename,
        url: v.url,
        uid: v.url,
      }))}
      style={props.style}
    >
      <Button small inverse={props.inverse} text={props.buttonText || 'อัพโหลดไฟล์'} icon={props.icon || 'far fa-file-upload'} disabled={props.disabled || (props.fileLength && props.data?.length >= props.fileLength)} style={props.buttonStyle}/>
    </Upload>
  )
}

export const IconButton = styled(Button)`
  border-radius: 4px;
  ${(props) => props?.iconColor && css`
    > i {
      color: ${props?.iconColor} !important;
    }
  `}
`

const RowSelectWrapper = styled.div`
  > input {
    display: none;
    &:checked {
      & + label {
        i {
          display: inline-block;
        }
      }
    }
  }
  > label {
    cursor: pointer;
    width: 100%;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid ${theme?.color?.gray10};
    i {
      display: none;
    }
  }
`
export const RowSelect = ({
  id, name, data, checked, onChange,
}) => (
  <RowSelectWrapper>
    <input checked={checked} type='radio' id={id} name={name} value={data?.value} onChange={onChange} />
    <label htmlFor={id}>
      <Div display='flex' alignItems='center' justifyContent='space-between'>
        <SmallText>{data?.text}</SmallText>
        <Icon color={theme?.color?.primaryColor} icon='fal fa-check' />
      </Div>
    </label>
  </RowSelectWrapper>
)

export const DrawerBottom = ({
  onClose, visible, children, props,
}) => (
  <Drawer
    className='drawer-bottom'
    placement='bottom'
    closable={false}
    onClose={onClose}
    visible={visible}
    height='auto'
    bodyStyle={{ padding: 0 }}
    drawerStyle={{
      padding: '12px 16px', maxWidth: 'calc(100% + 24px)', maxHeight: '90vh', overflow: 'hidden', ...props?.drawerStyle,
    }}
    {...omit(props, ['onClose', 'visible', 'children', 'drawerStyle'])}
  >
    { children }
  </Drawer>
)

const SearchOwner = ({ allVars, setAllVars }) => {
  const [selectedOwner, setSelectedOwner] = useState(allVars?.selectedOwner || [])
  const [getUserList, { data: usersData }] = useLazyQuery(USER_LIST)

  const callGetUserList = (searchValue) => {
    getUserList({
      variables: {
        limit: 10,
        offset: 0,
        name: searchValue,
      },
    })
  }

  useEffect(() => {
    callGetUserList()
  }, [])

  const onSearch = (e) => {
    const getValue = e.target.value
    delayInput(() => callGetUserList(getValue))
  }

  const onChangeCheckbox = (e) => {
    let newList = []
    if (_.isNil(_.find(selectedOwner, (obj) => obj?.id === e.target.value))) {
      const findData = _.find(usersData?.userList?.data?.list, (obj) => obj?.id === e.target.value)
      newList = _.uniqBy([{ id: e.target.value, name: `${findData?.firstName} ${findData?.lastName}` }, ...selectedOwner], 'id')
    } else {
      newList = _.compact(selectedOwner?.map((obj) => {
        if (obj?.id === e.target.value) return null
        return obj
      }))
    }
    setSelectedOwner(newList)
    setAllVars({ ...allVars, selectedOwner: newList })
  }
  const queryOwnerList = _.filter(usersData?.userList?.data?.list, (obj) => !includes(selectedOwner?.map((ownerDetail) => ownerDetail?.id), obj?.id))?.map((userDetail) => (
    { label: <SmallText bold>{userDetail?.firstName} {userDetail?.lastName}</SmallText>, value: userDetail?.id }
  ))
  return (
    <Div margin='16px 0 16px 16px'>
      <Input padding='6px 8px 5px 30px' margin='0 0 12px' width='100%' placeholder='ค้นหา' prefix={<Icon icon='fal fa-search' className='prefix' />} onChange={onSearch} />
      {
        !_.isEmpty(selectedOwner)
        && <Div padding='16px 0' margin='0 0 16px' moreStyle={{ borderBottom: `1px solid ${theme?.color?.gray10}` }}>
          <SmallText margin='0 0 8px' color={theme?.color?.gray30}>User ที่เลือก</SmallText>
          {
            _.filter(selectedOwner, (obj) => !obj?.isMyChat)?.map((ownerDetail, i) => (
              <CheckboxAntd
                key={ownerDetail?.id}
                style={{ margin: i === 0 ? '0' : '16px 0 0', display: 'block' }}
                onChange={onChangeCheckbox}
                value={ownerDetail?.id}
                checked={!_.isEmpty(_.find(selectedOwner, (obj) => obj?.id === ownerDetail?.id))}
              ><SmallText bold>{ownerDetail?.name}</SmallText></CheckboxAntd>
            ))
          }
        </Div>
      }
      {
        queryOwnerList?.map((checkboxDetail, i) => (
          <CheckboxAntd
            key={checkboxDetail?.value}
            style={{ margin: i === 0 ? '0' : '16px 0 0', display: 'block' }}
            onChange={onChangeCheckbox}
            value={checkboxDetail?.value}
            checked={!_.isEmpty(_.find(selectedOwner, (obj) => obj?.id === checkboxDetail?.value))}
          >{checkboxDetail?.label}</CheckboxAntd>
        ))
      }
    </Div>
  )
}

export const ExpandWithChoice = ({
  varKey, title, checkboxList, onCheck, isHaveCheckAll, vars, margin, children, allVars, setAllVars, isRadio, onSelectRadio,
}) => {
  const [visible, setVisible] = useState(true)
  const switchCollapse = () => setVisible(!visible)
  const callOnCheck = (e) => onCheck(title, varKey, e)
  const callOnSelect = (e) => onSelectRadio(varKey, e)

  return (
    <Div margin={margin}>
      <Div display='flex' alignItems='center' justifyContent='space-between' padding='0 0 12px' margin='0 0 16px' moreStyle={{ borderBottom: `1px solid ${theme?.color?.gray10}` }}>
        <SmallText weightgray100>{title}</SmallText>
        <RotateChevronContainer open={visible} onClick={switchCollapse}>
          <Icon icon='fal fa-chevron-up' />
        </RotateChevronContainer>
      </Div>
      <Div display='flex' flexDirection='column' moreStyle={{ transition: 'max-height 0.3s', overflow: 'hidden', maxHeight: visible ? 1000000 : 0 }}>
        {
          !children
            ? isRadio
              ? <RadioAntd.Group onChange={callOnSelect}>
                {
                  isHaveCheckAll && <RadioAntd value='checkAll' style={{ display: 'block', margin: '0 0 16px' }} checked={includes(vars, 'checkAll')}><SmallText bold>ทั้งหมด</SmallText></RadioAntd>
                }
                {
                  checkboxList?.map((radioDetail, i) => (
                    <RadioAntd key={radioDetail?.value} style={{ display: 'block', margin: i === 0 ? '0' : '16px 0 0' }} value={radioDetail?.value} checked={includes(vars, radioDetail?.value)}>{radioDetail?.label}</RadioAntd>
                  ))
                }
              </RadioAntd.Group>
              : <>
                {
                  isHaveCheckAll
                  && <CheckboxAntd
                    style={{ margin: '0 0 16px' }}
                    onChange={callOnCheck}
                    value='checkAll'
                    checked={vars?.length === checkboxList?.length}
                  ><SmallText bold>ทั้งหมด</SmallText></CheckboxAntd>
                }
                {
                  checkboxList?.map((checkboxDetail, i) => (
                    <CheckboxAntd key={checkboxDetail?.value} style={{ margin: i === 0 ? '0' : '16px 0 0' }} onChange={callOnCheck} value={checkboxDetail?.value} checked={includes(vars, checkboxDetail?.value)}>{checkboxDetail?.label}</CheckboxAntd>
                  ))
                }
              </>
            : children
        }
      </Div>
      {
        varKey === 'chatOwner' && (vars === 'OTHER_OWNER' || vars === 'UPSELL')
        && <SearchOwner allVars={allVars} setAllVars={setAllVars} />
      }
    </Div>
  )
}
