import { useMutation, useQuery } from '@apollo/client'
import {
  Button, Checkbox as CheckboxWithText,
  Div, H3, H4,
  Icon,
  Input,
  InputWithLabelCustom,
  Label,
  P,
  SmallText,
  Uploader,
} from '@konsys-ui-custom'
import {
  Popconfirm,
  Select as SelectAnt,
  TimePicker,
  message,
  notification,
} from 'antd'
import { DatePicker } from 'antd4'
import _, { includes } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import InputAddress from 'react-thailand-address-autocomplete'
import airCon from '../../assets/air-conditioner.png'

import {
  BankLogo,
} from '../../components/common/styled'
import { PAYMENT_TYPE, RECEIVE_TYPE } from '../../constants/db-const'
import { CONFIRM_PAYMENT, QUOTATION_UPDATE, VERIFY_SLIP } from '../../constants/graph/mutation'
import { BANK_ACCOUNT, BANK_LIST, GET_QUOTATION_USER } from '../../constants/graph/query'
import publicClient from '../../public-apollo-client'
import { theme } from '../../styles/variables'
import { mapImageBank } from '../../utils/util-services'
import {
  CheckboxLabel,
  CollapseButton,
  Container,
  GradientButton,
  LabelContainer,
  LabelUploadButton,
  Line,
  LinkDownload,
  LogoBg,
  PaymentContainer, QrImg,
  QuotationCollapseDetailContainer,
  QuotationContainer, QuotationDetailConainer,
  StepItem,
  StepList,
} from './styled'

const addressField = [
  {
    title: 'ชื่อ - นามสกุล',
    require: true,
    margin: '24px 0 16px',
    keyName: 'contactName',
  }, {
    title: 'เบอร์มือถือ',
    require: true,
    margin: '0 0 24px',
    keyName: 'contactPhoneNumber',
  },
  {
    title: 'เบอร์บ้าน',
    margin: '0 0 24px',
    keyName: 'contactOfficeTel',
  },
  {
    title: 'เลขที่',
    require: true,
    keyName: 'addressNo',
  }, {
    title: 'รหัสไปรษณีย์',
    require: true,
    width: '100px',
    keyName: 'zipcode',
    isInputAddress: true,
  }, {
    title: 'แขวง/ตำบล',
    require: true,
    keyName: 'subdistrict',
    isInputAddress: true,
  }, {
    title: 'เขต/อำเภอ',
    require: true,
    keyName: 'district',
    isInputAddress: true,
  }, {
    title: 'จังหวัด',
    require: true,
    margin: 0,
    keyName: 'province',
    isInputAddress: true,
  },
]

const TextWithLabel = ({ title, content, margin }) => (
  <Div margin={margin}>
    <Label>{title}</Label>
    <SmallText bold display='block'>{content}</SmallText>
  </Div>
)

const TableList = ({ data, deliveryCost, deliveryType }) => (
  <Div margin='16px 0 0'>
    <Div display='flex' padding='0 0 4px' margin='0 0 8px' moreStyle={{ borderBottom: `1px solid ${theme.color.paleGrey}` }}>
      <Label style={{ flexGrow: 1 }} margin='0 16px 0 0'>รายการสินค้า</Label>
      <Label margin='0 16px 0 0' style={{ width: '45px', flexGrow: 0, flexShrink: 0 }}>จำนวน</Label>
      <Label textAlign='right' style={{ width: '80px', flexGrow: 0, flexShrink: 0 }}>ราคาสุทธิ</Label>
    </Div>
    {
      data.map((v, i) => (
        <Div key={i} display='flex' margin='0 0 6px'>
          <SmallText bold margin='0 4px 0 0' width='20px'>{i + 1}.</SmallText>
          <SmallText bold style={{ flexGrow: 1 }} margin='0 16px 0 0'>{v.pack?.variantName || v.set?.name || v.set?.ProductSetItems?.Pack?.variantName || '-'}</SmallText>
          <SmallText bold margin='0 16px 0 0' style={{ width: '45px', flexGrow: 0, flexShrink: 0 }}>{v.quantity}</SmallText>
          <SmallText bold textAlign='right' style={{ width: '80px', flexGrow: 0, flexShrink: 0 }}>{numeral(v.price * v.quantity).format('0,0.00')}</SmallText>
        </Div>
      ))
    }
    {
      (deliveryType !== 'MANUAL')
      && <Div display='flex' margin='0 0 6px'>
        <SmallText bold margin='0 4px 0 0' width='20px'>{data.length + 1}.</SmallText>
        <SmallText bold style={{ flexGrow: 1 }} margin='0 16px 0 0'>ค่าขนส่ง</SmallText>
        <SmallText bold margin='0 16px 0 0' style={{ width: '45px', flexGrow: 0, flexShrink: 0 }}></SmallText>
        <SmallText bold textAlign='right' style={{ width: '80px', flexGrow: 0, flexShrink: 0 }}>{numeral(deliveryCost).format('0,0.00')}</SmallText>
      </Div>
    }
    {
      _.sum(data.map((v) => v.discount * v.quantity)) > 0
      && <Div display='flex' margin='0 0 6px'>
        <SmallText bold margin='0 4px 0 0' width='20px'>{deliveryType !== 'MANUAL' ? data.length + 2 : data.length + 1}.</SmallText>
        <SmallText bold style={{ flexGrow: 1 }} margin='0 16px 0 0'>ส่วนลด</SmallText>
        <SmallText bold margin='0 16px 0 0' style={{ width: '45px', flexGrow: 0, flexShrink: 0 }}></SmallText>
        <SmallText bold textAlign='right' style={{ width: '80px', flexGrow: 0, flexShrink: 0 }}>-{_.sum(data.map((v) => v.discount * v.quantity))}</SmallText>
      </Div>
    }
  </Div>
)

const QuotationDetail = ({
  data, open, totalPrice, mutationVariables,
}) => {
  const [openCollapse, setOpenCollapse] = useState(open || false)
  useEffect(() => {
    setOpenCollapse(open)
  }, [open])

  return (
    <QuotationDetailConainer collapse={openCollapse}>
      <Div display='flex' justifyContent='space-between' alignItems='center'>
        <span>
          <H4 bold>{data.refId}</H4>
          {
            includes(['WAITING_DELIVERED', 'DELIVERED'], data.status)
              && (
                data.isCOD
                  ? <P bold color={theme.color.success} margin='0 0 0 16px'>ยืนยันออเดอร์เสร็จสิ้น</P>
                  : <P bold color={theme.color.success} margin='0 0 0 16px'>ชำระเงินเสร็จสิ้น</P>
              )
          }
        </span>
        {
          data.isExpired
          && <P bold color={theme.color.error}>หมดอายุ</P>
        }
      </Div>
      <Div display='flex' alignItems='flex-end' justifyContent='space-between'>
        <P bold display='block' margin='8px 0 0'>ยอดทั้งหมด {numeral(totalPrice).format('0,0.00')} บาท</P>
        <CollapseButton onClick={() => setOpenCollapse(!openCollapse)} rotate={openCollapse}>
          <Icon color='#FFFFFF' icon='fal fa-angle-up' />
        </CollapseButton>
      </Div>
      <QuotationCollapseDetailContainer>
        {
          data?.quotationLots?.map((v, i) => (
            <Div margin={i !== data.quotationLots.length - 1 ? '0 0 32px' : '0'} padding={i !== 0 ? '24px 0 0' : '0'} key={i} moreStyle={{ borderTop: i !== 0 ? '1px dashed rgba(82, 120, 160, 0.5)' : '0' }}>
              <SmallText bold margin='0 0 6px'>ล็อตที่ {v.lotIndex}</SmallText>
              <Div display='flex'>
                <TextWithLabel title='ช่องทางการจัดส่ง' content={RECEIVE_TYPE[v.receiveType]} margin='0 16px 0 0' />
                {
                  !_.isEmpty(v.deliveryDate)
                  && <TextWithLabel title='วันที่จัดส่ง' content={moment(v.deliveryDate).format('DD/MM/YYYY')} />
                }
              </Div>
              {
                !_.isEmpty(v.deliveryChannels)
                && <Div margin='8px 0 0'>
                  <SmallText bold margin='0 0 8px'>Tracking Number ทั้งหมด</SmallText>
                  {
                    v.deliveryChannels?.map((o, index) => (
                      <Div display='flex' key={index} margin={index !== 0 ? '6px 0 0' : '0'}>
                        <Div width='20px' margin='0 6px 0 0'><Label bold>{index + 1}.</Label></Div>
                        <Div display='flex' flexDirection='column' width='20%' margin='0 24px 0 0' style={{ minWidth: '200px' }}>
                          <Label>บริษัทขนส่ง</Label>
                          <SmallText bold>{o.name || '-'}</SmallText>
                        </Div>
                        <Div display='flex' flexDirection='column' width='20%' style={{ minWidth: '200px' }}>
                          <Label>Tracking Number</Label>
                          <SmallText bold>{o.trackingNumber || '-'}</SmallText>
                        </Div>
                      </Div>
                    ))
                  }
                </Div>
              }
              <TableList data={v.quotationItems} deliveryCost={v.deliveryCost} deliveryType={_.find(mutationVariables, (o) => o.data.id === v.id)?.addressId} />
            </Div>
          ))
        }
        {
          data?.paymentType !== PAYMENT_TYPE.CREDIT && !_.isEmpty(data?.quotationPayments)
          && <Div display='flex' flexDirection='column' margin='32px 0 0' padding='20px 0 0' moreStyle={{ borderTop: '1px solid rgba(82,120,160,0.5)' }}>
            <SmallText margin='0 0 8px' bold>รายละเอียดจัดส่ง และ ชำระเงิน</SmallText>
            <Div moreStyle={{ maxHeight: '500px', overflow: 'auto' }}>
              {
                data?.quotationPayments?.map((v, i) => (
                  <Div key={i} display='flex' margin={i !== data?.quotationPayments.length - 1 ? '0 0 16px' : '0'}>
                    <SmallText margin='0 4px 0 0' width='30px'>{i + 1}.</SmallText>
                    <Div>
                      <PaymentContainer display='flex'>
                        {
                          !_.isEmpty(v?.bankName) && !_.isEmpty(v?.bankFullName)
                        && <Div>
                          <SmallText color={theme.color.charcoalGrey}>บัญชีต้นทาง</SmallText>
                          <Div display='flex'>
                            {
                              !_.isEmpty(v?.bankName)
                              && <BankLogo src={mapImageBank(v?.bankName)} />
                            }
                            <Div margin={!_.isEmpty(v?.bankName) ? '3px 0 0 8px' : '3px 0 0'}>
                              <SmallText display='block' bold>{v?.bankFullName}</SmallText>
                            </Div>
                          </Div>
                        </Div>
                        }
                        {
                          !_.isEmpty(v?.finnicBankName) && !_.isEmpty(v?.finnicBankFullName)
                        && <Div margin={!_.isEmpty(v?.bankName) && !_.isEmpty(v?.bankFullName) ? '0 32px 0' : '0 32px 0 0'}>
                          <SmallText color={theme.color.charcoalGrey}>บัญชีปลายทาง</SmallText>
                          <Div display='flex' alignItems='center'>
                            {
                              !_.isEmpty(v?.finnicBankName)
                              && <BankLogo src={mapImageBank(v?.finnicBankName)} />
                            }
                            <Div margin={!_.isEmpty(v?.finnicBankName) ? '3px 0 0 8px' : '3px 0 0'}>
                              <SmallText display='block' bold>{v?.finnicBankFullName}</SmallText>
                              <SmallText display='block' bold>{v?.bankAccount}</SmallText>
                            </Div>
                          </Div>
                        </Div>
                        }
                        <Div margin='0 32px 0 0'>
                          <Label color={theme.color.charcoalGrey} margin='0 0 4px'>วันเวลาที่ชำระเงิน</Label>
                          <Div display='flex' alignItems='center'>
                            <Div>
                              <SmallText>{_.isEmpty(v?.date) ? '-' : moment.tz(v?.date, 'Asia/Bangkok').format('DD/MM/YYYY')} {v?.time}</SmallText>
                            </Div>
                          </Div>
                        </Div>
                        {
                          v?.url
                        && <Div>
                          <Label display='block' color={theme.color.charcoalGrey} margin='0 0 4px'>รูปภาพการชำระเงิน</Label>
                          <a href={v?.url} style={{ width: 'fit-content' }} download>
                            <Label display='block' color={theme.color.tertiaryColor} margin='0 0 4px' style={{ textDecoration: 'underline' }}>ดาวน์โหลดรูปภาพการชำระเงิน</Label>
                            <img src={v?.url} style={{ maxWidth: '160px' }} />
                          </a>
                        </Div>
                        }
                      </PaymentContainer>
                    </Div>
                  </Div>
                ))
              }
            </Div>
          </Div>
        }
      </QuotationCollapseDetailContainer>
    </QuotationDetailConainer>
  )
}

const Checkbox = ({
  name, id, active, onChange, children, margin, color,
}) => (
  <Div margin={margin}>
    {
      (id === 'MANUAL' || id === 'noTax')
      && <hr style={{ margin: '24px 0', borderTop: `1px solid ${theme.color.paleBlue}` }} />
    }
    <input className='radio-input' type='radio' name={name} id={id} style={{ display: 'none' }} />
    <CheckboxLabel color={color} htmlFor={id} isCheck={active} onClick={() => onChange()}>
      <Div className='check-icon'></Div>
      {children}
    </CheckboxLabel>
  </Div>
)

const SelectAddress = ({ current, list, onChange }) => (
  <Div>
    {
      list.map((o, i) => (
        <Checkbox color={o.color} key={i} margin='0 0 16px' name={o.name} id={o.id} active={current === o.id} onChange={() => onChange(o.id)}>
          { o.content }
        </Checkbox>
      ))
    }
  </Div>
)

const Step = ({ currentStep, isShowPayment }) => (
  <StepList isShowPayment={isShowPayment} current={currentStep}>
    <StepItem active={currentStep > 1} current>
      <Div>
        <Label>{currentStep > 1 ? '' : 1}</Label>
      </Div>
      <SmallText>เลือกที่อยู่จัดส่ง</SmallText>
    </StepItem>
    <StepItem active={currentStep > 2} current={currentStep === 2}>
      <Div>
        <Label>{currentStep > 2 ? '' : 2}</Label>
      </Div>
      <SmallText>เลือกรับใบกำกับภาษี</SmallText>
    </StepItem>
    {
      isShowPayment
      && <StepItem current={currentStep === 3}>
        <Div>
          <Label>3</Label>
        </Div>
        <SmallText>ยืนยันชำระเงิน</SmallText>
      </StepItem>
    }
  </StepList>
)

const requireFieldText = (title, require) => <SmallText>{title}{require && <Label color={theme.color.error} margin='0 0 0 4px'>*</Label>}</SmallText>

const SelectPayment = ({
  current, list, onChange, onUpload, files, onRemove, totalPrice, amount, depositAmount, totalPayment,
}) => (
  <Div>
    { requireFieldText('เลือกธนาคาร', true) }
    <Div margin='16px 0 0'>
      {
        list?.map((bankData, i) => (
          <Checkbox key={i} margin={i !== list?.length - 1 ? '0 0 8px' : '0'} name={bankData.name} id={bankData.id} active={current === bankData.id} onChange={() => onChange(bankData.id, 'finnicBankId')}>
            <Div padding='16px'>
              <Div display='flex' alignItems='center' margin='0 0 8px'>
                <LogoBg imgUrl={bankData.logo} />
                <P margin='0 0 0 6px' bold>{bankData.bank.name}</P>
              </Div>
              <P>{bankData.detail.name}<br/>{bankData.detail.number}</P>
            </Div>
          </Checkbox>
        ))
      }
    </Div>
    <Div margin='32px 0 0' display='flex'>
      <Div width='calc(100% / 3)'>
        {
          requireFieldText('วันที่ชำระ', true)
        }
        <Div margin='4px 0 0'>
          <DatePicker
            inputReadOnly={true}
            disabledDate={(currentDate) => currentDate && currentDate >= moment().endOf('day')}
            onChange={(value) => onChange(value, 'transferDate')}
            format='DD/MM/YYYY'
            style={{ width: '100%' }}
          />
        </Div>
      </Div>
      <Div margin='0 0 0 24px' width='calc(100% / 3)'>
        {
          requireFieldText('เวลาที่ชำระ', true)
        }
        <Div margin='4px 0 0'>
          <TimePicker style={{ width: '100%' }} onChange={(value) => onChange(_.isEmpty(value) ? null : moment(value).format('HH:mm'), 'transferTime')} format='HH:mm'/>
        </Div>
      </Div>
      <Div margin='0 0 0 24px' width='calc(100% / 3)'>
        {
          requireFieldText('ยอดชำระ', true)
        }
        <Div margin='4px 0 0'>
          <Input
            width='100%'
            message={{ text: amount < (totalPayment > 0 ? depositAmount - totalPayment : depositAmount) ? 'จำนวนเงินต้องไม่น้อยกว่าจำนวนมัดจำ' : '', color: theme.color.error, margin: '2px 0 0' }}
            state={amount < (totalPayment > 0 ? depositAmount - totalPayment : depositAmount) ? 'error' : 'default'}
            value={amount}
            rule={{ type: 'float' }}
            onChange={(e) => {
              if (_.isEmpty(e.target.value)) {
                onChange('', 'amount')
              } else if ((parseFloat(e.target.value) <= (totalPayment > 0 ? totalPrice - totalPayment : parseFloat(totalPrice)))) {
                onChange(e.target.value, 'amount')
              }
            }}
          />
        </Div>
      </Div>
    </Div>
    <Div margin='16px 0 0' display='flex' alignItems='center' justifyContent='space-between'>
      { requireFieldText('อัพโหลดรูปภาพ', true) }
      <LabelContainer>
        <input accept='image/*' type='file' id='evidence' onChange={(e) => onUpload(e.target.files)} />
        <LabelUploadButton htmlFor='evidence'>
          <Icon icon='fal fa-image' />
          <SmallText margin='0 0 0 6px'>เลือกรูปภาพ</SmallText>
        </LabelUploadButton>
      </LabelContainer>
    </Div>
    {
      files
      && <Div margin='8px 0 0' display='flex' alignItems='center' justifyContent='flex-end'>
        <SmallText margin='0 16px 0 0'>{files?.name}</SmallText>
        <Icon icon='fal fa-trash-alt' color={theme.color.error} cursor='pointer' onClick={() => onRemove()} />
      </Div>
    }
  </Div>
)

const quotationContainer = (props) => {
  const [step, setStep] = useState(1)
  const [isCreditType, setIsCreditType] = useState(false)
  const [mutationVariables, setMutationVariables] = useState({
    addressId: 'newAddress',
    newAddress: {
      subdistrict: '',
      district: '',
      province: '',
      zipcode: '',
    },
    finnicBankId: undefined,
    files: undefined,
  })
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isCustomer] = useState(true)
  const [verifySlipData, setVerifySlipData] = useState({})

  const id = parseInt(props?.match?.params?.id || props?.id)
  const token = props?.match?.params?.token || props?.token

  const { data: quotationData, loading: quotationLoading } = useQuery(
    GET_QUOTATION_USER,
    {
      variables: { id },
      client: publicClient,
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    },
  )
  const { data: bankData, loading: bankLoading } = useQuery(BANK_ACCOUNT, {
    client: publicClient,
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  })
  const [updateQuotation, { data: updateQuotationData, loading: updateQuotationLoading }] = useMutation(QUOTATION_UPDATE, {
    client: publicClient,
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  })
  const isAllLotNotTransfer = !_.some(quotationData?.quotationInfo?.data.quotationLots, (o) => o.receiveType === 'TRANSFER')

  const isShowStep = quotationData?.quotationInfo?.data?.status === 'WAITING_ADDRESS' && !quotationData?.quotationInfo?.data?.isExpired
  const isCOD = quotationData?.quotationInfo?.data?.isCOD

  useEffect(() => {
    const dataLots = quotationData?.quotationInfo?.data?.quotationLots?.map((v) => (
      {
        customer: quotationData?.quotationInfo?.data?.customer,
        data: v,
        addressId: v.receiveType === 'MANUAL' ? 'MANUAL' : v.deliveryAddressId || 'newAddress',
        newAddress: {
          contactName: '',
          contactPhoneNumber: '',
          contactOfficeTel: '',
          addressNo: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
        },
        taxAddress: {
          addressId: 'noTax',
          newAddress: {
            contactName: '',
            contactPhoneNumber: '',
            addressNo: '',
            subdistrict: '',
            district: '',
            province: '',
            zipcode: '',
          },
        },
        isSetToPrimary: false,
      }
    )) || []
    setMutationVariables({
      lots: [...dataLots],
      finnicBankId: (quotationData?.quotationInfo?.data?.paymentType === 'CASH' && !_.isEmpty(mutationVariables.lots) && !_.isEmpty(bankData?.finnicBankList?.data))
        ? bankData?.finnicBankList?.data[0].id
        : undefined,
      files: undefined,
    })
  }, [quotationData?.quotationInfo?.data?.quotationLots, bankData])

  useEffect(() => {
    if (!_.isEmpty(quotationData?.quotationInfo?.data?.quotationLots) && isAllLotNotTransfer) {
      setStep(2)
    }
    if (quotationData?.quotationInfo?.data?.paymentType === 'CREDIT' || isCOD) {
      setIsCreditType(true)
    }
  }, [quotationData, bankData])

  const validateNewAddress = (type) => {
    const list = type === 'tax' ? _.filter(mutationVariables.lots, (v) => v.taxAddress?.addressId === 'newTaxAddress') : _.filter(mutationVariables.lots, (v) => v.addressId === 'newAddress')
    return !_.isEmpty(list) && !_.isEmpty(_.flatten(list.map((lot) => _.filter(addressField.map((data) => (
      type === 'tax'
        ? data.keyName === 'contactOfficeTel'
          ? false
          : _.isEmpty(lot.taxAddress.newAddress[data.keyName])
        : (data.keyName !== 'contactOfficeTel' ? _.isEmpty(lot.newAddress[data.keyName]) : false
        )
    )), (v) => v))))
  }

  const addressList = (data) => {
    if (_.isEmpty(data.data)) {
      return []
    }
    let allAddressList = [
      {
        name: 'selectAddress',
        id: 'newAddress',
        color: theme.color.tertiaryColor,
        content: <Div>
          <Div display='flex'>
            <Div display='flex' alignItems='center' padding='16px 32px' bgColor={theme.color.secondaryColor}>
              <Icon fontSize='30px' icon='fas fa-shipping-fast' color='white' />
            </Div>
            <Div padding='16px'>
              <P style={{ fontSize: '18px' }} bold display='block'>จัดส่ง - ที่อยู่ใหม่</P>
              <P display='block' margin='16px 0 0'>กรอกที่อยู่ใหม่</P>
            </Div>
          </Div>
          {
            data.addressId === 'newAddress'
            && <Div padding='0 24px 16px'>
              {
                addressField.map((v, i) => {
                  if (v?.isInputAddress) {
                    return (
                      <Div key={v?.keyName} className={['auto-address-input', _.isEmpty(data?.newAddress[v?.keyName]) && 'empty']}>
                        <SmallText>{requireFieldText(v.title, v.require)}</SmallText>
                        <InputAddress
                          placeholder={`กรอก${v.title}`}
                          address={v?.keyName}
                          value={data?.newAddress[v?.keyName]}
                          onChange={(e) => {
                            setMutationVariables({
                              ...mutationVariables,
                              lots: mutationVariables.lots.map((obj) => {
                                if (obj.data.id === data.data.id) return { ...data, newAddress: { ...obj?.newAddress, [v?.keyName]: e.target.value } }
                                return obj
                              }),
                            })
                          }}
                          onSelect={(val) => {
                            setMutationVariables({
                              ...mutationVariables,
                              lots: mutationVariables.lots.map((obj) => {
                                if (obj.data.id === data.data.id) return { ...data, newAddress: { ...obj?.newAddress, ...val } }
                                return obj
                              }),
                            })
                          }}
                        />
                      </Div>
                    )
                  }
                  return (
                    <InputWithLabelCustom
                      key={i}
                      margin={v.margin || '0 0 16px'}
                      width={v.width || '100%'}
                      title={{ text: requireFieldText(v.title, v.require) }}
                      input={{
                        placeholder: `กรอก${v.title}`,
                        width: '100%',
                        value: data.newAddress[v.keyName] || null,
                        state: (
                          _.isEmpty(data.newAddress[v.keyName]) || (v.keyName === 'contactPhoneNumber' && data.newAddress[v.keyName].length !== 10)
                        ) && v.keyName !== 'contactOfficeTel'
                          ? 'error'
                          : 'default',
                        onChange: (e) => {
                          setMutationVariables({
                            ...mutationVariables,
                            lots: mutationVariables.lots.map((obj) => {
                              if (obj.data.id === data.data.id) return { ...data, newAddress: { ...obj?.newAddress, [v?.keyName]: e.target.value } }
                              return obj
                            }),
                          })
                        },
                      }}
                    />
                  )
                })
              }
              <Div margin='16px 0 0'>
                <CheckboxWithText
                  data={{
                    value: `lot - ${data.data.id}`,
                    text: <SmallText>ใช้เป็นที่อยู่จัดส่งหลัก</SmallText>,
                  }}
                  checked={_.find(mutationVariables.lots, (o) => o.data.id === data.data.id)?.isSetToPrimary}
                  onChange={(e) => {
                    setMutationVariables({
                      ...mutationVariables,
                      lots: mutationVariables.lots.map((obj) => {
                        if (obj.data.id === data.data.id) return { ...data, isSetToPrimary: e.target.checked }
                        return { ...obj, isSetToPrimary: false }
                      }),
                    })
                  }}
                />
              </Div>
            </Div>
          }
        </Div>,
      },
    ]
    if (!_.isEmpty(data?.customer?.shippingAddress)) {
      allAddressList = _.concat(_.compact(data?.customer?.shippingAddress?.map((v) => {
        if (_.isEmpty(v?.postcode)) return null
        return (
          {
            name: 'selectAddress',
            id: v?.id,
            content: <Div display='flex'>
              <Div display='flex' alignItems='center' padding='16px 32px' bgColor={theme.color.primaryColor}>
                <Icon fontSize='30px' icon='fas fa-shipping-fast' color='white' />
              </Div>
              <Div padding='16px'>
                <P style={{ fontSize: '18px' }} bold display='block'>จัดส่ง - {v?.contactName} {!_.isEmpty(v?.contactPhoneNumber) && `(เบอร์ติดต่อ ${v?.contactPhoneNumber})`}</P>
                <P display='block' margin='16px 0 0'>{`${v?.addressNo} ${v?.subDistrict} ${v?.district} ${v?.province} ${v?.postcode}`}</P>
              </Div>
            </Div>,
          }
        )
      })), allAddressList)
    }
    return allAddressList
  }

  const taxAddressList = (data) => {
    const list = [
      {
        name: 'selectTaxAddress',
        id: 'newTaxAddress',
        color: theme.color.tertiaryColor,
        content: <Div>
          <Div display='flex'>
            <Div display='flex' alignItems='center' padding='16px 32px' bgColor={theme.color.secondaryColor}>
              <Icon fontSize='30px' icon='fas fa-file-edit' color='white' />
            </Div>
            <Div padding='16px'>
              <P style={{ fontSize: '18px' }} bold display='block'>ที่อยู่อื่น</P>
              <P display='block' margin='16px 0 0'>กรอกที่อยู่ใหม่</P>
            </Div>
          </Div>
          {
            data.taxAddress.addressId === 'newTaxAddress'
            && <Div padding='0 24px 16px'>
              {
                _.filter(addressField, (field) => field.keyName !== 'contactOfficeTel').map((v, i) => {
                  if (v?.isInputAddress) {
                    return (
                      <Div key={v?.keyName} className={['auto-address-input', _.isEmpty(data?.taxAddress?.newAddress[v?.keyName]) && 'empty']}>
                        <SmallText>{requireFieldText(v.title, v.require)}</SmallText>
                        <InputAddress
                          placeholder={`กรอก${v.title}`}
                          address={v?.keyName}
                          value={data?.taxAddress?.newAddress[v?.keyName]}
                          onChange={(e) => {
                            setMutationVariables({
                              ...mutationVariables,
                              lots: mutationVariables.lots.map((obj) => {
                                if (obj?.data?.id === data?.data?.id) return { ...data, taxAddress: { ...obj?.taxAddress, newAddress: { ...obj?.taxAddress?.newAddress, [v?.keyName]: e.target.value } } }
                                return obj
                              }),
                            })
                          }}
                          onSelect={(val) => {
                            setMutationVariables({
                              ...mutationVariables,
                              lots: mutationVariables.lots.map((obj) => {
                                if (obj?.data?.id === data?.data?.id) return { ...data, taxAddress: { ...obj?.taxAddress, newAddress: { ...obj?.taxAddress?.newAddress, ...val } } }
                                return obj
                              }),
                            })
                          }}
                        />
                      </Div>
                    )
                  }
                  return (
                    <InputWithLabelCustom
                      key={i}
                      margin={v.margin || '0 0 16px'}
                      width={v.width || '100%'}
                      title={{ text: requireFieldText((v.keyName === 'contactPhoneNumber' ? 'เบอร์ติดต่อ' : v.title), v.require) }}
                      input={{
                        placeholder: `กรอก${v.keyName === 'contactPhoneNumber' ? 'เบอร์ติดต่อ' : v.title}`,
                        width: '100%',
                        value: data?.taxAddress?.newAddress[v.keyName] || null,
                        state: _.isEmpty(data?.taxAddress?.newAddress[v.keyName]) ? 'error' : 'default',
                        onChange: (e) => {
                          setMutationVariables({
                            ...mutationVariables,
                            lots: mutationVariables.lots.map((obj) => {
                              if (obj?.data?.id === data?.data?.id) return { ...data, taxAddress: { ...obj?.taxAddress, newAddress: { ...obj?.taxAddress?.newAddress, [v?.keyName]: e.target.value } } }
                              return obj
                            }),
                          })
                        },
                      }}
                    />
                  )
                })
              }
            </Div>
          }
        </Div>,
      },
      {
        name: 'selectTaxAddress',
        id: 'noTax',
        color: theme.color.warning,
        content: <Div display='flex'>
          <Div display='flex' alignItems='center' padding='16px 32px' bgColor={theme.color.warning}>
            <Icon fontSize='30px' icon='fas fa-file-times' color='white' />
          </Div>
          <Div padding='16px'>
            <P style={{ fontSize: '18px' }} bold display='block'>ไม่รับใบกำกับภาษี</P>
            <P display='block' margin='16px 0 0'>ไม่ต้องการรับใบกำกับภาษีสำหรับล็อตนี้</P>
          </Div>
        </Div>,
      },
    ]
    const findPrimaryAddress = _.find(data?.customer?.billingAddress, (o) => o.isPrimary) || (data?.customer?.billingAddress.length === 1 && data?.customer?.billingAddress[0])
    if (!_.isEmpty(findPrimaryAddress)) {
      list.splice(0, 0, {
        name: 'selectTaxAddress',
        id: findPrimaryAddress.id,
        color: theme.color.primaryColor,
        content: <Div display='flex'>
          <Div display='flex' alignItems='center' padding='16px 32px' bgColor={theme.color.primaryColor}>
            <Icon fontSize='30px' icon='fas fa-file-check' color='white' />
          </Div>
          <Div padding='16px'>
            <P style={{ fontSize: '18px' }} bold display='block'>{findPrimaryAddress.contactName}</P>
            <P display='block' margin='16px 0 0'>{`${findPrimaryAddress?.addressNo} ${findPrimaryAddress?.subDistrict} ${findPrimaryAddress?.district} ${findPrimaryAddress?.province} ${findPrimaryAddress?.postcode}`}</P>
          </Div>
        </Div>,
      }, ..._.filter(data?.customer?.billingAddress, (address) => !address.isPrimary).map((address) => ({
        name: 'address',
        id: address.id,
        color: theme.color.primaryColor,
        content: <Div display='flex'>
          <Div display='flex' alignItems='center' padding='16px 32px' bgColor={theme.color.primaryColor}>
            <Icon fontSize='30px' icon='fas fa-file-check' color='white' />
          </Div>
          <Div padding='16px'>
            <P style={{ fontSize: '18px' }} bold display='block'>{address.contactName}</P>
            <P display='block' margin='16px 0 0'>{`${address?.addressNo} ${address?.subDistrict} ${address?.district} ${address?.province} ${address?.postcode}`}</P>
          </Div>
        </Div>,
      })))
    }
    return list
  }

  const bankList = (!bankLoading && bankData?.finnicBankList?.data.map((v) => (
    {
      name: 'bank',
      id: v.id,
      logo: mapImageBank(v.bank.code),
      bank: v.bank,
      detail: {
        name: v.bankAccountName,
        number: v.bankAccountNumber,
      },
    }
  ))) || []

  if (quotationLoading) {
    return (
      <Div height='100vh' display='flex' alignItems='center' justifyContent='center'>
        <Icon icon='fal fa-spinner fa-pulse' fontSize={theme.fonts.size.subHeading} color={theme.color.primaryColor} />
      </Div>
    )
  }
  if (!quotationData?.quotationInfo?.success) {
    return (
      <Div bgColor={theme.color.paleBlue} height='100vh' display='flex' alignItems='center' justifyContent='center'>
        <Div textAlign='center'>
          <img src={airCon} width='168px' />
          <H3 display='block' textAlign='center' color={theme.color.charcoalGrey} margin='32px 0 8px' style={{ fontWeight: 800 }}>Something went wrong</H3>
          <P bold display='block' textAlign='center' color={theme.color.brownGrey}>{quotationData?.quotationInfo?.message || 'คุณไม่มีสิทธิในการเข้างานข้อมูลส่วนนี้'}</P>
        </Div>
      </Div>
    )
  }

  const showBackButtonCondition = !(step === 2 && isAllLotNotTransfer) && step > 1
  const isViewQr = isCustomer && !_.isEmpty(quotationData?.quotationInfo?.data?.qrCodeUrl)
  const totalPrice = _.reduce(mutationVariables.lots, (total, lot) => {
    if (lot?.addressId === 'MANUAL') {
      return total + _.reduce(lot?.data?.quotationItems, (sum, n) => sum + n.totalPrice, 0)
    }
    return total + _.reduce(lot?.data?.quotationItems, (sum, n) => sum + n.totalPrice, 0) + lot?.data?.deliveryCost
  }, 0)

  const updateQuotationFn = () => {
    const variables = {
      id: quotationData?.quotationInfo?.data?.id,
      finnicBankId: mutationVariables.finnicBankId,
      files: mutationVariables.files,
      transferDate: mutationVariables.transferDate,
      transferTime: mutationVariables.transferTime,
      amount: _.isNil(mutationVariables.amount) ? undefined : parseFloat(mutationVariables.amount),
      quotationLots: mutationVariables.lots.map((v) => (
        {
          id: v.data.id,
          addressId: v.addressId === 'newAddress' || v.addressId === 'MANUAL' ? undefined : v.addressId,
          newAddress: v.addressId !== 'newAddress' ? undefined : {
            ..._.omit(v.newAddress, ['subdistrict', 'zipcode']),
            isPrimary: v.isSetToPrimary,
            subDistrict: v.newAddress?.subdistrict,
            postcode: v.newAddress?.zipcode,
          },
          deliveryCost: v.addressId === 'MANUAL' ? 0 : undefined,
          billingAddressId: v.taxAddress?.addressId === 'noTax' || v.taxAddress?.addressId === 'newTaxAddress' ? undefined : v.taxAddress?.addressId,
          newBillingAddress: v.taxAddress?.addressId !== 'newTaxAddress' ? undefined : { ..._.omit(v.taxAddress?.newAddress, ['subdistrict', 'zipcode']), subDistrict: v.taxAddress?.newAddress?.subdistrict, postcode: v.taxAddress?.newAddress?.zipcode },
        }
      )),
    }
    updateQuotation({
      variables,
    }).then((resp) => {
      if (resp?.data?.updateQuotation?.success) {
        if (isCOD) {
          message.success(resp?.data?.updateQuotation?.message)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else if (isCreditType) {
          setStep(step + 2)
        } else {
          setStep(step + 1)
        }
        setIsOpenModal(false)
      } else {
        message.error(resp?.data?.updateQuotation?.message)
      }
    })
  }

  return (
    <Div>
      <QuotationContainer>
        <Container>
          <H3>ใบเสนอราคา</H3>
          <QuotationDetail open={true} data={quotationData?.quotationInfo?.data} totalPrice={totalPrice} mutationVariables={mutationVariables.lots} />
          <Div margin='32px 0'>
            {
              !isShowStep
                ? isCOD
                  ? null
                  : quotationData?.quotationInfo?.data?.status === 'WAITING_PAYMENT'
                    ? <Div>
                      <P display='block' bold margin='0 0 16px'>ชำระเงิน ผ่าน QR code - ยอดชำระผ่าน QR code {numeral(quotationData?.quotationInfo?.data?.transferAmount || 0).format('0,0.00')} บาท</P>
                      <LinkDownload href={quotationData?.quotationInfo?.data?.qrCodeUrl} target='_blank'>
                        <P display='block'>คลิกเพื่อดาวน์โหลดรูปภาพ</P>
                      </LinkDownload>
                      <br/>
                      <QrImg src={quotationData?.quotationInfo?.data?.qrCodeUrl} />
                      <Line />
                      <VerifySlip
                        quotationId={quotationData?.quotationInfo?.data?.id}
                        verifySlipData={verifySlipData}
                        onChange={(key, value) => setVerifySlipData({ ...verifySlipData, [key]: value })}
                        setEmpty={() => setVerifySlipData({})}
                      />
                    </Div>
                    : null
                : step === 4
                  ? <Div margin='30% 0 0' textAlign='center'>
                    <Icon icon='fal fa-check-circle' fontSize={theme.fonts.size.subHeading} color={theme.color.success} />
                    <H3 bold display='block' textAlign='center' margin='16px 0 0' color={theme.color.success}>ชำระเงินสำเร็จ</H3>
                  </Div>
                  : <Div>
                    <Step currentStep={step} isShowPayment={!isCreditType && !isCOD} />
                    {
                      step === 1
                        ? <Div>
                          {
                            mutationVariables.lots.map((v, i) => (
                              <Div key={i} margin={v.data.receiveType !== 'TRANSFER' ? '0 0 16px' : '0 0 56px'}>
                                {
                                  v.data.receiveType !== 'TRANSFER'
                                    ? <P bold color={theme.color.heather} display='block'>ล็อตที่ {v.data.lotIndex} - ไม่ต้องเลือกที่อยู่จัดส่ง เนื่องจากเลือก {RECEIVE_TYPE[v.data.receiveType]}</P>
                                    : <P bold margin='0 0 16px' display='block'>ล็อตที่ {v.data.lotIndex} - เลือกที่อยู่จัดส่ง</P>
                                }
                                {
                                  v.data.receiveType === 'TRANSFER'
                            && <SelectAddress
                              current={v.addressId}
                              list={addressList(v)}
                              onChange={(value) => {
                                setMutationVariables({
                                  ...mutationVariables,
                                  lots: mutationVariables.lots.map((obj) => {
                                    if (obj.data.id === v.data.id) return { ...v, addressId: value }
                                    return obj
                                  }),
                                })
                              }}
                            />
                                }
                              </Div>
                            ))
                          }
                        </Div>
                        : step === 2
                          ? <Div>
                            {
                              mutationVariables.lots.map((v, i) => (
                                <Div key={i} margin='0 0 40px'>
                                  <P bold margin='0 0 16px' display='block'>ล็อตที่ {v.data.lotIndex} - เลือกรับใบกำกับภาษี</P>
                                  {
                                    <SelectAddress
                                      current={v.taxAddress.addressId}
                                      list={taxAddressList(v)}
                                      onChange={(value) => {
                                        setMutationVariables({
                                          ...mutationVariables,
                                          lots: mutationVariables.lots.map((obj) => {
                                            if (obj.data.id === v.data.id) return { ...v, taxAddress: { ...v.taxAddress, addressId: value } }
                                            return obj
                                          }),
                                        })
                                      }}
                                    />
                                  }
                                </Div>
                              ))
                            }
                          </Div>
                          : isViewQr
                            ? <Div>
                              <P display='block' bold margin='0 0 16px'>ชำระเงิน ผ่าน QR code - ยอดชำระผ่าน QR code {numeral(updateQuotationData?.updateQuotation?.data?.transferAmount || quotationData?.quotationInfo?.data?.transferAmount || 0).format('0,0.00')} บาท</P>
                              <LinkDownload href={quotationData?.quotationInfo?.data?.qrCodeUrl} target='_blank'>
                                <P display='block'>คลิกเพื่อดาวน์โหลดรูปภาพ</P>
                              </LinkDownload>
                              <br/>
                              <QrImg src={updateQuotationData?.updateQuotation?.data?.qrCodeUrl || quotationData?.quotationInfo?.data?.qrCodeUrl} />
                              <Line />
                              <VerifySlip
                                quotationId={quotationData?.quotationInfo?.data?.id}
                                verifySlipData={verifySlipData}
                                onChange={(key, value) => setVerifySlipData({ ...verifySlipData, [key]: value })}
                                setEmpty={() => setVerifySlipData({})}
                              />
                            </Div>
                            : <SelectPayment
                              current={mutationVariables.finnicBankId}
                              list={bankList}
                              onChange={(value, key) => setMutationVariables({ ...mutationVariables, [key]: value })}
                              onUpload={(file) => setMutationVariables({ ...mutationVariables, files: file[0] })}
                              files={mutationVariables.files}
                              onRemove={() => setMutationVariables({ ...mutationVariables, files: undefined })}
                              totalPrice={totalPrice}
                              depositAmount={quotationData?.quotationInfo?.data?.transferAmount}
                              totalPayment={_.sumBy(quotationData?.quotationInfo?.data?.quotationPayments, 'amount')}
                              amount={mutationVariables.amount}
                            />
                    }
                    <Div display={(step === 3 && isViewQr) ? 'none' : 'flex'} margin='32px 0 0' justifyContent={!showBackButtonCondition ? 'flex-end' : 'space-between'}>
                      {
                        showBackButtonCondition && <Button
                          buttonStyle='round'
                          icon='fal fa-arrow-left'
                          color={theme.color.tertiaryColor}
                          text='ย้อนกลับ'
                          onClick={() => setStep(step - 1)}
                        />
                      }
                      <GradientButton
                        buttonStyle='round'
                        inverse
                        rightIcon={step === 1}
                        disabled={updateQuotationLoading
                    || (step === 1
                      ? validateNewAddress()
                      || (mutationVariables.lots[0]?.addressId === 'newAddress' && mutationVariables.lots[0]?.newAddress?.contactPhoneNumber.length !== 10)
                      : step === 2
                        ? validateNewAddress('tax')
                        : !isViewQr && (_.isUndefined(mutationVariables.files)
                          || isOpenModal
                          || _.isUndefined(mutationVariables.finnicBankId) || mutationVariables.finnicBankId <= 0
                          || _.isEmpty(mutationVariables.transferDate)
                          || _.isEmpty(mutationVariables.transferTime)
                          || _.isNil(mutationVariables.amount) || mutationVariables.amount <= 0
                          || mutationVariables.amount < (_.sumBy(quotationData?.quotationInfo?.data?.quotationPayments, 'amount') > 0 ? quotationData?.quotationInfo?.data?.transferAmount - _.sumBy(quotationData?.quotationInfo?.data?.quotationPayments, 'amount') : parseFloat(quotationData?.quotationInfo?.data?.transferAmount))
                        )
                    )
                        }
                        lastStep={step === 3 || (step === 2 && isViewQr)}
                        loading={updateQuotationLoading}
                        text={
                          step === 3
                            ? 'ยืนยันชำระเงิน'
                            : step === 2
                              ? 'ยืนยันคำสั่งซื้อ'
                              : 'ขั้นตอนต่อไป'
                        }
                        icon={step === 3 || (step === 2 && isViewQr) ? 'fal fa-check' : 'fal fa-arrow-right'}
                        onClick={() => {
                          if (step === 3 || (step === 2 && (isViewQr || isCreditType))) {
                            updateQuotationFn()
                          } else {
                            setStep(step + 1)
                          }
                        }}
                      />
                    </Div>
                  </Div>
            }
          </Div>
        </Container>
      </QuotationContainer>
    </Div>
  )
}

const VerifySlip = ({
  onChange, verifySlipData, quotationId, setEmpty,
}) => {
  const [disabledPopconfirm, setDisabledPopconfirm] = useState(true)
  const { data: verifyBankListData } = useQuery(BANK_LIST)
  const [doVerifySlip, { data: verifyData, loading }] = useMutation(VERIFY_SLIP)
  const [doConfirmVerifySlip, { loading: confirmLoading }] = useMutation(CONFIRM_PAYMENT)
  const { slipImage, refNo, bankCode } = verifySlipData

  useEffect(() => {
    if (!disabledPopconfirm) {
      document.getElementById('modal-button-confirm').click()
    }
  }, [disabledPopconfirm])

  return (
    <Div>
      <P bold margin='0 0 16px'>ตรวจสอบสลิป</P>
      <Div>
        { requireFieldText('เลือกธนาคาร', true) }
        <SelectAnt
          showSearch
          style={{
            width: '100%',
            margin: '6px 0 16px',
          }}
          placeholder='เลือกธนาคาร'
          value={bankCode}
          onChange={(e) => onChange('bankCode', e)}
        >
          {
            verifyBankListData?.bankList?.data?.list.map((o) => (
              <SelectAnt.Option key={o.bankCode} value={o.bankCode}>{o.name}</SelectAnt.Option>
            ))
          }
        </SelectAnt>
        { requireFieldText('รายละเอียด', true) }
        <Div margin='4px 0 0' className='slip-section' display='flex' alignItems='center'>
          <Uploader
            onChange={(e) => onChange('slipImage', e)}
            data={slipImage}
            accept='image/*'
            isMultiple={false}
            buttonText='อัพโหลดไฟล์'
            disabled={!_.isEmpty(refNo)}
          />
          <SmallText margin='0 16px'>หรือ</SmallText>
          <Input
            width='calc(100% - 176.35px)'
            state='default'
            value={refNo || ''}
            onChange={(e) => onChange('refNo', e.target.value)}
            placeholder='กรอก Reference number จากสลิป'
            disabled={!_.isEmpty(slipImage)}
          />
        </Div>
      </Div>
      <Popconfirm
        key="submit"
        title={
          verifyData?.verifySlip?.data?.totalPayment < verifyData?.verifySlip?.data?.depositAmount
            ? <P>ยอดชำระรวม ยังไม่ครบยอดที่กำหนดชำระ<br/>ต้องบันทึกการชำระเงินเพิ่มเติม จนถึงยอดที่กำหนดของใบสั่งจอง จึงจะดำเนินการต่อได้<br/>ต้องการบันทึกชำระเงินหรือไม่ ?</P>
            : verifyData?.verifySlip?.data?.totalPayment > verifyData?.verifySlip?.data?.depositAmount
              ? <P>ยอดชำระรวม เกินยอดที่กำหนดของใบสั่งจอง<br/>ต้องการบันทึกชำระเงินหรือไม่ ?</P>
              : <P>Loading...</P>
        }
        onConfirm={() => {
          doConfirmVerifySlip({
            variables: {
              quotationId,
            },
          }).then((resp) => {
            if (resp?.data?.confirmPayment?.success) {
              notification.success({
                message: resp?.data?.confirmPayment?.message,
                placement: 'topRight',
                style: {
                  boxShadow: `0 0 10px ${theme.color.success}44`,
                },
              })
            } else {
              notification.error({
                message: resp?.data?.confirmPayment?.message,
                placement: 'topRight',
                style: {
                  boxShadow: `0 0 10px ${theme.color.error}44`,
                },
              })
            }
          })
        }}
        onCancel={() => setDisabledPopconfirm(true)}
        onVisibleChange={(visible) => {
          if (!visible) setDisabledPopconfirm(true)
        }}
        destroyTooltipOnHide
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        disabled={disabledPopconfirm}
        okButtonProps={{
          disabled: _.isEmpty(verifyData),
        }}
      >
        <Button
          id='modal-button-confirm'
          disabled={confirmLoading || loading || _.isEmpty(bankCode) || (_.isEmpty(refNo) && _.isEmpty(slipImage))}
          loading={confirmLoading || loading}
          style={{ display: 'block' }}
          margin='24px auto 0'
          icon='fal fa-receipt'
          color={theme.color.success}
          inverse
          text='ตรวจสอบ'
          onClick={() => {
            doVerifySlip({
              variables: {
                transactionId: refNo,
                quotationId,
                bankCode,
                attachment: _.isEmpty(_.compact(slipImage)) ? undefined : slipImage[0],
              },
            }).then((resp) => {
              if (!_.isEmpty(resp?.data?.verifySlip)) {
                if (resp?.data?.verifySlip?.success) {
                  setEmpty()
                  notification.success({
                    message: resp?.data?.verifySlip?.message,
                    placement: 'topRight',
                    style: {
                      boxShadow: `0 0 10px ${theme.color.success}44`,
                    },
                  })
                } else if (!resp?.data?.verifySlip?.success && resp?.data?.verifySlip?.message === 'NEED_CONFIRMATION') {
                  setDisabledPopconfirm(false)
                } else {
                  notification.error({
                    message: resp?.data?.verifySlip?.message,
                    placement: 'topRight',
                    style: {
                      boxShadow: `0 0 10px ${theme.color.error}44`,
                    },
                  })
                }
              }
            })
          }}
        />
      </Popconfirm>
    </Div>
  )
}

export default quotationContainer
