import gql from 'graphql-tag'

export const NEXT_QUOTATION_NUMBER = gql`
  query nextQuotationNumber {
    nextQuotationNumber
  }
`

export const GET_TAGS = gql`
  query getTagList {
    getTagList {
      data
    }
  }
`

export const GET_QUOTATION_USER = gql`
  query quotationInfo (
    $id: Int!
  ) {
    quotationInfo(
      id: $id
    ) {
      success
      message
      data {
        id
        refId
        paymentType
        status
        isActive
        #isExpired
        isCOD
        qrCodeUrl
        channelType
        transferAmount
        transferDate
        transferTime
        chatProvider{
          id
          name
        }
        customer {
          id
          name
          shippingAddress {
            id
            contactName
            addressNo
            province
            district
            subDistrict
            postcode
            isPrimary
          }
          billingAddress {
            id
            contactName
            addressNo
            province
            district
            subDistrict
            postcode
            isPrimary
          }
        }
        quotationLots {
          id
          deliveryDate
          discount
          deliveryCost
          receiveType
          lotIndex
          quotationId
          deliveryAddressId
          billingAddressId
          deliveryChannel {
            id
            name
            #trackingNumber
          }
          lotDeliveryAddress {
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactPhoneNumber
          }
          lotBillingAddress {
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactPhoneNumber
          }
          quotationItems {
            id
            quantity
            price
            discount
            totalPrice
            pack {
              id
              variantName
            }
            set {
              id
              name
              ProductSetItems {
                id
                Pack {
                  id
                  variantName
                }
              }
            }
          }
        }
        finnicBank{
          id
          bankAccountName
          bankAccountNumber
          bank{
            id
            code
          }
        }
        files{
          id
          url
        }
        quotationPayments {
          transactionId
          url
          date
          time
          amount
          bankCode
          bankName
          bankFullName
          bankAccount
          finnicBankCode
          finnicBankName
          finnicBankFullName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_PRICE = gql`
  query getSuggestionPrice(
    $pricingTypeId: Int!
    $packId: String!
  ) {
    getSuggestionPrice (input: {
      pricingTypeId: $pricingTypeId
      packId: $packId
    }) {
      data {
        type
        salesPrice
      }
      success
      message
    }
  }
`

export const GET_PERMISSIONS = gql`
  query myRole {
    myRole {
      data {
        moduleName
        roleList {
          role {
            id
            name
          }
          permissionList{
            id
            name
            status
            description
          }
        }
      }
    }
  }
`

export const CUSTOMER_LIST_IN_CHAT = gql`
  query customerList (
    $toSync: Boolean
    $name: String
  ) {
    customerList (
      input: {
        toSync: $toSync
        name: $name
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          name
          contactAddress {
            id
            contactName
            contactLineId
          }
        }
      }
    }
  }
`

export const CUSTOMER_LIST = gql`
  query customerList(
    $limit: Int
    $offset: Int
    $name: String
    $pricingTypeId: Int
    $paymentType: PaymentTypes
    $orderField: CustomerOrderField
    $orderBy: OrderBy
  ) {
    customerList(input:{
      limit: $limit
      offset: $offset
      name: $name
      pricingTypeId: $pricingTypeId
      paymentType: $paymentType
      orderField: $orderField
      orderBy: $orderBy
    }) {
      data{
        list{
          id
          name
          paymentType
          pricingType{
            id
            name
          }
          shippingAddress{
            id
            addressNo
            subDistrict
            district
            province
            postcode
            contactPhoneNumber
          }
          contactAddress{
            id
            contactName
          }
        }
        total
      }
      message
      success
    }
  }
`

export const CUSTOMER = gql`
  query customerDetail(
    $id: Int!
  ) {
    customerDetail(id: $id) {
      data {
        id
        paymentType
        type
        pricingType {
          id
          code
          name
          status
        }
        prefix
        name
        taxId
        gender
        birthDate
        idCardNumber
        billingAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          taxId
          isPrimary
        }
        contactAddress {
          id
          supplierId
          customerId
          type
          position
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          contactLineId
          contactFacebook
          position
          isPrimary
        }
        shippingAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          shopName
          isPrimary
        }
        isVat
        contactName
        contactPhoneNumber {
          id
          name
          phoneNumber
          isPrimary
        }
        contactFaxNumber
        contactEmail {
          id
          email
          isPrimary
        }
        contactRemark
        creditLimit
        creditUsage
        creditTerm
        status
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_PREFIX = gql`
  query supplierPrefix {
    supplierPrefix {
      data
    }
  }
`

export const PRICINGTYPES = gql`
  query pricingTypeList{
    pricingTypeList {
      data {
        list {
          id
          code
          name
          status
          createdAt
          updatedAt
        }
        total
      }
    }
  }
`

export const BANK_ACCOUNT = gql`
  query finnicBankList {
    finnicBankList {
      success
      message
      data {
        id
        bankAccountType
        bankAccountName
        bankAccountNumber
        bank {
          id
          code
          name
        }
        status
      }
    }
  }
`

export const CHAT_DETAIL = gql`
  query chatChannelInfo(
    $channelId: Int
    $limit: Int
    $offset: Int
    $messageId: Int
  ) {
    chatChannelInfo(
      channelId: $channelId
      limit: $limit
      offset: $offset
      messageId: $messageId
    ) {
      data{
        id
        reminderDate
        reminder
        reminderUser {
          id
          firstName
          lastName
        }
        providerId
        chatContactId
        chatgroupId
        currentUpsell{
          id
          firstName
          lastName
        }
        firstReader{
          id
          firstName
          lastName
        }
        chatProvider{
          id
          name
        }
        owner {
          id
          firstName
          lastName
          roles {
            id
          }
        }
        chatContact{
          id
          customerId
          uid
          displayName
          profileUrl
          customer{
            id
            name
            contactName
            shippingAddress{
              id
              addressNo
              province
              district
              subDistrict
              postcode
            }
          }
          address{
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactPhoneNumber
            contactOfficeTel
          }
        }
        chatGroupStatus{
          id
          name
        }
        totalMessages
        facebookPost {
          id
          refId
          createTime
          pageName
          images {
            id
            url
          }
          message
          createdAt
          updatedAt
        }
        chatMessages{
          id
          chatContactId
          userId
          user{
            id
            firstName
            lastName
          }
          messageType
          messageResponseType
          isRead
          sendedMessage{
            type
            text
            url
          }
          sendedFlexMessage{
            refId
            status
            totalPrice
            quantity
            uri
            isActive
            imageUrl
            paymentType
            type
            isNewVersion
            deliveryCost
            itemList {
              itemName
              totalPrice
            }
          }
          receivedMessage{
            id
            type
            text
          }
          receivedSticker{
            id
            type
            packageId
            stickerId
            stickerResourceType
            url
          }
          receivedImage{
            id
            type
            url
          }
          receivedFile{
            id
            type
            fileName
            fileSize
            url
          }
          receivedLocation{
            id
            type
            title
            address
            latitude
            longitude
          }
          receivedVideo{
            id
            type
            url
          }
          receivedAudio{
            id
            type
            url
            duration
          }
          receivedFacebookText {
            mid
            text
          }
          receivedFacebookMessageWithAttachment {
            mid
            attachments {
              type
              payload {
                url
                filename
              }
            }
          }
          createdAt
        }
        createdAt
        updatedAt
      }
      success
      message
    }
  }
`

export const VARIANT_INFO_FOR_QUOTATION = gql`
  query variantInfo(
    $id: String!
    $sellerId: Int!
  ) {
    variantInfo(id: $id, sellerId: $sellerId) {
      data{
        id
        type
        variantName
        salesPrice
        file {
          id
          url
        }
        quantity
        commission
        inventoryOption{
          id
          totalStock
          soldStock
          avgCost
        }
      }
      success
      message
    }
  }
`

export const VARIANT_LIST_FOR_QUOTATION = gql`
  query variantList(
    $limit: Int
    $offset: Int
    $customerId: Int
    $search: String
  ) {
    variantList(input:{
      limit: $limit
      offset: $offset
      customerId: $customerId
      search: $search
    }) {
      data{
        id
        type
        variantName
        salesPrice
        file {
          id
          url
        }
      }
      success
      message
    }
  }
`

export const VARIANT_LIST = gql`
  query variantList(
    $limit: Int
    $offset: Int
    $customerId: Int
    $search: String
  ) {
    variantList(input:{
      limit: $limit
      offset: $offset
      customerId: $customerId
      search: $search
    }) {
      data{
        id
        type
        variantName
      }
      success
      message
    }
  }
`

export const VARIANT_INFO = gql`
  query variantInfo(
    $id: String!
  ) {
    variantInfo(id: $id) {
      data{
        id
        type
        variantName
        name
        quantity
        middlePrice
        inventoryOption{
          id
          totalStock
          soldStock
        }
      }
      success
      message
    }
  }
`

export const QUOTATION_LIST_IN_CHAT = gql`
  query quotationList (
    $refId: String
    $paymentType: filterQuotationPaymentType
    $status: filterQuotationStatus
    $receiveType: filterQuotationReceiveType
    $deliveryDate: [Date]
    $createdAt: [Date]
    $customer: CustomerFilterInQuotationList
    $itemName: String
    $search: String
    $limit: Int
    $offset: Int
    $customerId: Int
    $channelId: Int
    $sortBy: QuotationSortBy
    $providerIds: [Int]
  ) {
    quotationList (
      input: {
        refId: $refId
        paymentType: $paymentType
        status: $status
        receiveType: $receiveType
        deliveryDate: $deliveryDate
        createdAt: $createdAt
        customer: $customer
        itemName: $itemName
        search: $search
        limit: $limit
        offset: $offset
        customerId: $customerId
        channelId: $channelId
        sortBy: $sortBy
        providerIds: $providerIds
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          refId
          totalPrice
          createdAt
          status
          isActive
          #isExpired
          channelId
          channelType
          editCount
          customer {
            name
          }
          quotationLots {
            id
            quotationItems {
              id
              discount
              quantity
              totalPrice
              isFree
            }
          }
          upsellStatus
        }
      }
    }
  }
`

export const QUOTATION_DETAIL = gql`
  query quotationInfo(
    $id: Int!
  ) {
    quotationInfo(
      id: $id
    ) {
      data{
        id
        createdAt
        refId
        status
        paymentType
        upsellStatus
        totalPrice
        totalBonusSale
        deliveryChannel
        chatProvider{
          id
          name
        }
        chatOwner {
          id
          firstName
          lastName
        }
        customerId
        channelId
        isActive
        #isExpired
        qrCodeUrl
        channelType
        transferAmount
        transferDate
        transferTime
        isCOD
        chatChannel {
          id
          chatProvider{
            id
            name
          }
          owner {
            id
            firstName
            lastName
          }
        }
        createdByUser {
          id
          firstName
          lastName
        }
        seller {
          id
          firstName
          lastName
          roles {
            id
          }
        }
        quotationPayments {
          transactionId
          url
          date
          time
          amount
          bankCode
          bankName
          bankFullName
          bankAccount
          finnicBankCode
          finnicBankName
          finnicBankFullName
          createdAt
          updatedAt
        }
        quotationLots{
          id
          isTax
          deliveryDate
          deliveryCost
          receiveType
          deliveryChannel {
            id
            name
            #trackingNumber
          }
          quotationItems{
            id
            quantity
            discount
            price
            totalPrice
            isFree
            cost
            commission
            seller {
              id
              firstName
              lastName
            }
            packId
            pack{
              id
              name
              variantName
              inventoryOption{
                id
                files {
                  id
                  url
                }
                inventory{
                  id
                  sku
                  productName
                  brand{
                    id
                    name
                  }
                }
              }
            }
            setId
            set {
              id
              name
              sku
              file {
                id
                url
              }
              ProductSetPricingTypes {
                id
                salesPrice
              }
            }
          }
          lotDeliveryAddress{
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactPhoneNumber
          }
          billingAddressId
          lotBillingAddress {
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactPhoneNumber
          }
        }
        contactAddress{
          id
          contactName
        }
        customer{
          id
          name
        }
        finnicBank{
          id
          bankAccountName
          bankAccountNumber
          bank{
            id
            code
          }
        }
        files{
          id
          url
        }
      }
      message
      success
    }
  }
`

export const COMMENT_LIST = gql`
  query commentList (
    $searchMessages: String
    $limit: Int
    $offset: Int
    $isRead: isReadType
    $name: String
  ) {
    commentList (
      input: {
        searchMessages: $searchMessages
        limit: $limit
        offset: $offset
        isRead: $isRead
        name: $name
      }
    ) {
      success
      message
      data {
        list{
          id
          createTime
          from{
            id
            name
            uid
            profileUrl
          }
          messages {
            id
            refId
            message
            type
            isTop
            createTime
            url
            createdAt
            updatedAt
          }
          totalUnread
          refId
        }
        total
      }
    }
  }
`

export const COMMENT_INFO = gql`
  query commentInfo (
    $commentChannelId: Int
    $offset: Int
    $limit: Int
    $commentId: Int
  ) {
    commentInfo(
      commentChannelId: $commentChannelId
      offset: $offset
      limit: $limit
      commentId: $commentId
    ) {
      success
      message
      data {
        id
        comments{
          id
          refId
          message
          type
          createTime
          isTop
          url
          messageResponseType
          createdAt
          updatedAt
        }
        from{
          id
          name
          uid
          profileUrl
        }
        post{
          id
          refId
          pageName
          createTime
          message
          createdAt
          updatedAt
        }
        totalComments
        totalUnread
        createdAt
        updatedAt
      }
    }
  }
`

export const CHAT_LIST = gql`
  query chatChannelList (
    $name: String
    $searchMessages: String
    $limit: Int
    $offset: Int
    $isRead: isReadType
    $chatgroupIds: [Int]
    $providerIds: [Int]
    $ownerIds: [Int]
    $tagIds: [Int]
    $upsellIds: [Int]
    $startDate: Date
    $endDate: Date
    $firstMessageStartDate: Date
    $firstMessageEndDate: Date
    $quotationCreatedStartDate: Date
    $quotationCreatedEndDate: Date
  ) {
    chatChannelList (
      input: {
        name: $name
        searchMessages: $searchMessages
        limit: $limit
        offset: $offset
        chatgroupIds: $chatgroupIds
        isRead: $isRead
        providerIds: $providerIds
        ownerIds: $ownerIds
        tagIds: $tagIds
        upsellIds: $upsellIds
        startDate: $startDate
        endDate: $endDate
        firstMessageStartDate: $firstMessageStartDate
        firstMessageEndDate: $firstMessageEndDate
        quotationCreatedStartDate: $quotationCreatedStartDate
        quotationCreatedEndDate: $quotationCreatedEndDate
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          providerId
          chatContactId
          reminderDate
          owner {
            id
            firstName
            lastName
          }
          quotations {
            id
            salesOrder {
              id
              lots {
                id
                items {
                  id
                  isUpsell
                  seller {
                    id
                    firstName
                    lastName
                  }
                  createdAt
                }
              }
            }
          }
          chatContact {
            id
            displayName
            profileUrl
            customerId
            customer {
              id
              name
            }
            address {
              id
              contactName
            }
          }
          chatProvider{
            id
            name
            shortName
            code
          }
          chatGroupStatus {
            id
            name
          }
          chatMessages {
            id
            messageType
            createdAt
            sendedMessage {
              type
              text
            }
            receivedImage {
              id
              type
              url
            }
            sendedFlexMessage {
              refId
            }
            receivedMessage {
              id
              type
              text
            }
            receivedSticker {
              id
              type
            }
            receivedVideo{
              id
              type
              url
            }
            receivedAudio{
              id
              type
              url
              duration
            }
            receivedFacebookText {
              mid
              text
            }
            receivedFacebookMessageWithAttachment {
              mid
              attachments {
                type
                payload {
                  url
                  filename
                }
              }
            }
          }
          totalUnread
          updatedAt
        }
      }
    }
  }
`

export const INVENTORY_LIST = gql`
  query inventoryList (
    $limit: Int
    $offset: Int
    $search: String
    $productType: ProductType!
  ) {
    inventoryList (
      input: {
        limit: $limit
        offset: $offset
        search: $search
        productType: $productType
      }
    ) {
      success
      message
      data {
        total
        inventoryList {
          id
          sku
          productName
          brand {
            id
            name
          }
          itemPricingTypes {
            id
            salesPrice
            pricingType {
              id
              code
              name
            }
          }
          inventoryOption {
            id
            totalStock
            soldStock
          }
        }
        productSetList {
          id
          sku
          name
          availableStock
          subtotalAfterDiscount
          ProductSetPricingTypes{
            id
            salesPrice
            pricingType {
              id
              code
              name
            }
          }
        }
      }
    }
  }
`

export const CHAT_GROUP_LIST = gql`
  query chatGroupStatusList (
    $providerId: Int
  ) {
    chatGroupStatusList (
      providerId: $providerId
    ) {
      success
      message
      data {
        total
        list {
          id
          name
          total
        }
      }
    }
  }
`

export const CHAT_STATUS = gql`
  query chatReadStatus (
    $chatgroupId: Int
    $providerId: Int
  ) {
    chatReadStatus (
      input: {
        chatgroupId: $chatgroupId
        providerId: $providerId
      }
    ) {
      success
      message
      data {
        name
        isReadType
        total
      }
    }
  }
`

export const ROLE_LIST = gql`
  query roleList {
    roleList {
      data{
        list {
          name
          id
          description
          countRole
        }
        total
      }
    }
  }
`
export const QUOTATION_STATUS_COUNT = gql`
  query quotationStatusListWithCount (
    $customerId: Int
  ) {
    quotationStatusListWithCount (
      input: {
        customerId: $customerId
      }
    ) {
      success
      message
      data {
        text
        value
        total
      }
    }
  }
`

export const USER_INFO = gql`
  query userDetail(
    $id: Int!
  ) {
    userDetail(id: $id) {
      data {
        id
        firstName
        lastName
        email
        status
        roles {
          id
          name
          description
          permissions { 
            id
            name
            description
          }
        }
        warehouse {
          id
          warehouseName
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const USER_LIST = gql`
  query userList(
    $limit: Int
    $offset: Int
    $name: String
    $status: Status
    $department: String
    $orderField: UserOrderField
    $orderBy: OrderBy
  ) {
    userList(
      input: {
        limit: $limit
        offset: $offset
        name: $name
        status: $status
        department: $department
        orderField: $orderField
        orderBy: $orderBy
      }) {
        data {
          list {
            id
            firstName
            lastName
            email
            status
            roles {
              id
              name
              description
              permissions { 
                id
                name
                description
              }
            }
            warehouse {
              id
              warehouseName
            }
            createdAt
            updatedAt
          }
          total
        }
      }
    }
`
export const GET_ROLES = gql`
  query rolePermissionList {
    rolePermissionList {
      data {
        moduleName
        roleList {
          role {
            id
            name
            description
          }
          permissionList {
            id
            name
            description
            status
          }
        }
      }
    }
  }
`

export const PROVIDER_LIST = gql`
  query providerList(
    $isCrm: Boolean
    $isQuotation: Boolean
  ) {
    providerList(
      isCRM: $isCrm
      isQuotation: $isQuotation
    ) {
      success
      message
      data {
        list {
          id
          name
          shortName
        }
      }
    }
  }
`

export const POSSIBLE_PROVIDER = gql`
  query possibleProvider (
    $id: Int!
  ) {
    possibleProvider (
      id: $id
    ) {
      success
      message
      data {
        providerId
        name
        chatChannelId
      }
    }
  }
`

export const BANK_LIST = gql`
  query bankList {
    bankList {
      success
      message
      data {
        total
        list {
          id
          code
          name
          bankCode
        }
      }
    }
  }
`

export const POSSIBLE_PROVIDER_FOR_CUSTOMER = gql`
  query possibleProviderForCustomer($id: Int!) {
    possibleProviderForCustomer(id: $id) {
      data {
        providerId
        name
        chatChannelId
      }
    }
  }
`

export const PURCHASE_HISTORY = gql`
  query purchaseHistory($input: PurchaseHistoryInput!) {
    purchaseHistory(input: $input) {
      data {
        total
        list {
          id
          refId
          createdAt
          quotationLots {
            id
            quotationItems {
              id
              quantity
              totalPrice
              price
              discount
              pack {
                id
                variantName
                inventoryOption {
                  files {
                    id
                    url
                  }
                }
              }
              set {
                id
                name
                file {
                  id
                  url
                }
              }
              isFree
            }
          }
          totalPrice
        }
      }
    }
  }
`

export const TAG_LIST = gql`
  query tagList($input: TagListInput) {
    tagList(input: $input) {
      data {
        total
        list {
          id
          name
        }
      }
    }
  }
`

export const ORDER_BONUS_BY_ROLE = gql`
  query orderBonusByRole($roleId: Int!) {
    orderBonusByRole(roleId: $roleId) {
      data {
        id
        tierPrice
        bonus
      }
    }
  }
`

export const DEFAULT_DELIVERY_CHANNEL = gql`
  query defaultDeliveryChannel {
    defaultDeliveryChannel {
      data {
        id
        deliveryChannel
        isSet
      }
    }
  }
`
