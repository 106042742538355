import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login(
    $client_id: String!
    $client_secret: String!
    $grant_type: GrantType!
    $username: String!
    $password: String!
  ) {
    login(input: {
      client_id: $client_id
      client_secret: $client_secret
      grant_type: $grant_type
      username: $username
      password: $password
    }) {
      createdAccessToken {
        access_token
        refresh_token
      }
      success
      message
    }
  }
`

export const REQ_RESET_PASSWORD_TOKEN = gql`
  mutation forgetPassword(
    $email: String!
  ) {
    forgetPassword(input: {
      email: $email
    }) {
      createdForgetPassword {
        resetPasswordToken
      }
      success
      message
    }
  }
`

export const UPDATE_READ_MSG = gql`
  mutation updateReadMessage(
    $channelId: Int!
  ) {
    updateReadMessage (input: {
      channelId: $channelId
    }) {
      success
      message
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $paymentType: PaymentTypes!
    $pricingTypeId: Int!
    $type: CustomerType!
    $prefix: String
    $name: String!
    $taxId: String
    $isVat: Boolean!
    $contactName: String
    $contactPhoneNumber: [ContactPhoneNumberInput]
    $contactFaxNumber: String
    $contactEmail: [ContactEmailInput]
    $contactRemark: String
    $creditLimit: Float
    $creditUsage: Float
    $creditTerm: Int
    $billingAddress: [BillingAddressInput]
    $contactAddress: [ContactAddressInput]
    $shippingAddress: [ShippingAddressInput]
    $gender: String
    $birthDate: Date
    $idCardNumber: String
  ) {
    createCustomer(input: {
      paymentType: $paymentType
      pricingTypeId: $pricingTypeId
      prefix: $prefix
      name: $name
      type: $type
      taxId: $taxId
      isVat: $isVat
      contactName: $contactName
      contactPhoneNumber: $contactPhoneNumber
      contactFaxNumber: $contactFaxNumber
      contactEmail: $contactEmail
      contactRemark: $contactRemark
      creditLimit: $creditLimit
      creditUsage: $creditUsage
      creditTerm: $creditTerm
      billingAddress: $billingAddress
      contactAddress: $contactAddress
      shippingAddress: $shippingAddress
      gender: $gender
      birthDate: $birthDate
      idCardNumber: $idCardNumber
    }) {
      success
      message
    }
  }
`

export const REPLY_MESSAGE = gql`
  mutation replyMessage(
    $text: String!
    $files: [Upload]
    $audios: [Upload]
    $duration: Int
    $channelId: Int!
    $isWithTag: Boolean
  ) {
    replyMessage(input:{
      text: $text
      files: $files
      audios: $audios
      duration: $duration
      channelId: $channelId
      isWithTag: $isWithTag
    }) {
      success
      message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $token: String!
    $newPassword: String!
  ) {
    resetPassword(input: {
      token: $token
      newPassword: $newPassword
    }) {
      success
      message
    }
  }
`

export const QUOTATION_UPDATE = gql`
  mutation updateQuotation (
    $id: Int!
    $finnicBankId: Int
    $files: [Upload]
    $transferDate: Date
    $transferTime: Time
    $quotationLots: [LotUpdateQuotationInput]
    $amount: Float
  ) {
    updateQuotation (
      input: {
        id: $id
        finnicBankId: $finnicBankId
        files: $files
        quotationLots: $quotationLots
        transferDate: $transferDate
        transferTime: $transferTime
        amount: $amount
      }
    ) {
      success
      message
      data {
        id
        qrCodeUrl
        transferAmount
      }
    }
  }
`

export const APPROVE_PAYMENT = gql`
  mutation approvePayment(
    $id: Int!
    $isApprove: Boolean!
  ) {
    approvePayment (input:{
      id: $id
      isApprove: $isApprove
    }) {
      success
      message
      data {
        id
        status
      }
    }
  }
`

export const CREATE_QUOTATION = gql`
  mutation createQuotation(
    $paymentType: quotationPaymentType!
    $customerId: Int
    $lots: [CreateQuotationLotInput]
    $files: [Upload]
    $contactAddressId: Int
    $chatProviderId: Int
    $channelId: Int
    $channelType: ChannelType
    $transferType: TransferType
    $transferAmount: Float
    $isCOD: Boolean
    $sellerId: Int
    $deliveryChannel: DeliveryChannelEnum!
  ) {
    createQuotation(input:{
      paymentType: $paymentType
      customerId: $customerId
      isCOD: $isCOD
      lots: $lots
      files: $files
      contactAddressId: $contactAddressId
      channelId: $channelId
      chatProviderId: $chatProviderId
      channelType: $channelType
      transferType: $transferType
      transferAmount: $transferAmount
      sellerId: $sellerId
      deliveryChannel: $deliveryChannel
    }) {
      success
      message
    }
  }
`

export const MULTI_CAST_MSG = gql`
  mutation multicastMessage (
    $chatgroupId: [Int]
    $providerId: Int
    $isAll: Boolean!
    $isMyChat: Boolean!
    $text: String
    $files: [Upload]
    $tagIds: [Int]
    $isConfirm: Boolean
    $messageTag: MessageTag
  ) {
    multicastMessage (input: {
      chatgroupId: $chatgroupId
      providerId: $providerId
      isAll: $isAll
      isMyChat: $isMyChat
      text: $text
      files: $files
      tagIds: $tagIds
      isConfirm: $isConfirm
      messageTag: $messageTag
    }) {
      success
      message
      data {
        id
        chatContactId
      }
    }
  }
`

export const CHANGE_CHAT_GROUP = gql`
  mutation setChatChannelStatus (
    $channelId: Int!
    $chatgroupId: Int!
  ) {
    setChatChannelStatus (
      input: {
        channelId: $channelId
        chatgroupId: $chatgroupId
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation updateRole (
    $id: Int!
    $description: String
  ) {
    updateRole (
      id: $id
      input: {
        description: $description
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_ROLE_PERMISSION = gql`
  mutation updateRolePermission (
    $roleId: Int!
    $permissionId: Int!
  ) {
    updateRolePermission (
      input: {
        roleId: $roleId
        permissionId: $permissionId
      }
    ) {
      success
      message
    }
  }
`

export const SET_CUSTOMER = gql`
  mutation setContactCustomer (
    $id: Int!
    $addressId: Int
    $customerId: Int
    $newAddress: CreateAddressInput
    $contactLineId: String
  ) {
    setContactCustomer (
      input: {
        id: $id
        addressId: $addressId
        customerId: $customerId
        newAddress: $newAddress
        contactLineId: $contactLineId
      }
    ) {
      success
      message
    }
  }
`

export const SEND_QUOTATION = gql`
  mutation replyFlexMessage (
    $providerId: Int
    $channelId: Int
    $quotationId: Int!
  ) {
    replyFlexMessage (
      input: {
        providerId: $providerId
        channelId: $channelId
        quotationId: $quotationId
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $password: String!
    $email: String!
    $roles: [Int]!
    $warehouseId: Int
  ) {
    createUser(input: {
      firstName: $firstName
      lastName: $lastName
      password: $password
      email: $email
      roles: $roles
      warehouseId: $warehouseId
    })  {
      success
      message
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $roles: [Int]
    $status: Status
    $warehouseId: Int
  ) {
    updateUser(
      id: $id
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        roles: $roles
        status: $status
        warehouseId: $warehouseId
    }) {
      success
      message
    }
  }
`

export const GENERATE_TOKEN = gql`
  mutation generateOneTimeToken (
    $type: TokenType
  ) {
    generateOneTimeToken (
      input: {
        type: $type
      }
    ) {
      data{
        token
      }
      success
      message
    }
  }
`

export const ACTIVE_QUOTATION = gql`
  mutation activeQuotation(
    $id: Int!
    $isActive: Boolean!
  ) {
    activeQuotation (input: {
      id: $id
      isActive: $isActive
    }) {
      success
      message
    }
  }
`

export const DUPLICATE_QUOTATION = gql`
  mutation duplicateQuotation (
    $id: Int
  ) {
    duplicateQuotation (
      id: $id
    ) {
      success
      message
      data {
        id
      }
    }
  }
`

export const VERIFY_SLIP = gql`
  mutation verifySlip (
    $transactionId: String
    $quotationId: Int!
    $bankCode: String!
    $attachment: Upload
  ) {
    verifySlip (
      input: {
        transactionId: $transactionId
        quotationId: $quotationId
        bankCode: $bankCode
        attachment: $attachment
      }
    ) {
      success
      message
      data {
        totalPayment
        totalAmount
        currentAmount
        depositAmount
      }
    }
  }
`

export const REPLY_COMMENT = gql`
  mutation comment (
    $commentId: String!
    $message: String
    $file: Upload
  ) {
    comment (
      input: {
        commentId: $commentId
        message: $message
        file: $file
      }
    ) {
      success
      message
      data {
        id
        refId
        message
        type
        isTop
        createTime
        createdAt
        updatedAt
      }
    }
  }
`

export const CONFIRM_PAYMENT = gql`
  mutation ConfirmPayment($quotationId: Int!) {
  confirmPayment(quotationId: $quotationId) {
    message
    success
    }
  }
`
// export const CONFIRM_PAYMENT = gql`
//   mutation confirmPayment (
//     $quotationId: Int!
//   ) {
//     confirmPayment (
//       quotationId: $quotationId
//     ) {
//       success
//       message
//     }
//   }
// `

export const UPDATE_UPSELL_STATUS = gql`
  mutation updateUpsellStatus(
    $quotationId: Int!,
    $status: UpsellStatus!
  ) {
    updateUpsellStatus(
      quotationId: $quotationId,
      status: $status
    ) {
      success
      message
    }
  }
`

export const SET_CHAT_CHANNEL_OWNER = gql`
  mutation setChatChannelOwner($input: SetChatChannelOwnerInput!) {
    setChatChannelOwner(input: $input) {
      success
      message
    }
  }
`

export const SET_UNREAD = gql`
  mutation setUnread($id: Int!) {
    setUnread(id: $id) {
      success
      message
    }
  }
`

export const EDIT_QUOTATION = gql`
  mutation editQuotation($input: EditQuotationInput) {
    editQuotation(input: $input) {
      success
      message
    }
  }
`

export const CREATE_REMINDER = gql`
  mutation createReminder($input: CreateReminderInput!) {
    createReminder(input: $input) {
      success
      message
    }
  }
`

export const DELETE_REMINDER = gql`
  mutation deleteReminder($chatChannelId: Int!) {
    deleteReminder(chatChannelId: $chatChannelId) {
      success
      message
    }
  }
`

export const IDLE_ADMIN = gql`
  mutation idleAdmin {
    idleAdmin {
      success
      message
    }
  }
`

export const CREATE_ALL_REMINDER = gql`
  mutation createAllReminder($input: CreateAllReminderInput!) {
    createAllReminder(input: $input) {
      success
      message
    }
  }
`
