import gql from 'graphql-tag'

export const CHAT_DETAIL_SUBSCRIPTION = gql`
  subscription chatChannelInfoSub (
    $channelId: Int
  ) {
    chatChannelInfoSub (
      channelId: $channelId
    ) {
      data{
        id
        reminderDate
        reminder
        reminderUser {
          id
          firstName
          lastName
        }
        providerId
        chatContactId
        chatgroupId
        firstReader{
          id
          firstName
          lastName
        }
        chatProvider{
          id
          name
        }
        owner {
          id
          firstName
          lastName
        }
        chatContact{
          id
          customerId
          uid
          displayName
          profileUrl
          customer{
            id
            contactName
          }
          address{
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactPhoneNumber
            contactOfficeTel
          }
        }
        chatGroupStatus{
          id
          name
        }
        chatMessages{
          id
          chatContactId
          userId
          user{
            id
            firstName
            lastName
          }
          messageType
          messageResponseType
          isRead
          sendedMessage{
            type
            text
            url
          }
          sendedFlexMessage{
            refId
            status
            totalPrice
            quantity
            uri
            isActive
            imageUrl
            paymentType
            type
            isNewVersion
            deliveryCost
            itemList {
              itemName
              totalPrice
            }
          }
          receivedMessage{
            id
            type
            text
          }
          receivedSticker{
            id
            type
            packageId
            stickerId
            stickerResourceType
            url
          }
          receivedImage{
            id
            type
            url
          }
          receivedFile{
            id
            type
            fileName
            fileSize
            url
          }
          receivedLocation{
            id
            type
            title
            address
            latitude
            longitude
          }
          receivedVideo{
            id
            type
            url
          }
          receivedAudio{
            id
            type
            url
            duration
          }
          receivedFacebookText {
            mid
            text
          }
          receivedFacebookMessageWithAttachment {
            mid
            attachments {
              type
              payload {
                url
                filename
              }
            }
          }
          createdAt
        }
        createdAt
        updatedAt
      }
      success
      message
    }
  }
`

export const CHAT_CHANGE = gql`
  subscription chatChannelChanged {
    chatChannelChanged {
      success
      message
      type
      data {
        id
        providerId
        chatContactId
        owner {
          id
          firstName
          lastName
        }
        chatContact {
          id
          displayName
          profileUrl
          customerId
          customer {
            id
          }
          address {
            id
            contactName
          }
        }
        chatProvider{
          id
          name
          shortName
        }
        chatGroupStatus {
          id
          name
        }
        chatMessages {
          id
          messageType
          createdAt
          sendedMessage {
            type
            text
          }
          receivedImage {
            id
            type
            url
          }
          sendedFlexMessage {
            refId
          }
          receivedMessage {
            id
            type
            text
          }
          receivedSticker {
            id
            type
          }
          receivedVideo{
            id
            type
            url
          }
          receivedAudio{
            id
            type
            url
            duration
          }
          receivedFacebookText {
            mid
            text
          }
          receivedFacebookMessageWithAttachment {
            mid
            attachments {
              type
              payload {
                url
                filename
              }
            }
          }
        }
        reminderDate
        totalUnread
        updatedAt
      }
    }
  }
`
