import { useState, useEffect } from 'react'
import { startRecording, saveRecording } from './recorder-controls'

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
  safariRecorder: null,
}

export default function useRecorder() {
  const [recorderState, setRecorderState] = useState(initialState)

  useEffect(() => {
    const MAX_RECORDER_TIME = 5
    let recordingInterval = null

    if (recorderState.initRecording) {
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => {
          if (prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0) {
            clearInterval(recordingInterval)
            return prevState
          }

          if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59) {
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            }
          }

          if (prevState.recordingSeconds === 59) {
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            }
          }
          return prevState
        })
      }, 1000)
    } else clearInterval(recordingInterval)

    return () => clearInterval(recordingInterval)
  })

  const isChromeMobile = window.navigator.userAgent.match(/CriOS/i)
  const isSafariIos = !!(window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i)) && !!window.navigator.userAgent.match(/WebKit/i) && !window.navigator.userAgent.match(/CriOS/i)
  const isSafari = isSafariIos || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)) //eslint-disable-line

  const recordMimeType = (isChromeMobile || isSafariIos) ? 'audio/mp4' : isSafari ? 'audio/mp3' : 'audio/webm;codecs=opus'

  useEffect(() => {
    if (recorderState.mediaStream) {
      setRecorderState((prevState) => (
        {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream, { mimeType: recordMimeType, audioBitsPerSecond: 128000 }), //eslint-disable-line
        }
      ))
    }
  }, [recorderState.mediaStream])

  useEffect(() => {
    const recorder = recorderState.mediaRecorder
    if (recorder) {
      let chunks = []

      if (recorder && recorder.state === 'inactive') {
        recorder.start()

        recorder.ondataavailable = (e) => {
          chunks.push(e.data)
        }

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: recordMimeType }) //eslint-disable-line
          chunks = []
          setRecorderState((prevState) => {
            if (prevState.mediaRecorder) {
              return {
                ...initialState,
                recordingMinutes: prevState?.recordingMinutes,
                recordingSeconds: prevState?.recordingSeconds,
                originalBlobFile: blob,
                audio: window.URL.createObjectURL(blob),
              }
            }
            return initialState
          })
        }
      }
    }
    return () => {
      if (recorder) recorder.stream.getAudioTracks().forEach((track) => track.stop())
    }
  }, [recorderState.mediaRecorder])

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
  }
}
