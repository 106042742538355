import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Button, ChatDayBreaker, Div, Icon, Input, Label, P, RecieveMessage, RowSelect, SendMessage, SmallText, TextArea,
  ExpandWithChoice, FullPageLoading } from '@konsys-ui-custom'
import {
  Checkbox,
  Modal,
  message,
} from 'antd'
import { DatePicker } from 'antd4'
import dayjs from 'dayjs'
import _, {
  compact, filter, find, findIndex, includes, isEmpty, isNil, isNull,
  map, omit, sortBy, uniq, uniqBy,
} from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import NavigateContainer from '../../components/navigate'
import {
  CREATE_ALL_REMINDER,
  CREATE_REMINDER, SET_CHAT_CHANNEL_OWNER, SET_CUSTOMER,
} from '../../constants/graph/mutation'
import {
  CHAT_GROUP_LIST, CUSTOMER_LIST_IN_CHAT, PURCHASE_HISTORY,
  TAG_LIST,
  USER_LIST,
} from '../../constants/graph/query'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import { getLocalStorage, getTokenData, getURL, saveLocalStorage } from '../../utils/local-data-service'
import {
  compressImageFile,
  delayInput, showMessageMutation,
} from '../../utils/util-services'
import { QuatationForm } from '../quotation/form'
import UserQuotation from '../user-quotation'
import {
  ChatInputWrapper,
  IconUploadContainer,
  IconUploadWrapper,
  PreviewWrapper,
  Select,
} from './styled'
import useRecorder from './useRecorder'

const isSafariIos = !!(window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i)) && !!window.navigator.userAgent.match(/WebKit/i) && !window.navigator.userAgent.match(/CriOS/i)

const RecordMobileInput = ({ initRecording, onStopRecording }) => {
  const defaultTime = {
    recordingMinutes: 0,
    recordingSeconds: 0,
  }
  const [time, setTime] = useState(defaultTime)

  useEffect(() => {
    let timer = null
    const MAX_RECORDER_TIME = 5
    if (initRecording) {
      timer = setInterval(() => {
        setTime((prevState) => {
          if (prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0) {
            clearInterval(timer)
            return prevState
          }

          if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59) {
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            }
          }

          if (prevState.recordingSeconds === 59) {
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            }
          }

          return prevState
        })
      }, 1000)
    } else {
      setTime(defaultTime)
    }
    return () => {
      clearInterval(timer)
    }
  }, [initRecording])

  const callOnStopRecording = () => onStopRecording(time?.recordingMinutes, time?.recordingSeconds)

  if (!initRecording) return null
  return <Div display='flex' alignItems='center' position='absolute' moreStyle={{
    top: 0, left: '8px', bottom: 0, zIndex: 1,
  }}>
    <Div display='inline-flex' alignItems='center'>
      <span className={initRecording ? 'animation-flashing' : ''} style={{
        width: '6px', height: '6px', backgroundColor: theme?.color?.error, borderRadius: '50%',
      }}></span>
      <SmallText margin='0 0 0 4px'>{time?.recordingMinutes < 10 ? `0${time?.recordingMinutes}` : time?.recordingMinutes}:{time?.recordingSeconds < 10 ? `0${time?.recordingSeconds}` : time?.recordingSeconds}</SmallText>
    </Div>
    <Icon id='stop-recording-el' icon='fas fa-stop-circle' fontSize={theme.fonts.size.subTitle} margin='2px 0 0 16px' color={initRecording ? theme?.color?.warning : theme?.color?.gray10} style={{ cursor: initRecording ? 'pointer' : 'not-allowed' }} onClick={callOnStopRecording} />
  </Div>
}

const ChatInput = ({
  loading, doReply, clear, isCommentChannel,
}) => {
  const [compressingImage, setCompressingImage] = useState(false)
  const {
    recorderState, startRecording, saveRecording, cancelRecording,
  } = useRecorder()
  const [mobileRecorderData, setMobileRecorderData] = useState()
  const [mutationVariables, setMutationVariables] = useState({
    text: '',
    fileList: [],
  })
  const { text, fileList } = mutationVariables

  useEffect(() => {
    if (clear) {
      const isFocusInput = !isEmpty(mutationVariables?.text)
      setMutationVariables({
        text: '',
        fileList: [],
      })
      if (isFocusInput) document.getElementById('reply-input').focus()
    }
  }, [clear])

  useEffect(() => {
    const inputFileElement = document.getElementById('upload-file')
    if (_.isEmpty(mutationVariables.fileList) && inputFileElement) {
      inputFileElement.value = ''
    }
  }, [mutationVariables.fileList])

  useEffect(() => {
    setMutationVariables({
      ...mutationVariables,
      audio: recorderState?.originalBlobFile,
    })
  }, [recorderState?.originalBlobFile])

  useEffect(() => {
    if (mobileRecorderData?.initRecording) startRecording()
  }, [mobileRecorderData?.initRecording])

  const reply = () => {
    setCompressingImage(true)
    const promises = mutationVariables?.fileList?.map((file) => compressImageFile(file)?.then((resolveFile) => resolveFile))
    Promise.all(promises).then((results) => {
      cancelRecording()
      setCompressingImage(false)
      doReply({
        ...mutationVariables,
        fileList: results,
        duration: ((!isNil(recorderState?.recordingMinutes) ? recorderState?.recordingMinutes : mobileRecorderData?.minute) * 60000) + ((!isNil(recorderState?.recordingSeconds) ? recorderState?.recordingSeconds : mobileRecorderData?.second) * 1000),
      })
    })
  }

  const isChromeMobile = window.navigator.userAgent.match(/CriOS/i)
  const isMobile = isSafariIos || isChromeMobile

  const callStartRecording = () => {
    if (isMobile) {
      setMobileRecorderData({ initRecording: true })
    } else {
      startRecording()
    }
  }

  const callSaveRecording = () => {
    if (!isMobile) {
      saveRecording()
    }
  }

  const disabledUploadImage = isCommentChannel && !_.isEmpty(mutationVariables.fileList)
  const isUseRecord = recorderState?.initRecording || !_.isEmpty(recorderState?.audio) || mobileRecorderData?.initRecording

  const recordAudio = new Audio(recorderState?.audio) //eslint-disable-line

  return (
    <Div moreStyle={{
      borderTop: `1px solid ${theme.color.gray40}`,
    }}>
      {
        !_.isEmpty(mutationVariables.fileList)
        && <Div bgColor={theme.color.gray10}>
          <SmallText display='block' padding='16px 40px 0' margin='0 0 8px'>รูปที่ต้องการส่ง</SmallText>
          <Div display='flex' padding='8px 40px 16px 40px' width='100%' moreStyle={{ overflow: 'auto' }}>
            {
              mutationVariables.fileList.map((v, i) => (
                <PreviewWrapper key={i} margin={i !== mutationVariables.fileList.length - 1 ? '0 16px 0 0' : '0'}>
                  <img src={URL.createObjectURL(v)} />
                  <Div>
                    <Icon icon='fal fa-trash-alt' color='white' cursor='pointer' onClick={() => {
                      const newList = [...mutationVariables.fileList]
                      _.remove(newList, (o, y) => y === i)
                      setMutationVariables({ ...mutationVariables, fileList: newList })
                    }} />
                  </Div>
                </PreviewWrapper>
              ))
            }
          </Div>
        </Div>
      }
      <ChatInputWrapper>
        <IconUploadContainer>
          <Input
            type='file'
            id='upload-file'
            multiple={!isCommentChannel}
            onChange={(e) => setMutationVariables({ ...mutationVariables, fileList: [...mutationVariables.fileList, ...e.target.files] })}
            accept="image/*"
            disabled={disabledUploadImage || isUseRecord}
          />
          <IconUploadWrapper htmlFor={!_.isEmpty(text) || loading ? '' : 'upload-file'}>
            <Icon icon='fal fa-images' color={!_.isEmpty(text) || loading || disabledUploadImage || isUseRecord ? theme.color.gray30 : theme.color.gray40} fontSize={theme.fonts.size.normal} style={{ cursor: !_.isEmpty(text) || loading || disabledUploadImage || isUseRecord ? 'not-allowed' : 'pointer', transition: 'all 0.3s ease' }} />
          </IconUploadWrapper>
        </IconUploadContainer>
        <Icon icon='fal fa-microphone' color={!_.isEmpty(text) || loading || !_.isEmpty(fileList) ? theme.color.gray30 : theme.color.gray40} fontSize={theme.fonts.size.normal} style={{ cursor: !_.isEmpty(text) || loading || !_.isEmpty(fileList) ? 'not-allowed' : 'pointer', transition: 'all 0.3s ease' }} margin='0 0 0 16px' onClick={_.isEmpty(text) && !loading && _.isEmpty(fileList) && callStartRecording} />
        <Div margin='0 0 0 16px' position='relative' moreStyle={{ flexGrow: 1 }}>
          <RecordMobileInput
            initRecording={mobileRecorderData?.initRecording}
            onStopRecording={(minute, second) => {
              setMobileRecorderData({
                ...mobileRecorderData, initRecording: false, minute, second,
              })
            }}
          />
          {
            isUseRecord
            && <Div display='flex' alignItems='center' position='absolute' moreStyle={{
              top: 0, left: '8px', bottom: 0, zIndex: 1,
            }}>
              {
                recorderState?.initRecording
                && <>
                  <Div display='inline-flex' alignItems='center'>
                    <span className={recorderState?.initRecording ? 'animation-flashing' : ''} style={{
                      width: '6px', height: '6px', backgroundColor: theme?.color?.error, borderRadius: '50%',
                    }}></span>
                    <SmallText margin='0 0 0 4px'>{recorderState?.recordingMinutes < 10 ? `0${recorderState?.recordingMinutes}` : recorderState?.recordingMinutes}:{recorderState?.recordingSeconds < 10 ? `0${recorderState?.recordingSeconds}` : recorderState?.recordingSeconds}</SmallText>
                  </Div>
                  <Icon icon='fas fa-stop-circle' fontSize={theme.fonts.size.subTitle} margin='2px 0 0 16px' color={recorderState?.initRecording ? theme?.color?.warning : theme?.color?.gray10} style={{ cursor: recorderState?.initRecording ? 'pointer' : 'not-allowed' }} onClick={callSaveRecording} />
                </>
              }
              {
                !recorderState?.initRecording && recorderState?.audio
                && <Div display='flex' padding='6px 8px' alignItems='center' justifyContent='space-between' bgColor={theme?.color?.success} moreStyle={{ borderRadius: '20px' }}>
                  <Icon icon='fas fa-play' fontSize={theme.fonts.size.label} color={theme?.color?.offWhite} style={{ cursor: 'pointer' }} onClick={() => recordAudio.play()} />
                  <Icon icon='fal fa-times' margin='0 0 0 16px' color={theme?.color?.offWhite} style={{ cursor: 'pointer' }} onClick={cancelRecording} />
                </Div>
              }
            </Div>
          }
          <TextArea
            id='reply-input'
            onChange={(e) => setMutationVariables({ ...mutationVariables, text: e.target.value })}
            placeholder={isUseRecord ? '' : !_.isEmpty(fileList) ? 'ไม่สามารถพิมพ์ข้อความได้' : 'พิมพ์ข้อความตรงนี้'}
            style={{
              width: '100%', borderRadius: 0, border: 'none', lineHeight: '27px', fontFamily: theme?.fonts?.family?.normal, fontWeight: theme?.fonts?.style?.light, fontSize: theme?.fonts?.size?.normal, resize: 'none',
            }}
            disabled={!_.isEmpty(fileList) || loading || isUseRecord || compressingImage}
            value={mutationVariables.text}
            rows='1'
            padding='8px 56px 4px 8px'
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !(_.isEmpty(text) && _.isEmpty(fileList))) reply()
            }}
          />
          <span id='reply-button' style={{
            width: '48px', position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', textAlign: 'center',
          }} onClick={() => {
            if (!(_.isEmpty(text) && _.isEmpty(fileList) && _.isEmpty(recorderState?.audio)) && !loading && !compressingImage) reply()
          }}>
            <Icon
              icon={
                (loading || compressingImage)
                  ? 'fal fa-spinner-third fa-pulse'
                  : `${_.isEmpty(text) && _.isEmpty(fileList) && _.isEmpty(recorderState?.audio) ? 'fal' : 'fas'} fa-paper-plane`
              }
              color={(_.isEmpty(text) && _.isEmpty(fileList) && _.isEmpty(recorderState?.audio)) || (loading || compressingImage) || recorderState?.initRecording ? theme.color.gray30 : theme.color.tertiaryColor}
              fontSize={theme.fonts.size.normal}
              style={{ cursor: (_.isEmpty(text) && _.isEmpty(fileList) && _.isEmpty(recorderState?.audio)) || (loading || compressingImage) || recorderState?.initRecording ? 'not-allowed' : 'pointer', transition: 'all 0.3s ease' }}
            />
          </span>
        </Div>
      </ChatInputWrapper>
    </Div>
  )
}

export const ChatMessages = ({
  isCommentChannel,
  data,
  replyLoading,
  replayData,
  doReply,
  channelId,
  setImage,
  setModalIsOpen,
  doFetchMore,
  messages,
  messageId,
  isFetchMore,
  channelType,
  setOpenUserQuotaion,
}) => {
  let nextMsgDate
  let currentMsgDate
  const ContainerMessage = useRef()

  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const isFirefox = typeof InstallTrigger !== 'undefined'

  const chatDetailDetectScroll = (e) => {
    const containerHeight = ContainerMessage?.current?.scrollHeight - ContainerMessage?.current?.clientHeight
    if (Math.abs(e.target.scrollTop) >= containerHeight - 1) {
      doFetchMore()
    }
  }

  const resizeHandler = () => {
    const viewport = window.visualViewport
    setInnerHeight(viewport.height)
  }

  useEffect(() => {
    window.visualViewport.addEventListener('resize', resizeHandler)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(ContainerMessage) && !_.isEmpty(ContainerMessage.current) && !_.isUndefined(messageId) && messageId !== 0 && !isFetchMore) {
      const getElement = document.getElementById(`message-${messageId}`)
      if (!_.isEmpty(getElement)) {
        getElement.scrollIntoView()
      }
    }
  }, [messages, messageId])

  const isMobile = window.innerWidth <= 768

  useEffect(() => {
    if (isMobile) {
      document.body.style.cssText = 'overflow: hidden;'
    }
  })

  const reverseMessageList = messages.slice(0).reverse()
  const findFirstReceiveIndex = findIndex(reverseMessageList, (obj) => obj?.messageType !== 'SEND')

  const setFocusInput = () => {
    const getInputEle = document.querySelector('#reply-input')
    if (!isEmpty(getInputEle)) {
      getInputEle.focus()
    }
  }

  return <Div display='flex' flexDirection='column-reverse' moreStyle={{
    overflow: 'hidden', transition: innerHeight > 460 && 'all 0.3s ease', height: `${innerHeight - (innerHeight < 460 ? 147 : 107)}px`, maxHeight: `${innerHeight - (innerHeight < 460 ? 147 : 107)}px`,
  }} onScroll={(e) => chatDetailDetectScroll(e)}>
    <ChatInput
      loading={replyLoading}
      clear={(!replyLoading && (replayData?.replyMessage?.success || replayData?.comment?.success))}
      isCommentChannel={isCommentChannel}
      doReply={(value) => {
        if (!isEmpty(value.text)) setFocusInput()
        const pushMessageVars = {
          files: value.fileList.length > 0 ? value.fileList : undefined,
          audios: [value?.audio],
          duration: value?.audio?.size > 0 ? value?.duration : undefined,
          text: value.fileList.length > 0 ? '' : value.text,
          channelId,
        }
        doReply({
          variables: pushMessageVars,
        }).then((resp) => {
          ContainerMessage.current.scrollTop = 0
          if (!resp.loading && !resp?.data?.replyMessage?.success && resp?.data.replyMessage.message === 'RESEND') {
            Modal.confirm({
              title: 'ไม่สามารถส่งข้อความได้ เนื่องจากแชตล่าสุดมากกว่า 24 ชั่วโมง',
              icon: <ExclamationCircleOutlined />,
              content: 'ต้องการส่งพร้อมกับแท็ก post_purchase_update?',
              onOk() {
                doReply({
                  variables: {
                    ...pushMessageVars,
                    isWithTag: true,
                  },
                }).then((r) => {
                  if (!r.loading && !r?.data?.replyMessage?.success) {
                    message.error(`ไม่สามารถส่งข้อความได้: ${r.data.replyMessage.message || 'เกิดข้อผิดพลาด'}`)
                  }
                })
              },
            })
          } else if (!resp.loading && !resp?.data?.replyMessage?.success) {
            message.error(`ไม่สามารถส่งข้อความได้: ${resp.data.replyMessage.message || 'เกิดข้อผิดพลาด'}`)
          }
        })
      }}
    />
    {
      (isMobile || isFirefox)
        ? <Div ref={ContainerMessage} display='flex' direction='column' moreStyle={{
          overflow: 'auto', transform: 'scale(-1)', direction: 'rtl', scrollBehavior: 'smooth', height: 'calc(100% - 69px)', maxHeight: 'calc(100% - 69px)',
        }}>
          <Div display='flex' padding='0 16px 30px' flexDirection='column-reverse' moreStyle={{ width: '100%', transform: 'scale(-1)', direction: 'ltr' }}>
            {
              messages?.length > 0
              && _.map(reverseMessageList, (msg, i) => {
                const isSameDay = !_.isUndefined(currentMsgDate) && moment(msg.createdAt).isSame(moment(currentMsgDate), 'day')
                nextMsgDate = currentMsgDate
                currentMsgDate = msg.createdAt
                return (
                  <Div key={`message-${msg.id}`} id={`message-${msg.id}`}>
                    {
                      msg.messageType === 'SEND' || msg.type === 'SEND'
                        ? <SendMessage
                          type={channelType}
                          msg={msg}
                          setImage={setImage}
                          setModalIsOpen={setModalIsOpen}
                          onOpenCustomerQuotation={(url) => setOpenUserQuotaion(url)}
                        />
                        : <RecieveMessage readUserName={(!isEmpty(data?.chatChannelInfo?.data?.firstReader?.firstName) || !isEmpty(data?.chatChannelInfo?.data?.firstReader?.lastName)) && i === findFirstReceiveIndex && `${data?.chatChannelInfo?.data?.firstReader?.firstName} ${data?.chatChannelInfo?.data?.firstReader?.lastName}`} msg={msg} data={data.chatChannelInfo.data} setImage={setImage} setModalIsOpen={setModalIsOpen} />
                    }
                    {
                      !isSameDay && !_.isUndefined(nextMsgDate)
                      && <ChatDayBreaker date={nextMsgDate} />
                    }
                  </Div>
                )
              })
            }
            {
              !_.isUndefined(currentMsgDate)
              && <ChatDayBreaker date={currentMsgDate} />
            }
          </Div>
        </Div>
        : <Div ref={ContainerMessage} display='flex' height='calc(100% - 69px)' padding='0 16px 30px' flexDirection='column-reverse' moreStyle={{
          overflowY: 'scroll', scrollBehavior: 'smooth',
        }}>
          {
            messages?.length > 0
            && _.map(reverseMessageList, (msg, i) => {
              const isSameDay = !_.isUndefined(currentMsgDate) && moment(msg.createdAt).isSame(moment(currentMsgDate), 'day')
              nextMsgDate = currentMsgDate
              currentMsgDate = msg.createdAt
              return (
                <Div key={`message-${msg.id}`} id={`message-${msg.id}`}>
                  {
                    msg.messageType === 'SEND' || msg.type === 'SEND'
                      ? <SendMessage
                        type={channelType}
                        msg={msg}
                        setImage={setImage}
                        setModalIsOpen={setModalIsOpen}
                        onOpenCustomerQuotation={(url) => setOpenUserQuotaion(url)}
                      />
                      : <RecieveMessage readUserName={(!isEmpty(data?.chatChannelInfo?.data?.firstReader?.firstName) || !isEmpty(data?.chatChannelInfo?.data?.firstReader?.lastName)) && i === findFirstReceiveIndex && `${data?.chatChannelInfo?.data?.firstReader?.firstName} ${data?.chatChannelInfo?.data?.firstReader?.lastName}`} msg={msg} data={data?.chatChannelInfo?.data} setImage={setImage} setModalIsOpen={setModalIsOpen} />
                  }
                  {
                    !isSameDay && !_.isUndefined(nextMsgDate)
                    && <ChatDayBreaker date={nextMsgDate} />
                  }
                </Div>
              )
            })
          }
          {
            !_.isUndefined(currentMsgDate)
            && <ChatDayBreaker date={currentMsgDate} />
          }
        </Div>
    }
  </Div>
}

export const SelectCustomerModal = (props) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [selectedData, setSelectedData] = useState({
    customerId: props?.data?.customerId,
    addressId: props?.data?.addressId,
  })
  const [disabledButton, setDisabledButton] = useState(false)
  const [customerAdded, setCustomerAdded] = useState(false)
  const [customerDefault, setCustomerDefault] = useState(false)
  const [search, setSearch] = useState({
    input: '',
    isNewList: false,
  })
  const [callGetCustomerList, { data, loading }] = useLazyQuery(CUSTOMER_LIST_IN_CHAT)
  const [doSetCustomer] = useMutation(SET_CUSTOMER)
  const newAddressValue = 'new-address'

  useEffect(() => {
    updateNavigateDetail({
      pageTitle: 'ผูกลูกค้าจาก ERP',
      leftIcon: 'fal fa-chevron-left',
      leftFn: props?.onCancel,
    })
  }, [])

  useEffect(() => {
    if (props.visible && isEmpty(data)) {
      // callGetCustomerList({ variables: { toSync: true, name: props?.data?.customerName } })
    }
  }, [props.visible])

  useEffect(() => {
    setSelectedData({ customerId: props?.data?.customerId, addressId: props?.data?.addressId })
    setCustomerDefault(true)
  }, [props.data])

  useEffect(() => {
    if (customerDefault && !loading && !_.isEmpty(data)) {
      setSelectedData({ customerId: _.find(data?.customerList?.data?.list, (v) => v.id === selectedData.customerId)?.id, addressId: _.find(data?.customerList?.data?.list, (v) => v.id === selectedData.customerId) ? props?.data?.addressId : undefined })
      setCustomerDefault(false)
    }
    if (customerAdded && !loading && !_.isEmpty(data)) {
      setSelectedData({ customerId: data?.customerList?.data?.list[0]?.id, addressId: data?.customerList?.data?.list[0]?.contactAddress[0]?.id })
      setCustomerAdded(false)
    }
  }, [data])

  useEffect(() => {
    if (!_.isEmpty(search.input)) {
      setSearch({ ...search, isNewList: true })
    }
    setSelectedData({ ...selectedData, contactLineId: _.find(data?.customerList?.data?.list, (v) => v.id === selectedData?.customerId)?.contactAddress?.[0]?.contactLineId })
  }, [data?.customerList?.data?.list])

  const callDoSetCustomer = () => {
    setDisabledButton(true)
    doSetCustomer({
      variables: {
        ...selectedData,
        addressId: selectedData?.addressId === newAddressValue ? undefined : selectedData?.addressId,
        id: props?.chatContactId,
      },
    }).then((resp) => {
      setDisabledButton(false)
      if (resp?.data?.setContactCustomer?.success) {
        message.success(resp?.data?.setContactCustomer?.message)
        if (props.onSuccess) {
          props.onSuccess()
        }
      } else {
        message.error(resp?.data?.setContactCustomer?.message)
      }
    })
  }

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div height='calc(100vh - 128px)' padding='16px'>
        <Div>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>เลือกลูกค้าที่ต้องการจะผูก</SmallText>
          <Select
            className='select-with-bg'
            showSearch
            placeholder='เลือกลูกค้า'
            value={_.find(data?.customerList?.data?.list, (v) => v.id === selectedData?.customerId)?.name}
            style={{ width: '100%' }}
            onChange={(value) => {
              setSelectedData({ ...selectedData, customerId: value, addressId: find(data?.customerList?.data?.list, (obj) => obj?.id === value)?.contactAddress?.[0]?.id })
              setSearch({ input: '', isNewList: false })
            }}
            onSearch={(value) => {
              setSearch({ ...search, input: value })
              if (!isEmpty(value) && !isNil(value)) {
                callGetCustomerList({ variables: { toSync: true, name: value } })
              }
            }}
            filterOption={(input, option) => option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              !_.isEmpty(search.input) && search.isNewList && data?.customerList?.data?.list?.map((v, index) => (
                <Select.Option key={index} value={v.id}>{v.name}</Select.Option>
              ))
            }
          </Select>
        </Div>
        {
          !_.isUndefined(selectedData?.customerId)
          && <Div margin='16px 0 0'>
            {
              selectedData?.addressId !== newAddressValue
                && <Div>
                  <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>Line Id (ถ้ามี)</SmallText>
                  <Input width='100%' placeholder='Line Id (ถ้ามี)' value={selectedData?.contactLineId} onChange={(e) => setSelectedData({ ...selectedData, contactLineId: e.target.value })} />
                </Div>
            }
          </Div>
        }
      </Div>
      <Div moreStyle={{ boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.09)' }} margin='0 -16px' padding='16px'>
        <Button loading={disabledButton} disabled={disabledButton || _.isUndefined(selectedData.customerId)} fullWidth text='เปลี่ยนเจ้าของแชต' color={theme?.color?.success} inverse onClick={callDoSetCustomer} />
      </Div>
    </NavigateContainer>
  )
}

export const ModalQuotationForm = ({ visible, setVisible, quotationFormProps }) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)

  useEffect(() => {
    updateNavigateDetail({
      pageTitle: 'สร้างใบเสนอราคา',
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => setVisible(false),
    })
  }, [visible])

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div>
        <QuatationForm
          {...quotationFormProps}
          modal={true}
          setModal={() => setVisible(false)}
        />
      </Div>
    </NavigateContainer>
  )
}

export const FilterPage = ({
  closeFilter, providerList, setfilterInput, filterInput,
}) => {
  const { authorization } = useContext(GlobalContext)
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [getChatGroupList, { data: groupListData }] = useLazyQuery(CHAT_GROUP_LIST)
  const [getTagList, { loading: tagsLoading, data: tagsData }] = useLazyQuery(TAG_LIST)

  const callGetTagList = (searchValue) => {
    getTagList({
      variables: {
        input: {
          name: searchValue,
        },
      },
    })
  }

  const [vars, setVars] = useState({
    chatOwner: 'checkAll',
    ownerNameList: [],
    readType: undefined,
    ...omit(filterInput, ['providerIds', 'chatgroupIds']),
    channel: filterInput?.providerIds?.map((channelId) => `${channelId}`),
    chatStatus: filterInput?.chatgroupIds?.map((statusId) => `${statusId}`),
    tagIds: [],
  })

  useEffect(() => {
    updateNavigateDetail({
      pageTitle: 'Filter',
      leftIcon: 'fal fa-chevron-left',
      leftFn: closeFilter,
      rightText: 'ล้าง',
      rightFn: () => setVars({
        readType: 'ALL',
        chatOwner: 'checkAll',
        startDate: undefined,
        endDate: undefined,
        quotationCreatedEndDate: undefined,
        quotationCreatedStartDate: undefined,
        firstMessageEndDate: undefined,
        firstMessageStartDate: undefined,
      }),
    })
    getChatGroupList()
    callGetTagList()
    const latestFilter = JSON.parse(getLocalStorage('filter-chatList'))
    if (!isNull(latestFilter)) {
      setVars({
        ...latestFilter,
        startDate: latestFilter.startDate ? moment(latestFilter.startDate) : undefined,
        endDate: latestFilter.endDate ? moment(latestFilter.endDate) : undefined,
        firstMessageStartDate: latestFilter.firstMessageStartDate ? moment(latestFilter.firstMessageStartDate) : undefined,
        firstMessageEndDate: latestFilter.firstMessageEndDate ? moment(latestFilter.firstMessageEndDate) : undefined,
        quotationCreatedStartDate: latestFilter.quotationCreatedStartDate ? moment(latestFilter.quotationCreatedStartDate) : undefined,
        quotationCreatedEndDate: latestFilter.quotationCreatedEndDate ? moment(latestFilter.quotationCreatedEndDate) : undefined,
      })
    }
  }, [])

  const topfilterCheckboxList = [
    {
      title: 'ช่องทาง',
      key: 'channel',
      isHaveCheckAll: true,
      checkboxList: providerList?.map((providerDetail) => (
        { label: <SmallText bold>{providerDetail?.name}</SmallText>, value: `${providerDetail?.id}` }
      )),
    },
  ]

  const checkIsPermNewCustomer = authorization?.Chat?.newCustomer
  const checkIsPermCrmCustomer = authorization?.Chat?.crmCustomer
  const checkIsNotCrmAndNew = !authorization?.Chat?.newCustomer && !authorization?.Chat?.crmCustomer
  const filterOutChatGroupList = checkIsNotCrmAndNew ? [] : _.compact(_.map(groupListData?.chatGroupStatusList?.data?.list, (group) => {
    const newCustomerList = [0, 1, 2] // 'ลูกค้าใหม่', 'แจกงาน CRM'
    const crmCustomerList = [0, 3, 4] // "ลูกค้า CRM ตั้งแจ้งเตือน", "ลูกค้า CRM แจ้งเตือนแล้ว"
    const checkNewCustomerCondition = checkIsPermNewCustomer && includes(newCustomerList, group?.id)
    const checkCrmCustomerCondition = checkIsPermCrmCustomer && includes(crmCustomerList, group?.id)
    if (checkNewCustomerCondition || checkCrmCustomerCondition) return group
    return null
  }))

  const bottomFilterCheckBoxList = _.compact([
    isEmpty(filterOutChatGroupList) ? null : {
      title: 'กลุ่มแชต',
      key: 'chatStatus',
      isHaveCheckAll: true,
      checkboxList: compact(sortBy(filterOutChatGroupList, ['id'])?.map((groupDetail) => {
        if (groupDetail?.id === 0) return null
        return { label: <SmallText bold>{groupDetail?.name}</SmallText>, value: `${groupDetail?.id}` }
      })),
    },
    {
      title: 'เจ้าของแชต',
      key: 'chatOwner',
      isRadio: true,
      isHaveCheckAll: true,
      checkboxList: [
        { label: <SmallText bold>แชตของฉัน</SmallText>, value: 'MY_CHAT' },
        { label: <SmallText bold>ค้นหาเจ้าของแชต</SmallText>, value: 'OTHER_OWNER' },
        { label: <SmallText bold>ค้นหา Upsell</SmallText>, value: 'UPSELL' },
      ],
    },
  ])

  const onSelectRadio = (key, e) => {
    let newSelectedOwnerList = []
    if (e.target.value === 'MY_CHAT') {
      newSelectedOwnerList = [{ id: getTokenData()?.userId, isMyChat: true }]
      // newSelectedOwnerList = [{ id: getTokenData()?.userId, isMyChat: true }, ...(vars?.selectedOwner || [])]
    }
    setVars({ ...vars, [key]: e.target.value, selectedOwner: newSelectedOwnerList })
  }

  const onCheck = (filterTitle, key, e) => {
    let newList = []
    if (e?.target?.value === 'checkAll') {
      if (e?.target?.checked) newList = find([...topfilterCheckboxList, ...bottomFilterCheckBoxList], (obj) => obj?.title === filterTitle)?.checkboxList?.map((obj) => obj?.value)
      else newList = []
    } else if (isEmpty(find(vars?.[key], (checkedValue) => checkedValue === e.target.value))) {
      newList = uniq([e.target.value, ...(vars?.[key] || [])])
    } else {
      newList = compact(vars?.[key]?.map((checkedValue) => {
        if (checkedValue === e.target.value) return null
        return checkedValue
      }))
    }

    if (key === 'chatOwner') {
      if (e.target.value === bottomFilterCheckBoxList?.[1]?.checkboxList?.[0]?.value) {
        if (e.target.checked) {
          setVars({ ...vars, [key]: newList, selectedOwner: uniqBy([{ id: getTokenData()?.userId, isMyChat: true }, ...(vars?.selectedOwner || [])], 'id') })
        } else {
          const newSelectedOwner = compact(vars?.selectedOwner?.map((ownerDetail) => {
            if (ownerDetail?.id !== getTokenData()?.userId) return ownerDetail
            return null
          }))
          setVars({ ...vars, [key]: newList, selectedOwner: newSelectedOwner })
        }
      } else if (e.target.value === bottomFilterCheckBoxList?.[1]?.checkboxList?.[1]?.value) {
        if (e.target.checked) {
          setVars({ ...vars, [key]: newList })
        } else {
          setVars({ ...vars, [key]: newList, selectedOwner: filter(vars?.selectedOwner, (obj) => !obj?.isMyChat) })
        }
      } else if (e.target.value === 'checkAll') {
        if (e.target.checked) {
          setVars({ ...vars, [key]: newList })
        } else {
          setVars({ ...vars, [key]: newList, selectedOwner: [] })
        }
      }
    } else {
      setVars({ ...vars, [key]: newList })
    }
  }

  const callRefetch = () => {
    const newFilterInput = {
      ...filterInput,
      ...omit(vars, ['channel', 'chatStatus']),
      providerIds: vars?.channel?.map((channelId) => parseInt(channelId)),
      chatgroupIds: vars?.chatStatus?.map((statusId) => parseInt(statusId)),
      selectedOwner: includes(vars?.chatOwner, bottomFilterCheckBoxList?.[1]?.checkboxList?.[0]?.value)
        ? uniqBy([{ id: getTokenData()?.userId }, ...(vars?.selectedOwner || [])], 'id')
        : vars?.selectedOwner,
      chatOwner: vars.chatOwner,
      tagIds: vars?.tagIds?.map((tag) => parseInt(tag)),
    }
    setfilterInput(newFilterInput)
    saveLocalStorage('filter-chatList', JSON.stringify(vars))
    closeFilter()
  }

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      {
        providerList
          ? <Div padding='16px' height={`${window.innerHeight - 128}px`} moreStyle={{ overflow: 'auto' }}>
            {
              topfilterCheckboxList?.map((filterDetail, i) => (
                <ExpandWithChoice
                  key={filterDetail?.key}
                  margin={i !== 0 ? '32px 0 0' : '0'}
                  title={filterDetail?.title}
                  checkboxList={filterDetail?.checkboxList}
                  onCheck={onCheck}
                  varKey={filterDetail?.key}
                  isHaveCheckAll={filterDetail?.isHaveCheckAll}
                  vars={vars?.[filterDetail?.key]}
                />
              ))
            }
            <ExpandWithChoice
              margin='32px 0 0'
              title='กรองแชตที่ยังไม่อ่าน'
            >
              <Checkbox style={{ margin: '0' }} value='ALL' onChange={(e) => setVars({ ...vars, readType: e.target.checked ? 'ALL' : '' })} checked={vars?.readType === 'ALL'}><SmallText bold>ทั้งหมด</SmallText></Checkbox>
              <Checkbox style={{ margin: '16px 0 0' }} value='UNREAD' onChange={(e) => setVars({ ...vars, readType: e.target.checked ? 'UNREAD' : '' })} checked={vars?.readType === 'UNREAD'}><SmallText bold>ยังไม่อ่าน</SmallText></Checkbox>
            </ExpandWithChoice>
            {
              checkIsPermCrmCustomer
              && <ExpandWithChoice
                margin='32px 0 0'
                title='วันที่แจ้งเตือน'
              >
                <Div display='flex'>
                  <Div margin='0 16px 0 0' width='calc((100% - 16px) / 2)'>
                    <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่เริ่ม</Label>
                    <DatePicker inputReadOnly={true} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่เริ่ม' style={{ width: '100%' }} value={vars?.startDate ? moment(vars?.startDate) : undefined} onChange={(selectedDate) => setVars({ ...vars, startDate: selectedDate, endDate: undefined })} />
                  </Div>
                  <Div width='calc((100% - 16px) / 2)'>
                    <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่สิ้นสุด</Label>
                    <DatePicker inputReadOnly={true} disabledDate={(current) => current && current < moment(vars?.startDate).subtract(1, 'd').endOf('day') } disabled={!vars?.startDate} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่สิ้นสุด' style={{ width: '100%' }} value={vars?.endDate ? moment(vars?.endDate) : undefined} onChange={(selectedDate) => setVars({ ...vars, endDate: selectedDate })} />
                  </Div>
                </Div>
              </ExpandWithChoice>
            }
            <ExpandWithChoice
              margin='32px 0 0'
              title='วันที่รับ/ส่งข้อความแรก'
            >
              <Div display='flex'>
                <Div margin='0 16px 0 0' width='calc((100% - 16px) / 2)'>
                  <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่เริ่ม</Label>
                  <DatePicker inputReadOnly={true} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่เริ่ม' style={{ width: '100%' }} value={vars?.firstMessageStartDate ? moment(vars?.firstMessageStartDate) : undefined} onChange={(selectedDate) => setVars({ ...vars, firstMessageStartDate: selectedDate, firstMessageEndDate: undefined })} />
                </Div>
                <Div width='calc((100% - 16px) / 2)'>
                  <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่สิ้นสุด</Label>
                  <DatePicker inputReadOnly={true} disabledDate={(current) => current && current < moment(vars?.firstMessageStartDate).subtract(1, 'd').endOf('day') } disabled={!vars?.firstMessageStartDate} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่สิ้นสุด' style={{ width: '100%' }} value={vars?.firstMessageEndDate ? moment(vars?.firstMessageEndDate) : undefined} onChange={(selectedDate) => setVars({ ...vars, firstMessageEndDate: selectedDate })} />
                </Div>
              </Div>
            </ExpandWithChoice>
            <ExpandWithChoice
              margin='32px 0 0'
              title='วันที่เปิดใบเสนอราคา'
            >
              <Div display='flex'>
                <Div margin='0 16px 0 0' width='calc((100% - 16px) / 2)'>
                  <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่เริ่ม</Label>
                  <DatePicker inputReadOnly={true} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่เริ่ม' style={{ width: '100%' }} value={vars?.quotationCreatedStartDate ? moment(vars?.quotationCreatedStartDate) : undefined} onChange={(selectedDate) => setVars({ ...vars, quotationCreatedStartDate: selectedDate, quotationCreatedEndDate: undefined })} />
                </Div>
                <Div width='calc((100% - 16px) / 2)'>
                  <Label margin='0 0 8px' color={theme?.color?.gray30}>วันที่สิ้นสุด</Label>
                  <DatePicker inputReadOnly={true} disabledDate={(current) => current && current < moment(vars?.quotationCreatedStartDate).subtract(1, 'd').endOf('day') } disabled={!vars?.quotationCreatedStartDate} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='วันที่สิ้นสุด' style={{ width: '100%' }} value={vars?.quotationCreatedEndDate ? moment(vars?.quotationCreatedEndDate) : undefined} onChange={(selectedDate) => setVars({ ...vars, quotationCreatedEndDate: selectedDate })} />
                </Div>
              </Div>
            </ExpandWithChoice>
            <ExpandWithChoice
              margin='32px 0 0'
              title='Tags'
            >
              <Select
                className='select-with-bg tags'
                mode='multiple'
                filterOption={false}
                style={{ width: '100%' }}
                placeholder='เลือกแท็ก'
                onChange={(e) => {
                  setVars({
                    ...vars,
                    tagIds: e,
                  })
                }}
                loading={tagsLoading}
                onSearch={(searchValue) => {
                  delayInput(() => callGetTagList(searchValue))
                }}
              >
                {
                  tagsLoading
                    ? []
                    : map(tagsData?.tagList?.data?.list, (tag) => (
                      <Select.Option key={tag?.id} value={tag?.id}>{tag?.name}</Select.Option>
                    ))
                }
              </Select>
            </ExpandWithChoice>
            {
              bottomFilterCheckBoxList?.map((filterDetail) => (
                <ExpandWithChoice
                  key={filterDetail?.key}
                  isRadio={filterDetail?.isRadio}
                  margin='32px 0 0'
                  title={filterDetail?.title}
                  checkboxList={filterDetail?.checkboxList}
                  onCheck={onCheck}
                  onSelectRadio={onSelectRadio}
                  varKey={filterDetail?.key}
                  isHaveCheckAll={filterDetail?.isHaveCheckAll}
                  vars={vars?.[filterDetail?.key]}
                  allVars={vars}
                  setAllVars={setVars}
                />
              ))
            }
          </Div>
          : <Div width='100%' margin='40px 0 0 0' display='flex' justifyContent='center' height={`${window.innerHeight - 168}px`}>
            <Icon icon='fal fa-spinner fa-pulse' style={{ height: 'fit-content' }} fontSize={theme.fonts.size.title} color={theme.color.gray40} />
          </Div>
      }
      <Div moreStyle={{ boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.09)' }} padding='16px'>
        <Button fullWidth text='ฟีลเตอร์' color={theme?.color?.success} inverse onClick={callRefetch} />
      </Div>
    </NavigateContainer>
  )
}

export const SetOwnerModal = (props) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [selectedOwner, setSelectedOwner] = useState()
  const [getUserList, { loading: getting, data: usersData }] = useLazyQuery(USER_LIST)
  const [doSetOwner, { loading: updating }] = useMutation(SET_CHAT_CHANNEL_OWNER)

  const callGetUserList = (searchValue) => {
    getUserList({
      variables: {
        limit: 10,
        offset: 0,
        status: 'ACTIVE',
        name: searchValue,
      },
    })
  }

  useEffect(() => {
    callGetUserList()
    updateNavigateDetail({
      pageTitle: 'เปลี่ยนเจ้าของแชต',
      leftIcon: 'fal fa-chevron-left',
      leftFn: props?.onCancel,
    })
  }, [])

  const callDoSetOwner = () => {
    if (!isNil(props?.chatId)) {
      doSetOwner({
        variables: {
          input: {
            channelId: props?.chatId,
            ownerId: selectedOwner,
          },
        },
      }).then((resp) => showMessageMutation(resp?.data?.setChatChannelOwner, () => props?.onSuccess && props?.onSuccess()))
    }
  }

  const userList = usersData?.userList?.data?.list?.map((userDetail) => (
    {
      id: userDetail?.id,
      name: `${userDetail?.firstName} ${userDetail?.lastName}`,
    }
  ))

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div height='calc(100vh - 128px)' padding='16px'>
        <Input margin='0 0 12px' padding='6px 8px 5px 30px' width='100%' placeholder='ค้นหาแอดมิน' prefix={<Icon icon='fal fa-search' className='prefix' />} onChange={(e) => {
          const getValue = e.target.value
          delayInput(() => callGetUserList(getValue))
        }} />
        <Div height='calc(100% - 52px)' moreStyle={{ overflow: 'auto' }}>
          {
            getting && isEmpty(usersData)
              ? <FullPageLoading height='calc(100vh - 54px)' center fontSize={theme?.fonts?.size?.normal} />
              : userList?.map((userDetail) => (
                <RowSelect
                  key={userDetail?.id}
                  checked={selectedOwner === userDetail?.id}
                  data={{ value: userDetail?.id, text: userDetail?.name }}
                  id={`${userDetail?.name}-${userDetail?.id}`}
                  name='owner'
                  onChange={() => setSelectedOwner(userDetail?.id)}
                />
              ))
          }
        </Div>
      </Div>
      <Div moreStyle={{ boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.09)' }} padding='16px'>
        <Button disabled={isNil(selectedOwner) || updating} loading={updating} fullWidth text='เปลี่ยนเจ้าของแชต' color={theme?.color?.success} inverse onClick={callDoSetOwner} />
      </Div>
    </NavigateContainer>
  )
}

export const Reminder = ({
  selectedUserReminder, closeAndRefetchList, isReminderAll, filterInput, close,
}) => {
  const [reminderVars, setReminderVars] = useState()
  const [getUserList, { loading: usersLoading, data: usersData }] = useLazyQuery(USER_LIST)
  const [doCreateReminder, { loading: creating }] = useMutation(CREATE_REMINDER)
  const [doCreateAllReminder, { loading: allReminderCreating }] = useMutation(CREATE_ALL_REMINDER)

  const searchUser = (searchValue) => {
    getUserList({
      variables: {
        limit: 10,
        offset: 0,
        name: isEmpty(searchValue) ? undefined : searchValue,
      },
    })
  }

  const callDoCreateReminder = () => {
    const reminderInput = {
      date: reminderVars?.date,
      reminder: reminderVars?.reminder,
      userId: reminderVars?.userId,
    }
    if (isReminderAll) {
      doCreateAllReminder({
        variables: {
          input: {
            ...filterInput,
            reminderInput,
          },
        },
      }).then((resp) => showMessageMutation(resp?.data?.createAllReminder, () => closeAndRefetchList()))
    } else {
      doCreateReminder({
        variables: {
          input: {
            chatChannelIds: selectedUserReminder,
            ...reminderInput,
          },
        },
      }).then((resp) => showMessageMutation(resp?.data?.createReminder, () => closeAndRefetchList()))
    }
  }

  return (
    <Div>
      <P margin='24px 0 0' weightgray100 color={theme?.color?.primaryColor}>ตั้งแจ้งเตือน</P>
      <Div margin='16px 0 0'>
        <Label display='block' margin='0 0 8px' color={theme?.color?.gray30}>เลือก Admin CRM</Label>
        <Select
          className='select-with-bg'
          showSearch
          style={{ width: '100%' }}
          filterOption={false}
          placeholder='เลือก Admin CRM'
          onSearch={(e) => delayInput(() => searchUser(e))}
          loading={usersLoading}
          value={reminderVars?.userId}
          onSelect={(selectedId) => setReminderVars({ ...reminderVars, userId: selectedId })}
        >
          {
            usersData?.userList?.data?.list?.map((userDetail) => (
              <Select.Option key={userDetail?.id} value={userDetail?.id}>{userDetail?.firstName} {userDetail?.lastName}</Select.Option>
            ))
          }
        </Select>
      </Div>
      <Div margin='16px 0 0'>
        <Label margin='0 0 8px' color={theme?.color?.gray30}>ตั้งวันที่แจ้งเตือน</Label>
        <DatePicker inputReadOnly={true} className='date-picker-with-bg' format='DD/MM/YYYY' placeholder='ตั้งวันที่แจ้งเตือน' style={{ width: '100%' }} value={reminderVars?.date} onChange={(selectedDate) => setReminderVars({ ...reminderVars, date: selectedDate })} />
      </Div>
      <Div margin='16px 0 0'>
        <Label display='block' margin='0 0 8px' color={theme?.color?.gray30}>หมายเหตุ</Label>
        <TextArea placeholder='หมายเหตุ' style={{ width: '100%' }} onChange={(e) => setReminderVars({ ...reminderVars, reminder: e.target.value })} />
      </Div>
      <Div display='flex' moreStyle={{ boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.09)' }} margin='16px -16px 0' padding='16px'>
        <Button fullWidth text='ยกเลิก' color={theme?.color?.gray10} textColor={theme?.color?.gray40} inverse margin='0 16px 0 0' onClick={() => close()} />
        <Button fullWidth text='ยืนยัน' color={theme?.color?.success} inverse disabled={isNil(reminderVars?.userId) || isEmpty(reminderVars?.date) || creating || allReminderCreating} loading={creating || allReminderCreating} onClick={callDoCreateReminder} />
      </Div>
    </Div>
  )
}
const ItemCard = ({ data }) => (
  <Div display='flex' padding='12px 0' moreStyle={{ borderBottom: `1px solid ${theme?.color?.gray10}` }}>
    {
      !isEmpty(data?.img)
      && <Div width='64px' margin='0 16px 0 0'>
        <img width='100%' src={data?.img} />
      </Div>
    }
    <Div>
      <SmallText bold>{data?.name || '-'}</SmallText>
      <SmallText bold display='block' margin='8px 0'>{numeral(data?.pricePerPiece).format('0,0.00')}</SmallText>
      <SmallText color={theme?.color?.gray40}>{numeral(data?.amount).format('0,0')} X</SmallText>
    </Div>
  </Div>
)

export const OrderHistory = ({ onCancel, customerId }) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [toggle, setToggle] = useState(false)
  const [getPurchaseHistory, { loading: getting, data: purchaseHistoryData, fetchMore: purchaseHistoryFetchMore }] = useLazyQuery(PURCHASE_HISTORY)

  useEffect(() => {
    if (!isNil(customerId)) {
      getPurchaseHistory({
        variables: {
          input: {
            customerId,
            limit: 10,
            offset: 0,
          },
        },
      })
      updateNavigateDetail({
        pageTitle: 'Order History',
        leftIcon: 'fal fa-chevron-left',
        leftFn: onCancel,
      })
    }
  }, [])

  const doToggle = () => setToggle(!toggle)
  const detectScroll = (event) => {
    if (event.target.scrollTop === (event.target.scrollHeight - event.target.offsetHeight)) {
      purchaseHistoryFetchMore({
        variables: {
          input: {
            offset: purchaseHistoryData?.purchaseHistory?.data?.list?.length,
            customerId,
            limit: 10,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult.purchaseHistory.data.list.length === 0) return prev
          return {
            ...prev,
            purchaseHistory: {
              ...prev.purchaseHistory,
              data: {
                ...prev.purchaseHistory.data,
                list: [
                  ...purchaseHistoryData?.purchaseHistory?.data?.list,
                  ...fetchMoreResult.purchaseHistory.data.list,
                ],
              },
            },
          }
        },
      })
    }
  }

  const orderList = purchaseHistoryData?.purchaseHistory?.data?.list?.map((purchaseHistoryDetail) => (
    {
      refId: purchaseHistoryDetail?.refId,
      createdAt: dayjs(purchaseHistoryDetail?.createdAt).format('DD/MM/YYYY'),
      total: purchaseHistoryDetail?.totalPrice,
      products: purchaseHistoryDetail?.quotationLots?.[0]?.quotationItems?.map((itemDetail) => (
        {
          id: itemDetail?.id,
          img: itemDetail?.pack?.inventoryOption?.files?.[0]?.url || itemDetail?.set?.file?.url,
          name: itemDetail?.pack?.variantName || itemDetail?.set?.name,
          pricePerPiece: itemDetail?.price - itemDetail?.discount,
          amount: itemDetail?.quantity,
        }
      )),
    }
  ))

  const total = purchaseHistoryData?.purchaseHistory?.data?.total

  if (getting && isEmpty(purchaseHistoryData?.purchaseHistory?.data)) return <FullPageLoading height='64px' center fontSize={theme?.fonts?.size?.subTitle} />

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div onScroll={detectScroll} height={`${window.innerHeight - 53}px`} moreStyle={{ overflow: 'auto' }}>
        {
          (isNil(customerId) || isEmpty(orderList))
            ? <P display='block' textAlign='center' color={theme?.color?.gray30} margin='80px 0'>{isNil(customerId) ? 'แชตนี้ไม่มีข้อมูลลูกค้า' : 'ไม่มีข้อมูล'}</P>
            : orderList?.map((orderDetail) => (
              <Div key={orderDetail?.refId} padding='16px' moreStyle={{ borderBottom: `1px solid ${theme?.color?.gray40}` }}>
                <Div display='flex' justifyContent='space-between' alignItems='center' margin='0 0 4px'>
                  <P weightgray100 color={theme?.color?.primaryColor}>{orderDetail?.refId}</P>
                  <SmallText color={theme?.color?.gray40}>{orderDetail?.createdAt}</SmallText>
                </Div>
                <Div>
                  <ItemCard data={orderDetail?.products?.[0]} />
                  {
                    orderDetail?.products?.length > 1
                    && <Div>
                      <Div moreStyle={{ maxHeight: toggle ? '1000px' : '0', overflow: 'hidden', transition: 'max-height 0.3s ease' }}>
                        {
                          orderDetail?.products?.map((productDetail, i) => {
                            if (i === 0) return null
                            return <ItemCard key={productDetail?.id} data={productDetail} />
                          })
                        }
                      </Div>
                      <Div padding='12px 0' textAlign='center' moreStyle={{ cursor: 'pointer', borderBottom: `1px solid ${theme?.color?.gray10}` }} onClick={doToggle}>
                        <Label color={theme?.color?.gray40} textAlign='center'>ดูสินค้าอื่นในคำสั่งซื้อ</Label>
                      </Div>
                    </Div>
                  }
                  <Div display='flex' justifyContent='space-between' alignItems='center' margin='12px 0 0'>
                    <SmallText weightgray100>ยอดรวมทั้งหมด</SmallText>
                    <SmallText weightgray100>{numeral(orderDetail?.total).format('0,0.00')}</SmallText>
                  </Div>
                </Div>
              </Div>
            ))
        }
        {
          orderList?.length < total
          && <FullPageLoading height='64px' center fontSize={theme?.fonts?.size?.subTitle} />
        }
      </Div>
    </NavigateContainer>
  )
}

export const UserQuotationDetail = ({ isOpen, onCancel, url }) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)

  useEffect(() => {
    if (isOpen) {
      updateNavigateDetail({
        pageTitle: 'ใบเสนอราคา',
        leftIcon: 'fal fa-chevron-left',
        leftFn: onCancel,
      })
    }
  }, [isOpen])

  const splitUrl = url?.split('quotation-detail/')?.[1]
  const getDetail = splitUrl?.split('/')

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <UserQuotation token={getDetail?.[0]} id={getDetail?.[1]} />
    </NavigateContainer>
  )
}

export default ChatMessages
