import { css, keyframes } from 'styled-components'

export const animate = {
  spin: keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `,
  fadeInFromTop: keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, -20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  fadeOutToTop: keyframes`
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0, -20%, 0);
    }
  `,
  fadeInFromRight: keyframes`
    from {
      opacity: 0;
      transform: translate3d(20%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  fadeOutToRight: keyframes`
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(20%, 0, 0);
    }
  `,
  fadeInFromLeft: keyframes`
    from {
      opacity: 0;
      transform: translate3d(-20%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  fadeOutToLeft: keyframes`
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(-20%, 0, 0);
    }
  `,
  fadeInFromBottom: keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, 20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  fadeOutToBottom: keyframes`
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0, 20%, 0);
    }
  `,
  time: {
    loading: '1s',
    maxHeight: '0.5s',
    fade: '0.2s',
    opacity: '0.2s',
    timeout: '3000',
  },
  transition: 'all 0.5s ease',
}

export const theme = {
  maxWidth: '768px',
  mobileMaxWidth: '320px',
  color: {
    primaryColor10: '#FAD7D7',
    primaryColor20: '#F6AFAF',
    primaryColor30: '#F49B9B',
    primaryColor40: '#EF7272',
    primaryColor: '#E83836',
    primaryColor60: '#BA2B2B',
    primaryColor70: '#A22626',
    primaryColor80: '#741B1B',
    primaryColor90: '#461010',
    primaryColor100: '#170505',
    secondaryColor: '#BFC1CF',
    tertiaryColor: '#00124B',
    offWhite: '#FFFFFF',
    gray10: '#F7F7FC',
    gray20: '#DEDEE3',
    gray30: '#C6C6CA',
    gray40: '#ADADB0',
    gray50: '#949497',
    gray60: '#7C7C7E',
    gray70: '#636365',
    gray80: '#4A4A4C',
    gray90: '#313132',
    gray100: '#191919',
    warning: '#FACC15',
    success: '#4ADE80',
    error: '#F87171',
    // ---------------------
    blue: '#3886D2',
    orange: '#fc6603',
    info: '#1890FF',
    lightBlue: '#D4DAE3',
    veryLightBlue: '#F3F5FA',
    greyBlue: '#6A8CAA',
    leafyGreen: '#4db433',
    dullOrange: '#d87f3f',
    lightPurple: '#b19cd9',
    hilight: '#FFE46C',
  },
  fonts: {
    family: {
      normal: 'sarabun',
    },
    style: {
      extraLight: '300',
      light: '300',
      medium: '500',
      regular: '500',
      bold: '600',
      gray100: '600',
    },
    size: {
      heading: '56px',
      subHeading: '40px',
      title: '30px',
      subTitle: '22px',
      normal: '16px',
      small: '14px',
      label: '12px',
    },
  },
  animate,
}

export const screenSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

export const media = Object.keys(screenSize).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (min-width: ${screenSize[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default theme
