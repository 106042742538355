import styled, { css } from 'styled-components'
import { Button } from '@konsys-ui-custom'
import { theme } from '../../styles/variables'

export const DownloadWrapper = styled.div`
  > img {
    width: 200px;
    margin: 2px 0 0;
    border-radius: 4px;
    overflow: hidden;
  }
`

export const Line = styled.hr`
  margin: ${(props) => props.margin || 0};
  border: none;
  border-top: 1px solid ${theme.color.lightBlue};
`

export const TextDropdownContainer = styled.div`
  display: inline-flex;
  align-items: center;
  > i {
    transition: all 0.5s ease;
  }
  ${(props) => !props.loading && css`
    &.ant-dropdown-open {
      > i {
        transform: rotate(180deg);
      }
    }
  `}
`

export const CustomButton = styled(Button)`
  border: none !important;
  background-color: transparent;
  padding-top: 2px;
  padding-bottom: 2px;
`

export const RowSelectProductWrapper = styled.div`
  ${(props) => props?.margin && css`
    margin: ${props?.margin};
  `}
  > input {
    display: none;
    &:checked + label > div {
      border: 1px solid ${theme?.color?.primaryColor};
    }
  }
`

export default DownloadWrapper
