import React from 'react'
import ReactDOM from 'react-dom'
import 'antd4/dist/antd.less'
import 'antd/dist/antd.less'
import './styles/index.less'
import { ConfigProvider } from 'antd'
import thTH from 'antd/lib/locale-provider/th_TH'
import { ThemeProvider } from 'styled-components'
import App from './App'
import { theme } from './styles/variables'
import 'moment/locale/th'
import ContextProvider from './store/providerComposer'
import './styles/override.css'

ReactDOM.render(
  <ContextProvider>
    <ConfigProvider locale={thTH}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConfigProvider>
  </ContextProvider>, document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
