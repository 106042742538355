import React, { useState, useContext, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  message, Drawer, Switch,
} from 'antd'
import { find, flatten, isEmpty } from 'lodash'
import {
  Div, SmallText, Icon, FullPageLoading,
} from '@konsys-ui-custom'
import { GET_ROLES } from '../../constants/graph/query'
import {
  UPDATE_ROLE_PERMISSION,
} from '../../constants/graph/mutation'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import NavigateContainer from '../../components/navigate'
import mapPermissionsName from '../../constants/map-permissions-name'

const PermissionRoleList = ({
  closePermissionRoleList, permissionDetail, roleList, refetch, authorization,
}) => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [doUpdateRolePermission] = useMutation(UPDATE_ROLE_PERMISSION)

  useEffect(() => {
    updateNavigateDetail({
      ...navigateDetail,
      pageTitle: permissionDetail?.title,
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => closePermissionRoleList(),
    })
  }, [])

  const callUpdateRolePerm = (roleId, permissionId) => {
    doUpdateRolePermission({
      variables: {
        roleId,
        permissionId,
      },
    }).then((resp) => {
      if (resp.data.updateRolePermission.success) {
        message.success(resp.data.updateRolePermission.message)
      } else {
        message.error(resp.data.updateRolePermission.message)
      }
      if (refetch) refetch()
    })
  }

  return (
    <NavigateContainer navigateProps={navigateDetail}>
      <Div padding='16px'>
        {
          roleList?.map((roleDetail) => (
            <Div key={roleDetail?.id} padding='16px 0' display='flex' alignItems='center' justifyContent='space-between' moreStyle={{ borderBottom: `1px solid ${theme?.color?.gray10}` }}>
              <SmallText bold>{roleDetail?.name}</SmallText>
              <Switch
                defaultChecked={roleDetail?.active}
                onChange={() => callUpdateRolePerm(roleDetail?.id, permissionDetail?.id)}
                disabled={!authorization?.Permission?.all}
              />
            </Div>
          ))
        }
      </Div>
    </NavigateContainer>
  )
}

export const Role = () => {
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [drawerDetail, setDrawerDetail] = useState({
    visible: false,
  })
  const roles = useQuery(GET_ROLES)
  const { authorization } = useContext(GlobalContext)

  const callUpdateNavigateDetail = () => {
    updateNavigateDetail({
      ...navigateDetail,
      pageTitle: 'การเข้าถึง',
      leftIcon: undefined,
      leftFn: undefined,
    })
  }

  useEffect(() => {
    callUpdateNavigateDetail()
  }, [])

  useEffect(() => {
    callUpdateNavigateDetail()
  }, [drawerDetail?.visible])

  if (roles.loading && isEmpty(roles.data)) return <FullPageLoading padding='24px' center fontSize={theme?.fonts?.size?.normal} />

  const closeDrawer = () => setDrawerDetail({ ...drawerDetail, visible: false })

  const module = roles?.data?.rolePermissionList?.data?.map((rolePermData) => (
    {
      name: rolePermData?.moduleName,
      permissionList: rolePermData?.roleList?.[0]?.permissionList?.map((pmDetail) => (
        {
          id: pmDetail?.id,
          name: pmDetail?.description,
          key: pmDetail?.name,
          roleList: rolePermData?.roleList?.map((roleData) => (
            {
              id: roleData?.role?.id,
              name: roleData?.role?.description,
              key: roleData?.role?.name,
              active: find(roleData?.permissionList, (obj) => obj?.id === pmDetail?.id)?.status,
            }
          )),
        }
      )),
    }
  ))

  const getAllPermissionList = module?.map((obj) => obj?.permissionList)
  const findRole = !isEmpty(drawerDetail?.permissionDetail) && find(flatten(getAllPermissionList), (obj) => obj?.id === drawerDetail?.permissionDetail?.id)

  const drawer = (
    <Drawer
      placement='right'
      closable={false}
      width='100%'
      visible={drawerDetail?.visible}
      bodyStyle={{ padding: 0, height: '100vh' }}
      style={{ transform: 'translateX(0) !important' }}
    >
      <PermissionRoleList
        closePermissionRoleList={closeDrawer}
        permissionDetail={drawerDetail?.permissionDetail}
        roleList={findRole?.roleList}
        refetch={roles?.refetch}
        authorization={authorization}
      />
    </Drawer>
  )

  return (
    <Div padding='16px'>
      { drawerDetail?.visible && drawer }
      {
        module?.map((moduleDetail, i) => (
          <Div key={moduleDetail?.name} margin={i !== 0 ? '32px 0 0' : '8px 0 0'}>
            <SmallText weightgray100 margin='0 0 8px'>{mapPermissionsName?.[moduleDetail?.name]?.TITLE || moduleDetail?.name}</SmallText>
            {
              moduleDetail?.permissionList?.map((pe) => (
                <Div key={pe?.name} padding='16px 0' display='flex' alignItems='center' justifyContent='space-between' moreStyle={{ cursor: 'pointer', borderBottom: `1px solid ${theme?.color?.gray10}` }} onClick={() => setDrawerDetail({ ...drawerDetail, visible: true, permissionDetail: { title: mapPermissionsName?.[moduleDetail?.name]?.CHILDREN?.[pe?.key] || pe.name, id: pe?.id } })}>
                  <SmallText bold>{mapPermissionsName?.[moduleDetail?.name]?.CHILDREN?.[pe?.key] || pe.name}</SmallText>
                  <Icon icon='fal fa-chevron-right' color={theme?.color?.gray40} />
                </Div>
              ))
            }
          </Div>
        ))
      }
    </Div>
  )
}

export default Role
