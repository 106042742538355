import { theme } from '../../styles/variables'

export const UPSELL_STATUS = {
  CALLED: {
    key: 'CALLED',
    text: 'โทรแล้ว',
    color: theme?.color?.info,
  },
  CALLING: {
    key: 'CALLING',
    text: 'กำลังโทร',
    color: theme?.color?.warning,
  },
  WAITING_CALL: {
    key: 'CALLED',
    text: 'ยังไม่ได้โทร',
    color: theme?.color?.gray40,
  },
  NOT_PICKUP: {
    key: 'CALLING',
    text: 'ลูกค้าไม่รับสาย',
    color: theme?.color?.warning,
  },
}

export const QUOTATION_STATUS = [
  {
    value: 'WAITING_ADDRESS',
    text: 'รอยืนยันที่อยู่',
  },
  {
    value: 'WAITING_PAYMENT',
    text: 'รอชำระเงิน',
  },
  {
    value: 'WAITING_PAYMENT_CONFIRM',
    text: 'รอยืนยันการชำระเงิน',
  },
  {
    value: 'WAITING_CREATE_SO',
    text: 'รอส่งออเดอร์',
  },
  {
    value: 'WAITING_DELIVERED',
    text: 'สร้างออเดอร์แล้ว',
  },
  {
    value: 'DELIVERED',
    text: 'สำเร็จแล้ว',
  },
  {
    value: 'SO_CANCELLED',
    text: 'ยกเลิก SO',
  },
]

export const colorSelector = (status) => {
  switch (status) {
    case QUOTATION_STATUS[0].value:
      return theme.color.primaryColor
    case QUOTATION_STATUS[1].value:
      return theme.color.lightPurple
    case QUOTATION_STATUS[2].value:
      return theme.color.dullOrange
    case QUOTATION_STATUS[3].value:
      return theme.color.info
    case QUOTATION_STATUS[4].value:
      return theme.color.gray40
    case QUOTATION_STATUS[5].value:
      return theme.color.success
    default:
      return theme.color.error
  }
}
